// source: invoicing/v1/articletype.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleNumberFilter', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleNumberFilter.FilterField', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleType', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleType.Category', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleTypeFilter', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleTypeSorting', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleTypeSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ArticleTypeSorting.SortingField', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateArticleTypeRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.CreateArticleTypeResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteArticleTypeRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.DeleteArticleTypeResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListArticleTypesRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.ListArticleTypesResponse', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateArticleTypeRequest', null, global);
goog.exportSymbol('proto.swift.invoicing.v1.UpdateArticleTypeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ArticleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ArticleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ArticleType.displayName = 'proto.swift.invoicing.v1.ArticleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ArticleTypeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ArticleTypeFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ArticleTypeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ArticleTypeFilter.displayName = 'proto.swift.invoicing.v1.ArticleTypeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ArticleNumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ArticleNumberFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ArticleNumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ArticleNumberFilter.displayName = 'proto.swift.invoicing.v1.ArticleNumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ArticleTypeSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ArticleTypeSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ArticleTypeSorting.displayName = 'proto.swift.invoicing.v1.ArticleTypeSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListArticleTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.ListArticleTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListArticleTypesRequest.displayName = 'proto.swift.invoicing.v1.ListArticleTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.ListArticleTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.invoicing.v1.ListArticleTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.invoicing.v1.ListArticleTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.ListArticleTypesResponse.displayName = 'proto.swift.invoicing.v1.ListArticleTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateArticleTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateArticleTypeRequest.displayName = 'proto.swift.invoicing.v1.CreateArticleTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.CreateArticleTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.CreateArticleTypeResponse.displayName = 'proto.swift.invoicing.v1.CreateArticleTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateArticleTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateArticleTypeRequest.displayName = 'proto.swift.invoicing.v1.UpdateArticleTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.UpdateArticleTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.UpdateArticleTypeResponse.displayName = 'proto.swift.invoicing.v1.UpdateArticleTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteArticleTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteArticleTypeRequest.displayName = 'proto.swift.invoicing.v1.DeleteArticleTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.invoicing.v1.DeleteArticleTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.invoicing.v1.DeleteArticleTypeResponse.displayName = 'proto.swift.invoicing.v1.DeleteArticleTypeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ArticleType.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ArticleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ArticleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleType.toObject = function(includeInstance, msg) {
  var f, obj = {
articleNo: jspb.Message.getFieldWithDefault(msg, 1, 0),
category: jspb.Message.getFieldWithDefault(msg, 2, 0),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
domesticVat: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
euVat: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
useAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.ArticleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ArticleType;
  return proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ArticleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.ArticleType.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDomesticVat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEuVat(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseAutomation(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ArticleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ArticleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomesticVat();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEuVat();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getUseAutomation();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.ArticleType.Category = {
  FREIGHT: 0,
  FREIGHT_ARRIVING: 1,
  FREIGHT_DEPARTING: 2,
  FREIGHT_DOMESTIC: 3,
  FREIGHT_INTERNATIONAL: 4,
  SEA: 5,
  ADMIN: 6,
  CUSTOMS: 7,
  CUSTOMS_HANDLING_IMPORT: 8,
  CUSTOMS_HANDLING_EXPORT: 9,
  FORWARDING_FEE: 10,
  ROAD: 11,
  BAF: 12,
  CAF: 13,
  ETS: 14,
  EBS: 15,
  EXPENSE: 16,
  OTHER: 17
};

/**
 * optional int64 article_no = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Category category = 2;
 * @return {!proto.swift.invoicing.v1.ArticleType.Category}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getCategory = function() {
  return /** @type {!proto.swift.invoicing.v1.ArticleType.Category} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleType.Category} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool domestic_vat = 4;
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getDomesticVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setDomesticVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool eu_vat = 5;
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getEuVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setEuVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool use_automation = 6;
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getUseAutomation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.setUseAutomation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
*/
proto.swift.invoicing.v1.ArticleType.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleType.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.invoicing.v1.ArticleType.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
*/
proto.swift.invoicing.v1.ArticleType.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ArticleType} returns this
 */
proto.swift.invoicing.v1.ArticleType.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleType.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ArticleTypeFilter.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ArticleTypeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ArticleTypeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleTypeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
articleNumberFilter: (f = msg.getArticleNumberFilter()) && proto.swift.invoicing.v1.ArticleNumberFilter.toObject(includeInstance, f),
categoryList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
euVatList: (f = jspb.Message.getRepeatedBooleanField(msg, 3)) == null ? undefined : f,
domesticVatList: (f = jspb.Message.getRepeatedBooleanField(msg, 4)) == null ? undefined : f,
useAutomationList: (f = jspb.Message.getRepeatedBooleanField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ArticleTypeFilter;
  return proto.swift.invoicing.v1.ArticleTypeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ArticleTypeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.ArticleNumberFilter;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleNumberFilter.deserializeBinaryFromReader);
      msg.setArticleNumberFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.swift.invoicing.v1.ArticleType.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategory(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEuVat(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDomesticVat(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<boolean>} */ (reader.isDelimited() ? reader.readPackedBool() : [reader.readBool()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUseAutomation(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ArticleTypeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ArticleTypeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleTypeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleNumberFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.ArticleNumberFilter.serializeBinaryToWriter
    );
  }
  f = message.getCategoryList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getEuVatList();
  if (f.length > 0) {
    writer.writePackedBool(
      3,
      f
    );
  }
  f = message.getDomesticVatList();
  if (f.length > 0) {
    writer.writePackedBool(
      4,
      f
    );
  }
  f = message.getUseAutomationList();
  if (f.length > 0) {
    writer.writePackedBool(
      5,
      f
    );
  }
};


/**
 * optional ArticleNumberFilter article_number_filter = 1;
 * @return {?proto.swift.invoicing.v1.ArticleNumberFilter}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.getArticleNumberFilter = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleNumberFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleNumberFilter, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleNumberFilter|undefined} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
*/
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.setArticleNumberFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.clearArticleNumberFilter = function() {
  return this.setArticleNumberFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.hasArticleNumberFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ArticleType.Category category = 2;
 * @return {!Array<!proto.swift.invoicing.v1.ArticleType.Category>}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.getCategoryList = function() {
  return /** @type {!Array<!proto.swift.invoicing.v1.ArticleType.Category>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.ArticleType.Category>} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.setCategoryList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleType.Category} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.addCategory = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.clearCategoryList = function() {
  return this.setCategoryList([]);
};


/**
 * repeated bool eu_vat = 3;
 * @return {!Array<boolean>}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.getEuVatList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 3));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.setEuVatList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.addEuVat = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.clearEuVatList = function() {
  return this.setEuVatList([]);
};


/**
 * repeated bool domestic_vat = 4;
 * @return {!Array<boolean>}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.getDomesticVatList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 4));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.setDomesticVatList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.addDomesticVat = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.clearDomesticVatList = function() {
  return this.setDomesticVatList([]);
};


/**
 * repeated bool use_automation = 5;
 * @return {!Array<boolean>}
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.getUseAutomationList = function() {
  return /** @type {!Array<boolean>} */ (jspb.Message.getRepeatedBooleanField(this, 5));
};


/**
 * @param {!Array<boolean>} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.setUseAutomationList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {boolean} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.addUseAutomation = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ArticleTypeFilter} returns this
 */
proto.swift.invoicing.v1.ArticleTypeFilter.prototype.clearUseAutomationList = function() {
  return this.setUseAutomationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ArticleNumberFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ArticleNumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ArticleNumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleNumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
filterField: jspb.Message.getFieldWithDefault(msg, 1, 0),
articleNumberListList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
articleNumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ArticleNumberFilter;
  return proto.swift.invoicing.v1.ArticleNumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ArticleNumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.invoicing.v1.ArticleNumberFilter.FilterField} */ (reader.readEnum());
      msg.setFilterField(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArticleNumberList(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ArticleNumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ArticleNumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleNumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getArticleNumberListList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getArticleNumber();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.FilterField = {
  ARTICLE_NUMBER: 0,
  GTE: 1,
  LTE: 2
};

/**
 * optional FilterField filter_field = 1;
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter.FilterField}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.getFilterField = function() {
  return /** @type {!proto.swift.invoicing.v1.ArticleNumberFilter.FilterField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleNumberFilter.FilterField} value
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter} returns this
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.setFilterField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated int64 article_number_list = 2;
 * @return {!Array<number>}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.getArticleNumberListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter} returns this
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.setArticleNumberListList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter} returns this
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.addArticleNumberList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter} returns this
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.clearArticleNumberListList = function() {
  return this.setArticleNumberListList([]);
};


/**
 * optional int64 article_number = 3;
 * @return {number}
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.getArticleNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ArticleNumberFilter} returns this
 */
proto.swift.invoicing.v1.ArticleNumberFilter.prototype.setArticleNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ArticleTypeSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ArticleTypeSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleTypeSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
sortingField: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ArticleTypeSorting;
  return proto.swift.invoicing.v1.ArticleTypeSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ArticleTypeSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.invoicing.v1.ArticleTypeSorting.SortingField} */ (reader.readEnum());
      msg.setSortingField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.ArticleTypeSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ArticleTypeSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ArticleTypeSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ArticleTypeSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortingField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.SortingField = {
  ARTICLE_NO: 0
};

/**
 * optional SortingField sorting_field = 1;
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting.SortingField}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.getSortingField = function() {
  return /** @type {!proto.swift.invoicing.v1.ArticleTypeSorting.SortingField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleTypeSorting.SortingField} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting} returns this
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.setSortingField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting.Ordering}
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.invoicing.v1.ArticleTypeSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleTypeSorting.Ordering} value
 * @return {!proto.swift.invoicing.v1.ArticleTypeSorting} returns this
 */
proto.swift.invoicing.v1.ArticleTypeSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListArticleTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListArticleTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.invoicing.v1.ArticleTypeFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.invoicing.v1.ArticleTypeSorting.toObject(includeInstance, f),
branchCountryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
customerCountryId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListArticleTypesRequest;
  return proto.swift.invoicing.v1.ListArticleTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListArticleTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.ArticleTypeFilter;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleTypeFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.invoicing.v1.ArticleTypeSorting;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleTypeSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchCountryId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerCountryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListArticleTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListArticleTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.invoicing.v1.ArticleTypeFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.invoicing.v1.ArticleTypeSorting.serializeBinaryToWriter
    );
  }
  f = message.getBranchCountryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCustomerCountryId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
*/
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ArticleTypeFilter filter = 2;
 * @return {?proto.swift.invoicing.v1.ArticleTypeFilter}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleTypeFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleTypeFilter, 2));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleTypeFilter|undefined} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
*/
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ArticleTypeSorting sorting = 3;
 * @return {?proto.swift.invoicing.v1.ArticleTypeSorting}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleTypeSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleTypeSorting, 3));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleTypeSorting|undefined} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
*/
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string branch_country_id = 4;
 * @return {string}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.getBranchCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.setBranchCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string customer_country_id = 5;
 * @return {string}
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.getCustomerCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesRequest} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesRequest.prototype.setCustomerCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.ListArticleTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.ListArticleTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
articleTypesList: jspb.Message.toObjectList(msg.getArticleTypesList(),
    proto.swift.invoicing.v1.ArticleType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesResponse}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.ListArticleTypesResponse;
  return proto.swift.invoicing.v1.ListArticleTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.ListArticleTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesResponse}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.invoicing.v1.ArticleType;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader);
      msg.addArticleTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.ListArticleTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.ListArticleTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getArticleTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesResponse} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ArticleType article_types = 2;
 * @return {!Array<!proto.swift.invoicing.v1.ArticleType>}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.getArticleTypesList = function() {
  return /** @type{!Array<!proto.swift.invoicing.v1.ArticleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.invoicing.v1.ArticleType, 2));
};


/**
 * @param {!Array<!proto.swift.invoicing.v1.ArticleType>} value
 * @return {!proto.swift.invoicing.v1.ListArticleTypesResponse} returns this
*/
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.setArticleTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.invoicing.v1.ArticleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.addArticleTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.invoicing.v1.ArticleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.invoicing.v1.ListArticleTypesResponse} returns this
 */
proto.swift.invoicing.v1.ListArticleTypesResponse.prototype.clearArticleTypesList = function() {
  return this.setArticleTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateArticleTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
articleType: (f = msg.getArticleType()) && proto.swift.invoicing.v1.ArticleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeRequest}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateArticleTypeRequest;
  return proto.swift.invoicing.v1.CreateArticleTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeRequest}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.ArticleType;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader);
      msg.setArticleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateArticleTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArticleType article_type = 1;
 * @return {?proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.getArticleType = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleType} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleType, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleType|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeRequest} returns this
*/
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.setArticleType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeRequest} returns this
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.clearArticleType = function() {
  return this.setArticleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateArticleTypeRequest.prototype.hasArticleType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.CreateArticleTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
articleType: (f = msg.getArticleType()) && proto.swift.invoicing.v1.ArticleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeResponse}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.CreateArticleTypeResponse;
  return proto.swift.invoicing.v1.CreateArticleTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeResponse}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.ArticleType;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader);
      msg.setArticleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.CreateArticleTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.CreateArticleTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArticleType article_type = 1;
 * @return {?proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.getArticleType = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleType} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleType, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleType|undefined} value
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeResponse} returns this
*/
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.setArticleType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.CreateArticleTypeResponse} returns this
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.clearArticleType = function() {
  return this.setArticleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.CreateArticleTypeResponse.prototype.hasArticleType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateArticleTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
articleType: (f = msg.getArticleType()) && proto.swift.invoicing.v1.ArticleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeRequest}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateArticleTypeRequest;
  return proto.swift.invoicing.v1.UpdateArticleTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeRequest}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.ArticleType;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader);
      msg.setArticleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateArticleTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArticleType article_type = 1;
 * @return {?proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.getArticleType = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleType} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleType, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleType|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeRequest} returns this
*/
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.setArticleType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeRequest} returns this
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.clearArticleType = function() {
  return this.setArticleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateArticleTypeRequest.prototype.hasArticleType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.UpdateArticleTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
articleType: (f = msg.getArticleType()) && proto.swift.invoicing.v1.ArticleType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeResponse}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.UpdateArticleTypeResponse;
  return proto.swift.invoicing.v1.UpdateArticleTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeResponse}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.invoicing.v1.ArticleType;
      reader.readMessage(value,proto.swift.invoicing.v1.ArticleType.deserializeBinaryFromReader);
      msg.setArticleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.UpdateArticleTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.UpdateArticleTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.invoicing.v1.ArticleType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ArticleType article_type = 1;
 * @return {?proto.swift.invoicing.v1.ArticleType}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.getArticleType = function() {
  return /** @type{?proto.swift.invoicing.v1.ArticleType} */ (
    jspb.Message.getWrapperField(this, proto.swift.invoicing.v1.ArticleType, 1));
};


/**
 * @param {?proto.swift.invoicing.v1.ArticleType|undefined} value
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeResponse} returns this
*/
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.setArticleType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.invoicing.v1.UpdateArticleTypeResponse} returns this
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.clearArticleType = function() {
  return this.setArticleType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.invoicing.v1.UpdateArticleTypeResponse.prototype.hasArticleType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteArticleTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
articleNo: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeRequest}
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteArticleTypeRequest;
  return proto.swift.invoicing.v1.DeleteArticleTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeRequest}
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteArticleTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 article_no = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeRequest} returns this
 */
proto.swift.invoicing.v1.DeleteArticleTypeRequest.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.invoicing.v1.DeleteArticleTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
articleNo: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeResponse}
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.invoicing.v1.DeleteArticleTypeResponse;
  return proto.swift.invoicing.v1.DeleteArticleTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeResponse}
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArticleNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.invoicing.v1.DeleteArticleTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.invoicing.v1.DeleteArticleTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticleNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 article_no = 1;
 * @return {number}
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.prototype.getArticleNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.invoicing.v1.DeleteArticleTypeResponse} returns this
 */
proto.swift.invoicing.v1.DeleteArticleTypeResponse.prototype.setArticleNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.swift.invoicing.v1);
