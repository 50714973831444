/**
 * @fileoverview gRPC-Web generated client stub for swift.document.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: document/v1/document.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_owner_pb = require('../../common/owner_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js')

var shipment_v1_shipment_pb = require('../../shipment/v1/shipment_pb.js')
const proto = {};
proto.swift = {};
proto.swift.document = {};
proto.swift.document.v1 = require('./document_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.document.v1.DocumentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.document.v1.DocumentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.ListDocumentsRequest,
 *   !proto.swift.document.v1.ListDocumentsResponse>}
 */
const methodDescriptor_DocumentService_ListDocuments = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/ListDocuments',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.ListDocumentsRequest,
  proto.swift.document.v1.ListDocumentsResponse,
  /**
   * @param {!proto.swift.document.v1.ListDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.ListDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.ListDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.ListDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.listDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_ListDocuments,
      callback);
};


/**
 * @param {!proto.swift.document.v1.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.ListDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.listDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_ListDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.GetDocumentRequest,
 *   !proto.swift.document.v1.GetDocumentResponse>}
 */
const methodDescriptor_DocumentService_GetDocument = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/GetDocument',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.GetDocumentRequest,
  proto.swift.document.v1.GetDocumentResponse,
  /**
   * @param {!proto.swift.document.v1.GetDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.GetDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.GetDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.GetDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.GetDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.getDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GetDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetDocument,
      callback);
};


/**
 * @param {!proto.swift.document.v1.GetDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.GetDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.getDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GetDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.CreateDocumentUploadLinkRequest,
 *   !proto.swift.document.v1.CreateDocumentUploadLinkResponse>}
 */
const methodDescriptor_DocumentService_CreateDocumentUploadLink = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/CreateDocumentUploadLink',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.CreateDocumentUploadLinkRequest,
  proto.swift.document.v1.CreateDocumentUploadLinkResponse,
  /**
   * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.CreateDocumentUploadLinkResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.CreateDocumentUploadLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.CreateDocumentUploadLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.createDocumentUploadLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/CreateDocumentUploadLink',
      request,
      metadata || {},
      methodDescriptor_DocumentService_CreateDocumentUploadLink,
      callback);
};


/**
 * @param {!proto.swift.document.v1.CreateDocumentUploadLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.CreateDocumentUploadLinkResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.createDocumentUploadLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/CreateDocumentUploadLink',
      request,
      metadata || {},
      methodDescriptor_DocumentService_CreateDocumentUploadLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.CreateDocumentRequest,
 *   !proto.swift.document.v1.CreateDocumentResponse>}
 */
const methodDescriptor_DocumentService_CreateDocument = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/CreateDocument',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.CreateDocumentRequest,
  proto.swift.document.v1.CreateDocumentResponse,
  /**
   * @param {!proto.swift.document.v1.CreateDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.CreateDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.CreateDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.CreateDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.CreateDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.createDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/CreateDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_CreateDocument,
      callback);
};


/**
 * @param {!proto.swift.document.v1.CreateDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.CreateDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.createDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/CreateDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_CreateDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.SetDocumentUsageRequest,
 *   !proto.swift.document.v1.SetDocumentUsageResponse>}
 */
const methodDescriptor_DocumentService_SetDocumentUsage = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/SetDocumentUsage',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.SetDocumentUsageRequest,
  proto.swift.document.v1.SetDocumentUsageResponse,
  /**
   * @param {!proto.swift.document.v1.SetDocumentUsageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.SetDocumentUsageResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.SetDocumentUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.SetDocumentUsageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.SetDocumentUsageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.setDocumentUsage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/SetDocumentUsage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_SetDocumentUsage,
      callback);
};


/**
 * @param {!proto.swift.document.v1.SetDocumentUsageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.SetDocumentUsageResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.setDocumentUsage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/SetDocumentUsage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_SetDocumentUsage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.DeleteDocumentRequest,
 *   !proto.swift.document.v1.DeleteDocumentResponse>}
 */
const methodDescriptor_DocumentService_DeleteDocument = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/DeleteDocument',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.DeleteDocumentRequest,
  proto.swift.document.v1.DeleteDocumentResponse,
  /**
   * @param {!proto.swift.document.v1.DeleteDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.DeleteDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.DeleteDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.DeleteDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.deleteDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/DeleteDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_DeleteDocument,
      callback);
};


/**
 * @param {!proto.swift.document.v1.DeleteDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.DeleteDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.deleteDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/DeleteDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_DeleteDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.GenerateBookingDocumentRequest,
 *   !proto.swift.document.v1.GenerateBookingDocumentResponse>}
 */
const methodDescriptor_DocumentService_GenerateBookingDocument = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/GenerateBookingDocument',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.GenerateBookingDocumentRequest,
  proto.swift.document.v1.GenerateBookingDocumentResponse,
  /**
   * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.GenerateBookingDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.GenerateBookingDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.GenerateBookingDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.generateBookingDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GenerateBookingDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GenerateBookingDocument,
      callback);
};


/**
 * @param {!proto.swift.document.v1.GenerateBookingDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.GenerateBookingDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.generateBookingDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GenerateBookingDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GenerateBookingDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.document.v1.GenerateShipmentDocumentRequest,
 *   !proto.swift.document.v1.GenerateShipmentDocumentResponse>}
 */
const methodDescriptor_DocumentService_GenerateShipmentDocument = new grpc.web.MethodDescriptor(
  '/swift.document.v1.DocumentService/GenerateShipmentDocument',
  grpc.web.MethodType.UNARY,
  proto.swift.document.v1.GenerateShipmentDocumentRequest,
  proto.swift.document.v1.GenerateShipmentDocumentResponse,
  /**
   * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.document.v1.GenerateShipmentDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.document.v1.GenerateShipmentDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.document.v1.GenerateShipmentDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.document.v1.DocumentServiceClient.prototype.generateShipmentDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GenerateShipmentDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GenerateShipmentDocument,
      callback);
};


/**
 * @param {!proto.swift.document.v1.GenerateShipmentDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.document.v1.GenerateShipmentDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.document.v1.DocumentServicePromiseClient.prototype.generateShipmentDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.document.v1.DocumentService/GenerateShipmentDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GenerateShipmentDocument);
};


module.exports = proto.swift.document.v1;

