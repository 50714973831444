import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import * as userv1 from '../proto/iam/v1/user_pb'

import { Actions } from '../store/auth/actions'
import * as userSelectors from '../store/auth/reducer'
import * as iamUserSelectors from '../store/iam/user/reducer'
import { RootState } from '../store/reducer'

import { FullSizeSpinner } from 'components/Spinners'

import { RouteNames, getDefaultRoutePerRole } from '../route/routes-map'

const AuthCallback = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isAuthorized = useSelector((state: RootState) => userSelectors.getIsAuthenticated(state))
  const currentUser = useSelector<RootState, userv1.User | undefined>((state) =>
    iamUserSelectors.getCurrentUser(state),
  )
  const isFetchingUser = useSelector<RootState, boolean | undefined>((state) =>
    iamUserSelectors.getIsFetching(state),
  )

  useEffect(() => {
    dispatch(Actions.authenticate())
  }, [])

  useEffect(() => {
    if (isAuthorized && currentUser) {
      if (currentUser.getRolesList().includes(userv1.User.Role.ADMIN)) {
        navigate(RouteNames.AdminUsers)
      } else {
        const defaultRoute = getDefaultRoutePerRole(currentUser.getRolesList())
        navigate(defaultRoute)
      }
    } else if (isAuthorized && !currentUser && !isFetchingUser) {
      navigate(RouteNames.Setup)
    }
  }, [currentUser, isAuthorized, isFetchingUser])

  return <FullSizeSpinner />
}

export default AuthCallback
