/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: booking/v1/nomination.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var booking_v1_supplier_pb = require('../../booking/v1/supplier_pb.js')

var booking_v1_pricing_pb = require('../../booking/v1/pricing_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./nomination_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.NominationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.NominationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListNominationsRequest,
 *   !proto.swift.booking.v1.ListNominationsResponse>}
 */
const methodDescriptor_NominationService_ListNominations = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/ListNominations',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListNominationsRequest,
  proto.swift.booking.v1.ListNominationsResponse,
  /**
   * @param {!proto.swift.booking.v1.ListNominationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListNominationsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListNominationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListNominationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.listNominations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/ListNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_ListNominations,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListNominationsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.listNominations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/ListNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_ListNominations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditNominationRequest,
 *   !proto.swift.booking.v1.EditNominationResponse>}
 */
const methodDescriptor_NominationService_EditNomination = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/EditNomination',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditNominationRequest,
  proto.swift.booking.v1.EditNominationResponse,
  /**
   * @param {!proto.swift.booking.v1.EditNominationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditNominationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditNominationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditNominationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditNominationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.editNomination =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/EditNomination',
      request,
      metadata || {},
      methodDescriptor_NominationService_EditNomination,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditNominationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditNominationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.editNomination =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/EditNomination',
      request,
      metadata || {},
      methodDescriptor_NominationService_EditNomination);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteNominationsRequest,
 *   !proto.swift.booking.v1.DeleteNominationsResponse>}
 */
const methodDescriptor_NominationService_DeleteNominations = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/DeleteNominations',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteNominationsRequest,
  proto.swift.booking.v1.DeleteNominationsResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteNominationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteNominationsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteNominationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteNominationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.deleteNominations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/DeleteNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_DeleteNominations,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteNominationsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.deleteNominations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/DeleteNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_DeleteNominations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetNominationPriceRequest,
 *   !proto.swift.booking.v1.SetNominationPriceResponse>}
 */
const methodDescriptor_NominationService_SetNominationPrice = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/SetNominationPrice',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetNominationPriceRequest,
  proto.swift.booking.v1.SetNominationPriceResponse,
  /**
   * @param {!proto.swift.booking.v1.SetNominationPriceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetNominationPriceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetNominationPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetNominationPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetNominationPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.setNominationPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/SetNominationPrice',
      request,
      metadata || {},
      methodDescriptor_NominationService_SetNominationPrice,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetNominationPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetNominationPriceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.setNominationPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/SetNominationPrice',
      request,
      metadata || {},
      methodDescriptor_NominationService_SetNominationPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.RevalidateNominationsRequest,
 *   !proto.swift.booking.v1.RevalidateNominationsResponse>}
 */
const methodDescriptor_NominationService_RevalidateNominations = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/RevalidateNominations',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.RevalidateNominationsRequest,
  proto.swift.booking.v1.RevalidateNominationsResponse,
  /**
   * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.RevalidateNominationsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.RevalidateNominationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.RevalidateNominationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.revalidateNominations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/RevalidateNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_RevalidateNominations,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.RevalidateNominationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.RevalidateNominationsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.revalidateNominations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/RevalidateNominations',
      request,
      metadata || {},
      methodDescriptor_NominationService_RevalidateNominations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateBookingDataRequest,
 *   !proto.swift.booking.v1.UpdateBookingDataResponse>}
 */
const methodDescriptor_NominationService_UpdateBookingData = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.NominationService/UpdateBookingData',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateBookingDataRequest,
  proto.swift.booking.v1.UpdateBookingDataResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateBookingDataResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateBookingDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateBookingDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.NominationServiceClient.prototype.updateBookingData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.NominationService/UpdateBookingData',
      request,
      metadata || {},
      methodDescriptor_NominationService_UpdateBookingData,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateBookingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateBookingDataResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.NominationServicePromiseClient.prototype.updateBookingData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.NominationService/UpdateBookingData',
      request,
      metadata || {},
      methodDescriptor_NominationService_UpdateBookingData);
};


module.exports = proto.swift.booking.v1;

