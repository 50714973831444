// source: shipment/v1/shipment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var invoicing_v1_cost_pb = require('../../invoicing/v1/cost_pb.js');
goog.object.extend(proto, invoicing_v1_cost_pb);
goog.exportSymbol('proto.swift.shipment.v1.AddBookingToShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.AddBookingToShipmentResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.CreateShipmentCostRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.CreateShipmentCostResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.CreateShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.CreateShipmentResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.GetShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.GetShipmentResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.GetShipmentStatsRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.GetShipmentStatsResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ListShipmentsRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ListShipmentsResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ListSplitCostReferenceRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ListSplitCostReferenceResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.RemoveBookingInShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.RemoveBookingInShipmentResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.Shipment', null, global);
goog.exportSymbol('proto.swift.shipment.v1.Shipment.Label', null, global);
goog.exportSymbol('proto.swift.shipment.v1.Shipment.Status', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentFilter', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentMode', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentModePoint', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentSorting', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentSorting.Field', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentStats', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentStats.DataCase', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentStatsQuery', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentStatsQuery.Grouping', null, global);
goog.exportSymbol('proto.swift.shipment.v1.ShipmentStatsQuery.Type', null, global);
goog.exportSymbol('proto.swift.shipment.v1.SplitCostItem', null, global);
goog.exportSymbol('proto.swift.shipment.v1.SplitCostReference', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentCostRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentCostResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentCostStatusRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentCostStatusResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentRequest', null, global);
goog.exportSymbol('proto.swift.shipment.v1.UpdateShipmentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.Shipment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.Shipment.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.Shipment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.Shipment.displayName = 'proto.swift.shipment.v1.Shipment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentStatsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.ShipmentStatsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentStatsQuery.displayName = 'proto.swift.shipment.v1.ShipmentStatsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.shipment.v1.ShipmentStats.oneofGroups_);
};
goog.inherits(proto.swift.shipment.v1.ShipmentStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentStats.displayName = 'proto.swift.shipment.v1.ShipmentStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.ShipmentMode.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.ShipmentMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentMode.displayName = 'proto.swift.shipment.v1.ShipmentMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentModePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.ShipmentModePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentModePoint.displayName = 'proto.swift.shipment.v1.ShipmentModePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.ShipmentFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.ShipmentFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentFilter.displayName = 'proto.swift.shipment.v1.ShipmentFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ShipmentSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.ShipmentSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ShipmentSorting.displayName = 'proto.swift.shipment.v1.ShipmentSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.SplitCostReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.SplitCostReference.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.SplitCostReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.SplitCostReference.displayName = 'proto.swift.shipment.v1.SplitCostReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.SplitCostItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.SplitCostItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.SplitCostItem.displayName = 'proto.swift.shipment.v1.SplitCostItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ListShipmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.ListShipmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ListShipmentsRequest.displayName = 'proto.swift.shipment.v1.ListShipmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ListShipmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.ListShipmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.ListShipmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ListShipmentsResponse.displayName = 'proto.swift.shipment.v1.ListShipmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.displayName = 'proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.displayName = 'proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.AddBookingToShipmentRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.AddBookingToShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.AddBookingToShipmentRequest.displayName = 'proto.swift.shipment.v1.AddBookingToShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.AddBookingToShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.AddBookingToShipmentResponse.displayName = 'proto.swift.shipment.v1.AddBookingToShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.RemoveBookingInShipmentRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.RemoveBookingInShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.RemoveBookingInShipmentRequest.displayName = 'proto.swift.shipment.v1.RemoveBookingInShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.RemoveBookingInShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.RemoveBookingInShipmentResponse.displayName = 'proto.swift.shipment.v1.RemoveBookingInShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.GetShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.GetShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.GetShipmentRequest.displayName = 'proto.swift.shipment.v1.GetShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.GetShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.GetShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.GetShipmentResponse.displayName = 'proto.swift.shipment.v1.GetShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.CreateShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.CreateShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.CreateShipmentRequest.displayName = 'proto.swift.shipment.v1.CreateShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.CreateShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.CreateShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.CreateShipmentResponse.displayName = 'proto.swift.shipment.v1.CreateShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentRequest.displayName = 'proto.swift.shipment.v1.UpdateShipmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentResponse.displayName = 'proto.swift.shipment.v1.UpdateShipmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.GetShipmentStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.GetShipmentStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.GetShipmentStatsRequest.displayName = 'proto.swift.shipment.v1.GetShipmentStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.GetShipmentStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.GetShipmentStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.GetShipmentStatsResponse.displayName = 'proto.swift.shipment.v1.GetShipmentStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.displayName = 'proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.displayName = 'proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentCostStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.displayName = 'proto.swift.shipment.v1.UpdateShipmentCostStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentCostStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.displayName = 'proto.swift.shipment.v1.UpdateShipmentCostStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.CreateShipmentCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.CreateShipmentCostRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.CreateShipmentCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.CreateShipmentCostRequest.displayName = 'proto.swift.shipment.v1.CreateShipmentCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.CreateShipmentCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.CreateShipmentCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.CreateShipmentCostResponse.displayName = 'proto.swift.shipment.v1.CreateShipmentCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.UpdateShipmentCostRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentCostRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentCostRequest.displayName = 'proto.swift.shipment.v1.UpdateShipmentCostRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.UpdateShipmentCostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.UpdateShipmentCostResponse.displayName = 'proto.swift.shipment.v1.UpdateShipmentCostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.shipment.v1.ListSplitCostReferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ListSplitCostReferenceRequest.displayName = 'proto.swift.shipment.v1.ListSplitCostReferenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.shipment.v1.ListSplitCostReferenceResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.shipment.v1.ListSplitCostReferenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.shipment.v1.ListSplitCostReferenceResponse.displayName = 'proto.swift.shipment.v1.ListSplitCostReferenceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.Shipment.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.Shipment.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.Shipment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.Shipment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.Shipment.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
status: jspb.Message.getFieldWithDefault(msg, 3, 0),
shipmentRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
transportRef: jspb.Message.getFieldWithDefault(msg, 5, ""),
transportMode: jspb.Message.getFieldWithDefault(msg, 6, 0),
estimatedTimeDeparture: (f = msg.getEstimatedTimeDeparture()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
estimatedTimeArrival: (f = msg.getEstimatedTimeArrival()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
labelsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
internalNote: jspb.Message.getFieldWithDefault(msg, 11, ""),
internalNoteRemindAt: (f = msg.getInternalNoteRemindAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
internalNoteEditedAt: (f = msg.getInternalNoteEditedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
financialMonth: (f = msg.getFinancialMonth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.Shipment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.Shipment;
  return proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.Shipment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.shipment.v1.Shipment.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportRef(value);
      break;
    case 6:
      var value = /** @type {!proto.swift.common.TransportMode} */ (reader.readEnum());
      msg.setTransportMode(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeDeparture(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeArrival(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.swift.shipment.v1.Shipment.Label>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLabels(values[i]);
      }
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInternalNoteRemindAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInternalNoteEditedAt(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinancialMonth(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.Shipment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.Shipment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.Shipment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.Shipment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransportRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTransportMode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEstimatedTimeDeparture();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTimeArrival();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInternalNoteRemindAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalNoteEditedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFinancialMonth();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.shipment.v1.Shipment.Status = {
  NEW: 0
};

/**
 * @enum {number}
 */
proto.swift.shipment.v1.Shipment.Label = {
  COLOADING: 0
};

/**
 * optional int64 shipment_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.Shipment.prototype.getShipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.shipment.v1.Shipment.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status status = 3;
 * @return {!proto.swift.shipment.v1.Shipment.Status}
 */
proto.swift.shipment.v1.Shipment.prototype.getStatus = function() {
  return /** @type {!proto.swift.shipment.v1.Shipment.Status} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.shipment.v1.Shipment.Status} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string shipment_ref = 4;
 * @return {string}
 */
proto.swift.shipment.v1.Shipment.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transport_ref = 5;
 * @return {string}
 */
proto.swift.shipment.v1.Shipment.prototype.getTransportRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setTransportRef = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional swift.common.TransportMode transport_mode = 6;
 * @return {!proto.swift.common.TransportMode}
 */
proto.swift.shipment.v1.Shipment.prototype.getTransportMode = function() {
  return /** @type {!proto.swift.common.TransportMode} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setTransportMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp estimated_time_departure = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getEstimatedTimeDeparture = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setEstimatedTimeDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearEstimatedTimeDeparture = function() {
  return this.setEstimatedTimeDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasEstimatedTimeDeparture = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp estimated_time_arrival = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getEstimatedTimeArrival = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setEstimatedTimeArrival = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearEstimatedTimeArrival = function() {
  return this.setEstimatedTimeArrival(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasEstimatedTimeArrival = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string booking_refs = 9;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.Shipment.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};


/**
 * repeated Label labels = 10;
 * @return {!Array<!proto.swift.shipment.v1.Shipment.Label>}
 */
proto.swift.shipment.v1.Shipment.prototype.getLabelsList = function() {
  return /** @type {!Array<!proto.swift.shipment.v1.Shipment.Label>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.swift.shipment.v1.Shipment.Label>} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.swift.shipment.v1.Shipment.Label} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional string internal_note = 11;
 * @return {string}
 */
proto.swift.shipment.v1.Shipment.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp internal_note_remind_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getInternalNoteRemindAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setInternalNoteRemindAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearInternalNoteRemindAt = function() {
  return this.setInternalNoteRemindAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasInternalNoteRemindAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp internal_note_edited_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getInternalNoteEditedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setInternalNoteEditedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearInternalNoteEditedAt = function() {
  return this.setInternalNoteEditedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasInternalNoteEditedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp financial_month = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getFinancialMonth = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setFinancialMonth = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearFinancialMonth = function() {
  return this.setFinancialMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasFinancialMonth = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.Shipment.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.Shipment} returns this
*/
proto.swift.shipment.v1.Shipment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.Shipment} returns this
 */
proto.swift.shipment.v1.Shipment.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.Shipment.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentStatsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentStatsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentStatsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
grouping: jspb.Message.getFieldWithDefault(msg, 2, 0),
from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentStatsQuery;
  return proto.swift.shipment.v1.ShipmentStatsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentStatsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.shipment.v1.ShipmentStatsQuery.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.shipment.v1.ShipmentStatsQuery.Grouping} */ (reader.readEnum());
      msg.setGrouping(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentStatsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentStatsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentStatsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGrouping();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.Type = {
  SHIPMENT_MODE: 0
};

/**
 * @enum {number}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.Grouping = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery.Type}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.getType = function() {
  return /** @type {!proto.swift.shipment.v1.ShipmentStatsQuery.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.shipment.v1.ShipmentStatsQuery.Type} value
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Grouping grouping = 2;
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery.Grouping}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.getGrouping = function() {
  return /** @type {!proto.swift.shipment.v1.ShipmentStatsQuery.Grouping} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.shipment.v1.ShipmentStatsQuery.Grouping} value
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.setGrouping = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
*/
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
*/
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ShipmentStatsQuery} returns this
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ShipmentStatsQuery.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.shipment.v1.ShipmentStats.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.swift.shipment.v1.ShipmentStats.DataCase = {
  DATA_NOT_SET: 0,
  SHIPMENT_MODE: 1
};

/**
 * @return {proto.swift.shipment.v1.ShipmentStats.DataCase}
 */
proto.swift.shipment.v1.ShipmentStats.prototype.getDataCase = function() {
  return /** @type {proto.swift.shipment.v1.ShipmentStats.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.shipment.v1.ShipmentStats.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentStats.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentStats.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentMode: (f = msg.getShipmentMode()) && proto.swift.shipment.v1.ShipmentMode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentStats}
 */
proto.swift.shipment.v1.ShipmentStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentStats;
  return proto.swift.shipment.v1.ShipmentStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentStats}
 */
proto.swift.shipment.v1.ShipmentStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.ShipmentMode;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentMode.deserializeBinaryFromReader);
      msg.setShipmentMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentMode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.ShipmentMode.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShipmentMode shipment_mode = 1;
 * @return {?proto.swift.shipment.v1.ShipmentMode}
 */
proto.swift.shipment.v1.ShipmentStats.prototype.getShipmentMode = function() {
  return /** @type{?proto.swift.shipment.v1.ShipmentMode} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.ShipmentMode, 1));
};


/**
 * @param {?proto.swift.shipment.v1.ShipmentMode|undefined} value
 * @return {!proto.swift.shipment.v1.ShipmentStats} returns this
*/
proto.swift.shipment.v1.ShipmentStats.prototype.setShipmentMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.swift.shipment.v1.ShipmentStats.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ShipmentStats} returns this
 */
proto.swift.shipment.v1.ShipmentStats.prototype.clearShipmentMode = function() {
  return this.setShipmentMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ShipmentStats.prototype.hasShipmentMode = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.ShipmentMode.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentMode.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentMode.toObject = function(includeInstance, msg) {
  var f, obj = {
pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.swift.shipment.v1.ShipmentModePoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentMode}
 */
proto.swift.shipment.v1.ShipmentMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentMode;
  return proto.swift.shipment.v1.ShipmentMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentMode}
 */
proto.swift.shipment.v1.ShipmentMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.ShipmentModePoint;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentModePoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.shipment.v1.ShipmentModePoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ShipmentModePoint points = 1;
 * @return {!Array<!proto.swift.shipment.v1.ShipmentModePoint>}
 */
proto.swift.shipment.v1.ShipmentMode.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.swift.shipment.v1.ShipmentModePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.shipment.v1.ShipmentModePoint, 1));
};


/**
 * @param {!Array<!proto.swift.shipment.v1.ShipmentModePoint>} value
 * @return {!proto.swift.shipment.v1.ShipmentMode} returns this
*/
proto.swift.shipment.v1.ShipmentMode.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.shipment.v1.ShipmentModePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.ShipmentModePoint}
 */
proto.swift.shipment.v1.ShipmentMode.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.shipment.v1.ShipmentModePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.ShipmentMode} returns this
 */
proto.swift.shipment.v1.ShipmentMode.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentModePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentModePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentModePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
air: jspb.Message.getFieldWithDefault(msg, 2, 0),
sea: jspb.Message.getFieldWithDefault(msg, 3, 0),
rail: jspb.Message.getFieldWithDefault(msg, 4, 0),
road: jspb.Message.getFieldWithDefault(msg, 5, 0),
courier: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentModePoint}
 */
proto.swift.shipment.v1.ShipmentModePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentModePoint;
  return proto.swift.shipment.v1.ShipmentModePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentModePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentModePoint}
 */
proto.swift.shipment.v1.ShipmentModePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAir(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSea(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRail(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoad(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCourier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentModePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentModePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentModePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAir();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSea();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRail();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getRoad();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCourier();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
*/
proto.swift.shipment.v1.ShipmentModePoint.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 air = 2;
 * @return {number}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getAir = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.setAir = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 sea = 3;
 * @return {number}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getSea = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.setSea = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 rail = 4;
 * @return {number}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getRail = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.setRail = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 road = 5;
 * @return {number}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getRoad = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.setRoad = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 courier = 6;
 * @return {number}
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.getCourier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ShipmentModePoint} returns this
 */
proto.swift.shipment.v1.ShipmentModePoint.prototype.setCourier = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.ShipmentFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
search: jspb.Message.getFieldWithDefault(msg, 1, ""),
transportOperatorUserIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentFilter}
 */
proto.swift.shipment.v1.ShipmentFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentFilter;
  return proto.swift.shipment.v1.ShipmentFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentFilter}
 */
proto.swift.shipment.v1.ShipmentFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransportOperatorUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransportOperatorUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.ShipmentFilter} returns this
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string transport_operator_user_id = 2;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.getTransportOperatorUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.ShipmentFilter} returns this
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.setTransportOperatorUserIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.ShipmentFilter} returns this
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.addTransportOperatorUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.ShipmentFilter} returns this
 */
proto.swift.shipment.v1.ShipmentFilter.prototype.clearTransportOperatorUserIdList = function() {
  return this.setTransportOperatorUserIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ShipmentSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ShipmentSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ShipmentSorting}
 */
proto.swift.shipment.v1.ShipmentSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ShipmentSorting;
  return proto.swift.shipment.v1.ShipmentSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ShipmentSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ShipmentSorting}
 */
proto.swift.shipment.v1.ShipmentSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.shipment.v1.ShipmentSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.shipment.v1.ShipmentSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ShipmentSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ShipmentSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ShipmentSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.shipment.v1.ShipmentSorting.Ordering = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.swift.shipment.v1.ShipmentSorting.Field = {
  SHIPMENT_ID: 0,
  STATUS: 1,
  SHIPMENT_REF: 2,
  MODE: 3,
  BOOKING_REF: 4,
  CREATED_AT: 5,
  TRANSPORT_OPERATOR: 6
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.shipment.v1.ShipmentSorting.Field}
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.getField = function() {
  return /** @type {!proto.swift.shipment.v1.ShipmentSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.shipment.v1.ShipmentSorting.Field} value
 * @return {!proto.swift.shipment.v1.ShipmentSorting} returns this
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.shipment.v1.ShipmentSorting.Ordering}
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.shipment.v1.ShipmentSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.shipment.v1.ShipmentSorting.Ordering} value
 * @return {!proto.swift.shipment.v1.ShipmentSorting} returns this
 */
proto.swift.shipment.v1.ShipmentSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.SplitCostReference.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.SplitCostReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.SplitCostReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.SplitCostReference.toObject = function(includeInstance, msg) {
  var f, obj = {
splitCostReferenceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
shipmentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
costItemsList: jspb.Message.toObjectList(msg.getCostItemsList(),
    proto.swift.shipment.v1.SplitCostItem.toObject, includeInstance),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
branchId: jspb.Message.getFieldWithDefault(msg, 8, 0),
comment: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.SplitCostReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.SplitCostReference;
  return proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.SplitCostReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitCostReferenceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = new proto.swift.shipment.v1.SplitCostItem;
      reader.readMessage(value,proto.swift.shipment.v1.SplitCostItem.deserializeBinaryFromReader);
      msg.addCostItems(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.SplitCostReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitCostReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCostItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.swift.shipment.v1.SplitCostItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 split_cost_reference_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getSplitCostReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setSplitCostReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 shipment_id = 2;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getShipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated SplitCostItem cost_items = 5;
 * @return {!Array<!proto.swift.shipment.v1.SplitCostItem>}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getCostItemsList = function() {
  return /** @type{!Array<!proto.swift.shipment.v1.SplitCostItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.shipment.v1.SplitCostItem, 5));
};


/**
 * @param {!Array<!proto.swift.shipment.v1.SplitCostItem>} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
*/
proto.swift.shipment.v1.SplitCostReference.prototype.setCostItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.swift.shipment.v1.SplitCostItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.SplitCostItem}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.addCostItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.swift.shipment.v1.SplitCostItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.clearCostItemsList = function() {
  return this.setCostItemsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
*/
proto.swift.shipment.v1.SplitCostReference.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
*/
proto.swift.shipment.v1.SplitCostReference.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 branch_id = 8;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.swift.shipment.v1.SplitCostReference.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.SplitCostReference} returns this
 */
proto.swift.shipment.v1.SplitCostReference.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.SplitCostItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.SplitCostItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.SplitCostItem.toObject = function(includeInstance, msg) {
  var f, obj = {
splitCostItemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
splitCostReferenceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
percentageOfShipment: jspb.Message.getFieldWithDefault(msg, 3, 0),
cost: (f = msg.getCost()) && invoicing_v1_cost_pb.Cost.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.SplitCostItem}
 */
proto.swift.shipment.v1.SplitCostItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.SplitCostItem;
  return proto.swift.shipment.v1.SplitCostItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.SplitCostItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.SplitCostItem}
 */
proto.swift.shipment.v1.SplitCostItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitCostItemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitCostReferenceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPercentageOfShipment(value);
      break;
    case 4:
      var value = new invoicing_v1_cost_pb.Cost;
      reader.readMessage(value,invoicing_v1_cost_pb.Cost.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.SplitCostItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.SplitCostItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.SplitCostItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitCostItemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSplitCostReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPercentageOfShipment();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      invoicing_v1_cost_pb.Cost.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 split_cost_item_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.getSplitCostItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostItem} returns this
 */
proto.swift.shipment.v1.SplitCostItem.prototype.setSplitCostItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 split_cost_reference_id = 2;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.getSplitCostReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostItem} returns this
 */
proto.swift.shipment.v1.SplitCostItem.prototype.setSplitCostReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 percentage_of_shipment = 3;
 * @return {number}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.getPercentageOfShipment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.SplitCostItem} returns this
 */
proto.swift.shipment.v1.SplitCostItem.prototype.setPercentageOfShipment = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional swift.invoicing.v1.Cost cost = 4;
 * @return {?proto.swift.invoicing.v1.Cost}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.getCost = function() {
  return /** @type{?proto.swift.invoicing.v1.Cost} */ (
    jspb.Message.getWrapperField(this, invoicing_v1_cost_pb.Cost, 4));
};


/**
 * @param {?proto.swift.invoicing.v1.Cost|undefined} value
 * @return {!proto.swift.shipment.v1.SplitCostItem} returns this
*/
proto.swift.shipment.v1.SplitCostItem.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.SplitCostItem} returns this
 */
proto.swift.shipment.v1.SplitCostItem.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.SplitCostItem.prototype.hasCost = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ListShipmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ListShipmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListShipmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.shipment.v1.ShipmentFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.shipment.v1.ShipmentSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest}
 */
proto.swift.shipment.v1.ListShipmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ListShipmentsRequest;
  return proto.swift.shipment.v1.ListShipmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ListShipmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest}
 */
proto.swift.shipment.v1.ListShipmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.shipment.v1.ShipmentFilter;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.shipment.v1.ShipmentSorting;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ListShipmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ListShipmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListShipmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.shipment.v1.ShipmentFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.shipment.v1.ShipmentSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
*/
proto.swift.shipment.v1.ListShipmentsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ShipmentFilter filter = 2;
 * @return {?proto.swift.shipment.v1.ShipmentFilter}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.shipment.v1.ShipmentFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.ShipmentFilter, 2));
};


/**
 * @param {?proto.swift.shipment.v1.ShipmentFilter|undefined} value
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
*/
proto.swift.shipment.v1.ListShipmentsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ShipmentSorting sorting = 3;
 * @return {?proto.swift.shipment.v1.ShipmentSorting}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.shipment.v1.ShipmentSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.ShipmentSorting, 3));
};


/**
 * @param {?proto.swift.shipment.v1.ShipmentSorting|undefined} value
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
*/
proto.swift.shipment.v1.ListShipmentsRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.ListShipmentsRequest} returns this
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.ListShipmentsRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.ListShipmentsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ListShipmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ListShipmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListShipmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
shipmentsList: jspb.Message.toObjectList(msg.getShipmentsList(),
    proto.swift.shipment.v1.Shipment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ListShipmentsResponse}
 */
proto.swift.shipment.v1.ListShipmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ListShipmentsResponse;
  return proto.swift.shipment.v1.ListShipmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ListShipmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ListShipmentsResponse}
 */
proto.swift.shipment.v1.ListShipmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.addShipments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ListShipmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ListShipmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListShipmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ListShipmentsResponse} returns this
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Shipment shipments = 2;
 * @return {!Array<!proto.swift.shipment.v1.Shipment>}
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.getShipmentsList = function() {
  return /** @type{!Array<!proto.swift.shipment.v1.Shipment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.shipment.v1.Shipment, 2));
};


/**
 * @param {!Array<!proto.swift.shipment.v1.Shipment>} value
 * @return {!proto.swift.shipment.v1.ListShipmentsResponse} returns this
*/
proto.swift.shipment.v1.ListShipmentsResponse.prototype.setShipmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.shipment.v1.Shipment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.addShipments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.shipment.v1.Shipment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.ListShipmentsResponse} returns this
 */
proto.swift.shipment.v1.ListShipmentsResponse.prototype.clearShipmentsList = function() {
  return this.setShipmentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest;
  return proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string shipment_ref = 1;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} returns this
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string booking_refs = 2;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} returns this
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} returns this
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} returns this
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse;
  return proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse} returns this
*/
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse} returns this
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.AddBookingToShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.AddBookingToShipmentRequest;
  return proto.swift.shipment.v1.AddBookingToShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.AddBookingToShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest} returns this
*/
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest} returns this
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string booking_refs = 2;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest} returns this
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest} returns this
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentRequest} returns this
 */
proto.swift.shipment.v1.AddBookingToShipmentRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.AddBookingToShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentResponse}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.AddBookingToShipmentResponse;
  return proto.swift.shipment.v1.AddBookingToShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentResponse}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.AddBookingToShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentResponse} returns this
*/
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.AddBookingToShipmentResponse} returns this
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.AddBookingToShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.RemoveBookingInShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.RemoveBookingInShipmentRequest;
  return proto.swift.shipment.v1.RemoveBookingInShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.RemoveBookingInShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} returns this
*/
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} returns this
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string booking_refs = 2;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} returns this
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} returns this
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} returns this
 */
proto.swift.shipment.v1.RemoveBookingInShipmentRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.RemoveBookingInShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.RemoveBookingInShipmentResponse;
  return proto.swift.shipment.v1.RemoveBookingInShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.RemoveBookingInShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse} returns this
*/
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.RemoveBookingInShipmentResponse} returns this
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.RemoveBookingInShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.GetShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.GetShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
shipmentRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.GetShipmentRequest}
 */
proto.swift.shipment.v1.GetShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.GetShipmentRequest;
  return proto.swift.shipment.v1.GetShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.GetShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.GetShipmentRequest}
 */
proto.swift.shipment.v1.GetShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.GetShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.GetShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 shipment_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.getShipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.GetShipmentRequest} returns this
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shipment_ref = 2;
 * @return {string}
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.GetShipmentRequest} returns this
 */
proto.swift.shipment.v1.GetShipmentRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.GetShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.GetShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.GetShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.GetShipmentResponse}
 */
proto.swift.shipment.v1.GetShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.GetShipmentResponse;
  return proto.swift.shipment.v1.GetShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.GetShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.GetShipmentResponse}
 */
proto.swift.shipment.v1.GetShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.GetShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.GetShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.GetShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.GetShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.GetShipmentResponse} returns this
*/
proto.swift.shipment.v1.GetShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.GetShipmentResponse} returns this
 */
proto.swift.shipment.v1.GetShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.GetShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.CreateShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.CreateShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.CreateShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.CreateShipmentRequest}
 */
proto.swift.shipment.v1.CreateShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.CreateShipmentRequest;
  return proto.swift.shipment.v1.CreateShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.CreateShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.CreateShipmentRequest}
 */
proto.swift.shipment.v1.CreateShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.CreateShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.CreateShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.CreateShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.CreateShipmentRequest.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.CreateShipmentRequest} returns this
*/
proto.swift.shipment.v1.CreateShipmentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.CreateShipmentRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.CreateShipmentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.CreateShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.CreateShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.CreateShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.CreateShipmentResponse}
 */
proto.swift.shipment.v1.CreateShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.CreateShipmentResponse;
  return proto.swift.shipment.v1.CreateShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.CreateShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.CreateShipmentResponse}
 */
proto.swift.shipment.v1.CreateShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.CreateShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.CreateShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.CreateShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.CreateShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.CreateShipmentResponse} returns this
*/
proto.swift.shipment.v1.CreateShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.CreateShipmentResponse} returns this
 */
proto.swift.shipment.v1.CreateShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.CreateShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentRequest}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentRequest;
  return proto.swift.shipment.v1.UpdateShipmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentRequest}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentRequest} returns this
*/
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentRequest.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentResponse}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentResponse;
  return proto.swift.shipment.v1.UpdateShipmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentResponse}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentResponse} returns this
*/
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentResponse} returns this
 */
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.GetShipmentStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
query: (f = msg.getQuery()) && proto.swift.shipment.v1.ShipmentStatsQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsRequest}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.GetShipmentStatsRequest;
  return proto.swift.shipment.v1.GetShipmentStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsRequest}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.ShipmentStatsQuery;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentStatsQuery.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.GetShipmentStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.ShipmentStatsQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShipmentStatsQuery query = 1;
 * @return {?proto.swift.shipment.v1.ShipmentStatsQuery}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.getQuery = function() {
  return /** @type{?proto.swift.shipment.v1.ShipmentStatsQuery} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.ShipmentStatsQuery, 1));
};


/**
 * @param {?proto.swift.shipment.v1.ShipmentStatsQuery|undefined} value
 * @return {!proto.swift.shipment.v1.GetShipmentStatsRequest} returns this
*/
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsRequest} returns this
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.GetShipmentStatsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.GetShipmentStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.GetShipmentStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
stats: (f = msg.getStats()) && proto.swift.shipment.v1.ShipmentStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsResponse}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.GetShipmentStatsResponse;
  return proto.swift.shipment.v1.GetShipmentStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.GetShipmentStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsResponse}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.ShipmentStats;
      reader.readMessage(value,proto.swift.shipment.v1.ShipmentStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.GetShipmentStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.GetShipmentStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.ShipmentStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional ShipmentStats stats = 1;
 * @return {?proto.swift.shipment.v1.ShipmentStats}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.swift.shipment.v1.ShipmentStats} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.ShipmentStats, 1));
};


/**
 * @param {?proto.swift.shipment.v1.ShipmentStats|undefined} value
 * @return {!proto.swift.shipment.v1.GetShipmentStatsResponse} returns this
*/
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.GetShipmentStatsResponse} returns this
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.GetShipmentStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
shipmentRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
internalNote: jspb.Message.getFieldWithDefault(msg, 3, ""),
internalNoteRemindAt: (f = msg.getInternalNoteRemindAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest;
  return proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInternalNoteRemindAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalNoteRemindAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 shipment_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.getShipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shipment_ref = 2;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_note = 3;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp internal_note_remind_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.getInternalNoteRemindAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} returns this
*/
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.setInternalNoteRemindAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.clearInternalNoteRemindAt = function() {
  return this.setInternalNoteRemindAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest.prototype.hasInternalNoteRemindAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipment: (f = msg.getShipment()) && proto.swift.shipment.v1.Shipment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse;
  return proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.Shipment;
      reader.readMessage(value,proto.swift.shipment.v1.Shipment.deserializeBinaryFromReader);
      msg.setShipment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.Shipment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Shipment shipment = 1;
 * @return {?proto.swift.shipment.v1.Shipment}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.getShipment = function() {
  return /** @type{?proto.swift.shipment.v1.Shipment} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.Shipment, 1));
};


/**
 * @param {?proto.swift.shipment.v1.Shipment|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse} returns this
*/
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.setShipment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse} returns this
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.clearShipment = function() {
  return this.setShipment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.prototype.hasShipment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
splitCostReferenceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
status: jspb.Message.getFieldWithDefault(msg, 2, 0),
comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentCostStatusRequest;
  return proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitCostReferenceId(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitCostReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 split_cost_reference_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.getSplitCostReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.setSplitCostReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.invoicing.v1.Cost.Status status = 2;
 * @return {!proto.swift.invoicing.v1.Cost.Status}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Status} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentCost: (f = msg.getShipmentCost()) && proto.swift.shipment.v1.SplitCostReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentCostStatusResponse;
  return proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.SplitCostReference;
      reader.readMessage(value,proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader);
      msg.setShipmentCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SplitCostReference shipment_cost = 1;
 * @return {?proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.getShipmentCost = function() {
  return /** @type{?proto.swift.shipment.v1.SplitCostReference} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.SplitCostReference, 1));
};


/**
 * @param {?proto.swift.shipment.v1.SplitCostReference|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse} returns this
*/
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.setShipmentCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.clearShipmentCost = function() {
  return this.setShipmentCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.prototype.hasShipmentCost = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.CreateShipmentCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
costType: jspb.Message.getFieldWithDefault(msg, 5, 0),
supplierId: jspb.Message.getFieldWithDefault(msg, 6, 0),
comment: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.CreateShipmentCostRequest;
  return proto.swift.shipment.v1.CreateShipmentCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (reader.readEnum());
      msg.setCostType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.CreateShipmentCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCostType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string shipment_ref = 1;
 * @return {string}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string booking_refs = 2;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};


/**
 * optional int64 amount = 3;
 * @return {number}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional swift.invoicing.v1.Cost.Type cost_type = 5;
 * @return {!proto.swift.invoicing.v1.Cost.Type}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getCostType = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Type} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setCostType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 supplier_id = 6;
 * @return {number}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.CreateShipmentCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.CreateShipmentCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentCost: (f = msg.getShipmentCost()) && proto.swift.shipment.v1.SplitCostReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostResponse}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.CreateShipmentCostResponse;
  return proto.swift.shipment.v1.CreateShipmentCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.CreateShipmentCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostResponse}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.SplitCostReference;
      reader.readMessage(value,proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader);
      msg.setShipmentCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.CreateShipmentCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.CreateShipmentCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SplitCostReference shipment_cost = 1;
 * @return {?proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.getShipmentCost = function() {
  return /** @type{?proto.swift.shipment.v1.SplitCostReference} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.SplitCostReference, 1));
};


/**
 * @param {?proto.swift.shipment.v1.SplitCostReference|undefined} value
 * @return {!proto.swift.shipment.v1.CreateShipmentCostResponse} returns this
*/
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.setShipmentCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.CreateShipmentCostResponse} returns this
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.clearShipmentCost = function() {
  return this.setShipmentCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.CreateShipmentCostResponse.prototype.hasShipmentCost = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentCostRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
splitCostReferenceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
costType: jspb.Message.getFieldWithDefault(msg, 6, 0),
supplierId: jspb.Message.getFieldWithDefault(msg, 7, 0),
comment: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentCostRequest;
  return proto.swift.shipment.v1.UpdateShipmentCostRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSplitCostReferenceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (reader.readEnum());
      msg.setCostType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentCostRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSplitCostReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCostType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string shipment_ref = 1;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 split_cost_reference_id = 2;
 * @return {number}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getSplitCostReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setSplitCostReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string booking_refs = 3;
 * @return {!Array<string>}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional swift.invoicing.v1.Cost.Type cost_type = 6;
 * @return {!proto.swift.invoicing.v1.Cost.Type}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getCostType = function() {
  return /** @type {!proto.swift.invoicing.v1.Cost.Type} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.swift.invoicing.v1.Cost.Type} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setCostType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 supplier_id = 7;
 * @return {number}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string comment = 8;
 * @return {string}
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostRequest} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.UpdateShipmentCostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentCost: (f = msg.getShipmentCost()) && proto.swift.shipment.v1.SplitCostReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostResponse}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.UpdateShipmentCostResponse;
  return proto.swift.shipment.v1.UpdateShipmentCostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostResponse}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.SplitCostReference;
      reader.readMessage(value,proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader);
      msg.setShipmentCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.UpdateShipmentCostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentCost();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional SplitCostReference shipment_cost = 1;
 * @return {?proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.getShipmentCost = function() {
  return /** @type{?proto.swift.shipment.v1.SplitCostReference} */ (
    jspb.Message.getWrapperField(this, proto.swift.shipment.v1.SplitCostReference, 1));
};


/**
 * @param {?proto.swift.shipment.v1.SplitCostReference|undefined} value
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostResponse} returns this
*/
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.setShipmentCost = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.shipment.v1.UpdateShipmentCostResponse} returns this
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.clearShipmentCost = function() {
  return this.setShipmentCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.shipment.v1.UpdateShipmentCostResponse.prototype.hasShipmentCost = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ListSplitCostReferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
shipmentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceRequest}
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ListSplitCostReferenceRequest;
  return proto.swift.shipment.v1.ListSplitCostReferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceRequest}
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShipmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ListSplitCostReferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShipmentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 shipment_id = 1;
 * @return {number}
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.prototype.getShipmentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} returns this
 */
proto.swift.shipment.v1.ListSplitCostReferenceRequest.prototype.setShipmentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.shipment.v1.ListSplitCostReferenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
splitCostReferencesList: jspb.Message.toObjectList(msg.getSplitCostReferencesList(),
    proto.swift.shipment.v1.SplitCostReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceResponse}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.shipment.v1.ListSplitCostReferenceResponse;
  return proto.swift.shipment.v1.ListSplitCostReferenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceResponse}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.shipment.v1.SplitCostReference;
      reader.readMessage(value,proto.swift.shipment.v1.SplitCostReference.deserializeBinaryFromReader);
      msg.addSplitCostReferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.shipment.v1.ListSplitCostReferenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitCostReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.shipment.v1.SplitCostReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SplitCostReference split_cost_references = 1;
 * @return {!Array<!proto.swift.shipment.v1.SplitCostReference>}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.getSplitCostReferencesList = function() {
  return /** @type{!Array<!proto.swift.shipment.v1.SplitCostReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.shipment.v1.SplitCostReference, 1));
};


/**
 * @param {!Array<!proto.swift.shipment.v1.SplitCostReference>} value
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceResponse} returns this
*/
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.setSplitCostReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.shipment.v1.SplitCostReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.shipment.v1.SplitCostReference}
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.addSplitCostReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.shipment.v1.SplitCostReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.shipment.v1.ListSplitCostReferenceResponse} returns this
 */
proto.swift.shipment.v1.ListSplitCostReferenceResponse.prototype.clearSplitCostReferencesList = function() {
  return this.setSplitCostReferencesList([]);
};


goog.object.extend(exports, proto.swift.shipment.v1);
