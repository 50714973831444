import { TransportMode } from 'proto/common/booking_pb'
import { Contract, ContractSorting } from 'proto/contract/v1/contract_pb'

import { Pagination, defaultPageSize } from './query'

export interface ContractFilter {
  contractRef?: Array<string>
  organizationId?: Array<number>
  status?: Array<Contract.Status>
  transportMode?: Array<TransportMode>
  branchId?: Array<number>
  senderCountry?: Array<string>
  receiverCountry?: Array<string>
  benefitCalculation?: boolean
  salesRepresentative?: string[]
}

export interface ContractPage {
  pagination: Pagination
  filter: ContractFilter
  sorting: ContractSorting
}

export const getDefaultContractPage = (): ContractPage => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: new ContractSorting(),
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
export type ParsingContractPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  sorting?: string
}
