// source: order/v1/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
goog.exportSymbol('proto.swift.order.v1.ApplyOrderEventRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.ApplyOrderEventResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.CreateOrderRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.CreateOrderResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderEventsRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderEventsResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderStatsRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.GetOrderStatsResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.ListOrdersRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.ListOrdersResponse', null, global);
goog.exportSymbol('proto.swift.order.v1.Order', null, global);
goog.exportSymbol('proto.swift.order.v1.Order.DataCase', null, global);
goog.exportSymbol('proto.swift.order.v1.Order.Status', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderEvent', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderEvent.EventDataCase', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderEvent.Type', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderFilter', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderItem', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderItem.DataCase', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderProgress', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderProgressPoint', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderSorting', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderSorting.Field', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderStats', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderStats.DataCase', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderStatsQuery', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderStatsQuery.Grouping', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderStatsQuery.Type', null, global);
goog.exportSymbol('proto.swift.order.v1.OrderType', null, global);
goog.exportSymbol('proto.swift.order.v1.PurchaseOrder', null, global);
goog.exportSymbol('proto.swift.order.v1.PurchaseOrderEvent', null, global);
goog.exportSymbol('proto.swift.order.v1.PurchaseOrderItem', null, global);
goog.exportSymbol('proto.swift.order.v1.PurchaseOrderItem.UpdatePriceCase', null, global);
goog.exportSymbol('proto.swift.order.v1.ReturnOrder', null, global);
goog.exportSymbol('proto.swift.order.v1.ReturnOrderItem', null, global);
goog.exportSymbol('proto.swift.order.v1.ReturnOrderItem.Status', null, global);
goog.exportSymbol('proto.swift.order.v1.UpdateOrderRequest', null, global);
goog.exportSymbol('proto.swift.order.v1.UpdateOrderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.order.v1.Order.repeatedFields_, proto.swift.order.v1.Order.oneofGroups_);
};
goog.inherits(proto.swift.order.v1.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.Order.displayName = 'proto.swift.order.v1.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.PurchaseOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.PurchaseOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.PurchaseOrder.displayName = 'proto.swift.order.v1.PurchaseOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ReturnOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.ReturnOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ReturnOrder.displayName = 'proto.swift.order.v1.ReturnOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.order.v1.OrderItem.oneofGroups_);
};
goog.inherits(proto.swift.order.v1.OrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderItem.displayName = 'proto.swift.order.v1.OrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.PurchaseOrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.order.v1.PurchaseOrderItem.oneofGroups_);
};
goog.inherits(proto.swift.order.v1.PurchaseOrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.PurchaseOrderItem.displayName = 'proto.swift.order.v1.PurchaseOrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ReturnOrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.ReturnOrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ReturnOrderItem.displayName = 'proto.swift.order.v1.ReturnOrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.PurchaseOrderEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.PurchaseOrderEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.PurchaseOrderEvent.displayName = 'proto.swift.order.v1.PurchaseOrderEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.order.v1.OrderEvent.oneofGroups_);
};
goog.inherits(proto.swift.order.v1.OrderEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderEvent.displayName = 'proto.swift.order.v1.OrderEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderStatsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.OrderStatsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderStatsQuery.displayName = 'proto.swift.order.v1.OrderStatsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.order.v1.OrderStats.oneofGroups_);
};
goog.inherits(proto.swift.order.v1.OrderStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderStats.displayName = 'proto.swift.order.v1.OrderStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.order.v1.OrderProgress.repeatedFields_, null);
};
goog.inherits(proto.swift.order.v1.OrderProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderProgress.displayName = 'proto.swift.order.v1.OrderProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderProgressPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.OrderProgressPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderProgressPoint.displayName = 'proto.swift.order.v1.OrderProgressPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.order.v1.OrderFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.order.v1.OrderFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderFilter.displayName = 'proto.swift.order.v1.OrderFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.OrderSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.OrderSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.OrderSorting.displayName = 'proto.swift.order.v1.OrderSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ListOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.ListOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ListOrdersRequest.displayName = 'proto.swift.order.v1.ListOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ListOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.order.v1.ListOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.order.v1.ListOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ListOrdersResponse.displayName = 'proto.swift.order.v1.ListOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.GetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderRequest.displayName = 'proto.swift.order.v1.GetOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.GetOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderResponse.displayName = 'proto.swift.order.v1.GetOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.GetOrderEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderEventsRequest.displayName = 'proto.swift.order.v1.GetOrderEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.order.v1.GetOrderEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.order.v1.GetOrderEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderEventsResponse.displayName = 'proto.swift.order.v1.GetOrderEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.GetOrderStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderStatsRequest.displayName = 'proto.swift.order.v1.GetOrderStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.GetOrderStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.GetOrderStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.GetOrderStatsResponse.displayName = 'proto.swift.order.v1.GetOrderStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.CreateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.CreateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.CreateOrderRequest.displayName = 'proto.swift.order.v1.CreateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.CreateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.CreateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.CreateOrderResponse.displayName = 'proto.swift.order.v1.CreateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.UpdateOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.UpdateOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.UpdateOrderRequest.displayName = 'proto.swift.order.v1.UpdateOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.UpdateOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.UpdateOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.UpdateOrderResponse.displayName = 'proto.swift.order.v1.UpdateOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ApplyOrderEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.ApplyOrderEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ApplyOrderEventRequest.displayName = 'proto.swift.order.v1.ApplyOrderEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.order.v1.ApplyOrderEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.order.v1.ApplyOrderEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.order.v1.ApplyOrderEventResponse.displayName = 'proto.swift.order.v1.ApplyOrderEventResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.order.v1.Order.repeatedFields_ = [10];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.order.v1.Order.oneofGroups_ = [[11,12]];

/**
 * @enum {number}
 */
proto.swift.order.v1.Order.DataCase = {
  DATA_NOT_SET: 0,
  PURCHASE: 11,
  RETURN: 12
};

/**
 * @return {proto.swift.order.v1.Order.DataCase}
 */
proto.swift.order.v1.Order.prototype.getDataCase = function() {
  return /** @type {proto.swift.order.v1.Order.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.order.v1.Order.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
orderRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
bookingRef: jspb.Message.getFieldWithDefault(msg, 5, ""),
orderType: jspb.Message.getFieldWithDefault(msg, 6, 0),
status: jspb.Message.getFieldWithDefault(msg, 7, 0),
sender: (f = msg.getSender()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
receiver: (f = msg.getReceiver()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.swift.order.v1.OrderItem.toObject, includeInstance),
purchase: (f = msg.getPurchase()) && proto.swift.order.v1.PurchaseOrder.toObject(includeInstance, f),
pb_return: (f = msg.getReturn()) && proto.swift.order.v1.ReturnOrder.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.Order}
 */
proto.swift.order.v1.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.Order;
  return proto.swift.order.v1.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.Order}
 */
proto.swift.order.v1.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 6:
      var value = /** @type {!proto.swift.order.v1.OrderType} */ (reader.readEnum());
      msg.setOrderType(value);
      break;
    case 7:
      var value = /** @type {!proto.swift.order.v1.Order.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 9:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 10:
      var value = new proto.swift.order.v1.OrderItem;
      reader.readMessage(value,proto.swift.order.v1.OrderItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 11:
      var value = new proto.swift.order.v1.PurchaseOrder;
      reader.readMessage(value,proto.swift.order.v1.PurchaseOrder.deserializeBinaryFromReader);
      msg.setPurchase(value);
      break;
    case 12:
      var value = new proto.swift.order.v1.ReturnOrder;
      reader.readMessage(value,proto.swift.order.v1.ReturnOrder.deserializeBinaryFromReader);
      msg.setReturn(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.swift.order.v1.OrderItem.serializeBinaryToWriter
    );
  }
  f = message.getPurchase();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.order.v1.PurchaseOrder.serializeBinaryToWriter
    );
  }
  f = message.getReturn();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.order.v1.ReturnOrder.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.order.v1.Order.Status = {
  NEW: 0,
  SUBMITTED: 1,
  CHECKED: 2,
  APPROVED: 3,
  COMPLETED: 5
};

/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.swift.order.v1.Order.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.order.v1.Order.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string order_ref = 4;
 * @return {string}
 */
proto.swift.order.v1.Order.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_ref = 5;
 * @return {string}
 */
proto.swift.order.v1.Order.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional OrderType order_type = 6;
 * @return {!proto.swift.order.v1.OrderType}
 */
proto.swift.order.v1.Order.prototype.getOrderType = function() {
  return /** @type {!proto.swift.order.v1.OrderType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.swift.order.v1.OrderType} value
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional Status status = 7;
 * @return {!proto.swift.order.v1.Order.Status}
 */
proto.swift.order.v1.Order.prototype.getStatus = function() {
  return /** @type {!proto.swift.order.v1.Order.Status} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.swift.order.v1.Order.Status} value
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional swift.crm.v1.Address sender = 8;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.order.v1.Order.prototype.getSender = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 8));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasSender = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional swift.crm.v1.Address receiver = 9;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.order.v1.Order.prototype.getReceiver = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 9));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated OrderItem items = 10;
 * @return {!Array<!proto.swift.order.v1.OrderItem>}
 */
proto.swift.order.v1.Order.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.swift.order.v1.OrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.order.v1.OrderItem, 10));
};


/**
 * @param {!Array<!proto.swift.order.v1.OrderItem>} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.swift.order.v1.OrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.OrderItem}
 */
proto.swift.order.v1.Order.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.swift.order.v1.OrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional PurchaseOrder purchase = 11;
 * @return {?proto.swift.order.v1.PurchaseOrder}
 */
proto.swift.order.v1.Order.prototype.getPurchase = function() {
  return /** @type{?proto.swift.order.v1.PurchaseOrder} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.PurchaseOrder, 11));
};


/**
 * @param {?proto.swift.order.v1.PurchaseOrder|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setPurchase = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.order.v1.Order.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearPurchase = function() {
  return this.setPurchase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasPurchase = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ReturnOrder return = 12;
 * @return {?proto.swift.order.v1.ReturnOrder}
 */
proto.swift.order.v1.Order.prototype.getReturn = function() {
  return /** @type{?proto.swift.order.v1.ReturnOrder} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.ReturnOrder, 12));
};


/**
 * @param {?proto.swift.order.v1.ReturnOrder|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setReturn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.swift.order.v1.Order.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearReturn = function() {
  return this.setReturn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasReturn = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.Order.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.Order} returns this
*/
proto.swift.order.v1.Order.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.Order} returns this
 */
proto.swift.order.v1.Order.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.Order.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.PurchaseOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.PurchaseOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.PurchaseOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrder.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.PurchaseOrder}
 */
proto.swift.order.v1.PurchaseOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.PurchaseOrder;
  return proto.swift.order.v1.PurchaseOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.PurchaseOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.PurchaseOrder}
 */
proto.swift.order.v1.PurchaseOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.PurchaseOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.PurchaseOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.PurchaseOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ReturnOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ReturnOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ReturnOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ReturnOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
handlingRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
submittedAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
approvedAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
controllerCreditedAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
customerCreditedAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
controllerInvoiceRef: jspb.Message.getFieldWithDefault(msg, 7, ""),
customerInvoiceRef: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ReturnOrder}
 */
proto.swift.order.v1.ReturnOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ReturnOrder;
  return proto.swift.order.v1.ReturnOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ReturnOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ReturnOrder}
 */
proto.swift.order.v1.ReturnOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHandlingRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubmittedAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApprovedAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setControllerCreditedAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerCreditedAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllerInvoiceRef(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerInvoiceRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ReturnOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ReturnOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ReturnOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ReturnOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandlingRate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubmittedAmount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getApprovedAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getControllerCreditedAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCustomerCreditedAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getControllerInvoiceRef();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomerInvoiceRef();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 handling_rate = 2;
 * @return {number}
 */
proto.swift.order.v1.ReturnOrder.prototype.getHandlingRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setHandlingRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 submitted_amount = 3;
 * @return {number}
 */
proto.swift.order.v1.ReturnOrder.prototype.getSubmittedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setSubmittedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 approved_amount = 4;
 * @return {number}
 */
proto.swift.order.v1.ReturnOrder.prototype.getApprovedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setApprovedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 controller_credited_amount = 5;
 * @return {number}
 */
proto.swift.order.v1.ReturnOrder.prototype.getControllerCreditedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setControllerCreditedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 customer_credited_amount = 6;
 * @return {number}
 */
proto.swift.order.v1.ReturnOrder.prototype.getCustomerCreditedAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setCustomerCreditedAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string controller_invoice_ref = 7;
 * @return {string}
 */
proto.swift.order.v1.ReturnOrder.prototype.getControllerInvoiceRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setControllerInvoiceRef = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string customer_invoice_ref = 8;
 * @return {string}
 */
proto.swift.order.v1.ReturnOrder.prototype.getCustomerInvoiceRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.ReturnOrder} returns this
 */
proto.swift.order.v1.ReturnOrder.prototype.setCustomerInvoiceRef = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.order.v1.OrderItem.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.swift.order.v1.OrderItem.DataCase = {
  DATA_NOT_SET: 0,
  PURCHASE: 5,
  RETURN: 6
};

/**
 * @return {proto.swift.order.v1.OrderItem.DataCase}
 */
proto.swift.order.v1.OrderItem.prototype.getDataCase = function() {
  return /** @type {proto.swift.order.v1.OrderItem.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.order.v1.OrderItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
itemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
sku: jspb.Message.getFieldWithDefault(msg, 2, ""),
quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
price: jspb.Message.getFieldWithDefault(msg, 4, 0),
purchase: (f = msg.getPurchase()) && proto.swift.order.v1.PurchaseOrderItem.toObject(includeInstance, f),
pb_return: (f = msg.getReturn()) && proto.swift.order.v1.ReturnOrderItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderItem}
 */
proto.swift.order.v1.OrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderItem;
  return proto.swift.order.v1.OrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderItem}
 */
proto.swift.order.v1.OrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 5:
      var value = new proto.swift.order.v1.PurchaseOrderItem;
      reader.readMessage(value,proto.swift.order.v1.PurchaseOrderItem.deserializeBinaryFromReader);
      msg.setPurchase(value);
      break;
    case 6:
      var value = new proto.swift.order.v1.ReturnOrderItem;
      reader.readMessage(value,proto.swift.order.v1.ReturnOrderItem.deserializeBinaryFromReader);
      msg.setReturn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPurchase();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.order.v1.PurchaseOrderItem.serializeBinaryToWriter
    );
  }
  f = message.getReturn();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.swift.order.v1.ReturnOrderItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 item_id = 1;
 * @return {number}
 */
proto.swift.order.v1.OrderItem.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string sku = 2;
 * @return {string}
 */
proto.swift.order.v1.OrderItem.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 quantity = 3;
 * @return {number}
 */
proto.swift.order.v1.OrderItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 price = 4;
 * @return {number}
 */
proto.swift.order.v1.OrderItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PurchaseOrderItem purchase = 5;
 * @return {?proto.swift.order.v1.PurchaseOrderItem}
 */
proto.swift.order.v1.OrderItem.prototype.getPurchase = function() {
  return /** @type{?proto.swift.order.v1.PurchaseOrderItem} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.PurchaseOrderItem, 5));
};


/**
 * @param {?proto.swift.order.v1.PurchaseOrderItem|undefined} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
*/
proto.swift.order.v1.OrderItem.prototype.setPurchase = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.swift.order.v1.OrderItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.clearPurchase = function() {
  return this.setPurchase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderItem.prototype.hasPurchase = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ReturnOrderItem return = 6;
 * @return {?proto.swift.order.v1.ReturnOrderItem}
 */
proto.swift.order.v1.OrderItem.prototype.getReturn = function() {
  return /** @type{?proto.swift.order.v1.ReturnOrderItem} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.ReturnOrderItem, 6));
};


/**
 * @param {?proto.swift.order.v1.ReturnOrderItem|undefined} value
 * @return {!proto.swift.order.v1.OrderItem} returns this
*/
proto.swift.order.v1.OrderItem.prototype.setReturn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.swift.order.v1.OrderItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderItem} returns this
 */
proto.swift.order.v1.OrderItem.prototype.clearReturn = function() {
  return this.setReturn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderItem.prototype.hasReturn = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.order.v1.PurchaseOrderItem.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.swift.order.v1.PurchaseOrderItem.UpdatePriceCase = {
  UPDATE_PRICE_NOT_SET: 0,
  ACTUAL_PRICE: 2
};

/**
 * @return {proto.swift.order.v1.PurchaseOrderItem.UpdatePriceCase}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.getUpdatePriceCase = function() {
  return /** @type {proto.swift.order.v1.PurchaseOrderItem.UpdatePriceCase} */(jspb.Message.computeOneofCase(this, proto.swift.order.v1.PurchaseOrderItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.PurchaseOrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.PurchaseOrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
actualQuantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
actualPrice: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.PurchaseOrderItem}
 */
proto.swift.order.v1.PurchaseOrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.PurchaseOrderItem;
  return proto.swift.order.v1.PurchaseOrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.PurchaseOrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.PurchaseOrderItem}
 */
proto.swift.order.v1.PurchaseOrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.PurchaseOrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.PurchaseOrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActualQuantity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 actual_quantity = 1;
 * @return {number}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.getActualQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.PurchaseOrderItem} returns this
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.setActualQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 actual_price = 2;
 * @return {number}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.getActualPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.PurchaseOrderItem} returns this
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.setActualPrice = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.swift.order.v1.PurchaseOrderItem.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.swift.order.v1.PurchaseOrderItem} returns this
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.clearActualPrice = function() {
  return jspb.Message.setOneofField(this, 2, proto.swift.order.v1.PurchaseOrderItem.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.PurchaseOrderItem.prototype.hasActualPrice = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ReturnOrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ReturnOrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ReturnOrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ReturnOrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
status: jspb.Message.getFieldWithDefault(msg, 1, 0),
remarks: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ReturnOrderItem}
 */
proto.swift.order.v1.ReturnOrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ReturnOrderItem;
  return proto.swift.order.v1.ReturnOrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ReturnOrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ReturnOrderItem}
 */
proto.swift.order.v1.ReturnOrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.order.v1.ReturnOrderItem.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ReturnOrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ReturnOrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ReturnOrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ReturnOrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRemarks();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.order.v1.ReturnOrderItem.Status = {
  SUBMITTED: 0,
  ACCEPTED: 1,
  REJECTED: 2
};

/**
 * optional Status status = 1;
 * @return {!proto.swift.order.v1.ReturnOrderItem.Status}
 */
proto.swift.order.v1.ReturnOrderItem.prototype.getStatus = function() {
  return /** @type {!proto.swift.order.v1.ReturnOrderItem.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.order.v1.ReturnOrderItem.Status} value
 * @return {!proto.swift.order.v1.ReturnOrderItem} returns this
 */
proto.swift.order.v1.ReturnOrderItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string remarks = 2;
 * @return {string}
 */
proto.swift.order.v1.ReturnOrderItem.prototype.getRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.ReturnOrderItem} returns this
 */
proto.swift.order.v1.ReturnOrderItem.prototype.setRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.PurchaseOrderEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.PurchaseOrderEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.PurchaseOrderEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrderEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.PurchaseOrderEvent}
 */
proto.swift.order.v1.PurchaseOrderEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.PurchaseOrderEvent;
  return proto.swift.order.v1.PurchaseOrderEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.PurchaseOrderEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.PurchaseOrderEvent}
 */
proto.swift.order.v1.PurchaseOrderEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.order.v1.Order.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.PurchaseOrderEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.PurchaseOrderEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.PurchaseOrderEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.PurchaseOrderEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Order.Status status = 1;
 * @return {!proto.swift.order.v1.Order.Status}
 */
proto.swift.order.v1.PurchaseOrderEvent.prototype.getStatus = function() {
  return /** @type {!proto.swift.order.v1.Order.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.order.v1.Order.Status} value
 * @return {!proto.swift.order.v1.PurchaseOrderEvent} returns this
 */
proto.swift.order.v1.PurchaseOrderEvent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.order.v1.OrderEvent.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.swift.order.v1.OrderEvent.EventDataCase = {
  EVENT_DATA_NOT_SET: 0,
  PURCHASE_ORDER_EVENT: 5
};

/**
 * @return {proto.swift.order.v1.OrderEvent.EventDataCase}
 */
proto.swift.order.v1.OrderEvent.prototype.getEventDataCase = function() {
  return /** @type {proto.swift.order.v1.OrderEvent.EventDataCase} */(jspb.Message.computeOneofCase(this, proto.swift.order.v1.OrderEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
orderRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
orderEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
relatedEventDate: (f = msg.getRelatedEventDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
purchaseOrderEvent: (f = msg.getPurchaseOrderEvent()) && proto.swift.order.v1.PurchaseOrderEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderEvent}
 */
proto.swift.order.v1.OrderEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderEvent;
  return proto.swift.order.v1.OrderEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderEvent}
 */
proto.swift.order.v1.OrderEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderEventId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelatedEventDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new proto.swift.order.v1.PurchaseOrderEvent;
      reader.readMessage(value,proto.swift.order.v1.PurchaseOrderEvent.deserializeBinaryFromReader);
      msg.setPurchaseOrderEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderEventId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRelatedEventDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPurchaseOrderEvent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.order.v1.PurchaseOrderEvent.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.order.v1.OrderEvent.Type = {
  PURCHASEORDEREVENTTYPE: 0
};

/**
 * optional string order_ref = 1;
 * @return {string}
 */
proto.swift.order.v1.OrderEvent.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.OrderEvent} returns this
 */
proto.swift.order.v1.OrderEvent.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 order_event_id = 2;
 * @return {number}
 */
proto.swift.order.v1.OrderEvent.prototype.getOrderEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderEvent} returns this
 */
proto.swift.order.v1.OrderEvent.prototype.setOrderEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp related_event_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.OrderEvent.prototype.getRelatedEventDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.OrderEvent} returns this
*/
proto.swift.order.v1.OrderEvent.prototype.setRelatedEventDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderEvent} returns this
 */
proto.swift.order.v1.OrderEvent.prototype.clearRelatedEventDate = function() {
  return this.setRelatedEventDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderEvent.prototype.hasRelatedEventDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.OrderEvent.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.OrderEvent} returns this
*/
proto.swift.order.v1.OrderEvent.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderEvent} returns this
 */
proto.swift.order.v1.OrderEvent.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderEvent.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PurchaseOrderEvent purchase_order_event = 5;
 * @return {?proto.swift.order.v1.PurchaseOrderEvent}
 */
proto.swift.order.v1.OrderEvent.prototype.getPurchaseOrderEvent = function() {
  return /** @type{?proto.swift.order.v1.PurchaseOrderEvent} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.PurchaseOrderEvent, 5));
};


/**
 * @param {?proto.swift.order.v1.PurchaseOrderEvent|undefined} value
 * @return {!proto.swift.order.v1.OrderEvent} returns this
*/
proto.swift.order.v1.OrderEvent.prototype.setPurchaseOrderEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.swift.order.v1.OrderEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderEvent} returns this
 */
proto.swift.order.v1.OrderEvent.prototype.clearPurchaseOrderEvent = function() {
  return this.setPurchaseOrderEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderEvent.prototype.hasPurchaseOrderEvent = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderStatsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderStatsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderStatsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
grouping: jspb.Message.getFieldWithDefault(msg, 2, 0),
from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderStatsQuery}
 */
proto.swift.order.v1.OrderStatsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderStatsQuery;
  return proto.swift.order.v1.OrderStatsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderStatsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderStatsQuery}
 */
proto.swift.order.v1.OrderStatsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.order.v1.OrderStatsQuery.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.order.v1.OrderStatsQuery.Grouping} */ (reader.readEnum());
      msg.setGrouping(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderStatsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderStatsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderStatsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGrouping();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.order.v1.OrderStatsQuery.Type = {
  ORDER_PROGRESS: 0
};

/**
 * @enum {number}
 */
proto.swift.order.v1.OrderStatsQuery.Grouping = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.order.v1.OrderStatsQuery.Type}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.getType = function() {
  return /** @type {!proto.swift.order.v1.OrderStatsQuery.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.order.v1.OrderStatsQuery.Type} value
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
 */
proto.swift.order.v1.OrderStatsQuery.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Grouping grouping = 2;
 * @return {!proto.swift.order.v1.OrderStatsQuery.Grouping}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.getGrouping = function() {
  return /** @type {!proto.swift.order.v1.OrderStatsQuery.Grouping} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.order.v1.OrderStatsQuery.Grouping} value
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
 */
proto.swift.order.v1.OrderStatsQuery.prototype.setGrouping = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
*/
proto.swift.order.v1.OrderStatsQuery.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
 */
proto.swift.order.v1.OrderStatsQuery.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
*/
proto.swift.order.v1.OrderStatsQuery.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderStatsQuery} returns this
 */
proto.swift.order.v1.OrderStatsQuery.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderStatsQuery.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.order.v1.OrderStats.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.swift.order.v1.OrderStats.DataCase = {
  DATA_NOT_SET: 0,
  ORDER_PROGRESS: 1
};

/**
 * @return {proto.swift.order.v1.OrderStats.DataCase}
 */
proto.swift.order.v1.OrderStats.prototype.getDataCase = function() {
  return /** @type {proto.swift.order.v1.OrderStats.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.order.v1.OrderStats.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderStats.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderStats.toObject = function(includeInstance, msg) {
  var f, obj = {
orderProgress: (f = msg.getOrderProgress()) && proto.swift.order.v1.OrderProgress.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderStats}
 */
proto.swift.order.v1.OrderStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderStats;
  return proto.swift.order.v1.OrderStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderStats}
 */
proto.swift.order.v1.OrderStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderProgress;
      reader.readMessage(value,proto.swift.order.v1.OrderProgress.deserializeBinaryFromReader);
      msg.setOrderProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderProgress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.OrderProgress.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderProgress order_progress = 1;
 * @return {?proto.swift.order.v1.OrderProgress}
 */
proto.swift.order.v1.OrderStats.prototype.getOrderProgress = function() {
  return /** @type{?proto.swift.order.v1.OrderProgress} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderProgress, 1));
};


/**
 * @param {?proto.swift.order.v1.OrderProgress|undefined} value
 * @return {!proto.swift.order.v1.OrderStats} returns this
*/
proto.swift.order.v1.OrderStats.prototype.setOrderProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.swift.order.v1.OrderStats.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderStats} returns this
 */
proto.swift.order.v1.OrderStats.prototype.clearOrderProgress = function() {
  return this.setOrderProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderStats.prototype.hasOrderProgress = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.order.v1.OrderProgress.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.swift.order.v1.OrderProgressPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderProgress}
 */
proto.swift.order.v1.OrderProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderProgress;
  return proto.swift.order.v1.OrderProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderProgress}
 */
proto.swift.order.v1.OrderProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderProgressPoint;
      reader.readMessage(value,proto.swift.order.v1.OrderProgressPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.order.v1.OrderProgressPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderProgressPoint points = 1;
 * @return {!Array<!proto.swift.order.v1.OrderProgressPoint>}
 */
proto.swift.order.v1.OrderProgress.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.swift.order.v1.OrderProgressPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.order.v1.OrderProgressPoint, 1));
};


/**
 * @param {!Array<!proto.swift.order.v1.OrderProgressPoint>} value
 * @return {!proto.swift.order.v1.OrderProgress} returns this
*/
proto.swift.order.v1.OrderProgress.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.order.v1.OrderProgressPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.OrderProgressPoint}
 */
proto.swift.order.v1.OrderProgress.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.order.v1.OrderProgressPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.OrderProgress} returns this
 */
proto.swift.order.v1.OrderProgress.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderProgressPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderProgressPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderProgressPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
submitted: jspb.Message.getFieldWithDefault(msg, 2, 0),
checked: jspb.Message.getFieldWithDefault(msg, 3, 0),
approved: jspb.Message.getFieldWithDefault(msg, 4, 0),
completed: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderProgressPoint}
 */
proto.swift.order.v1.OrderProgressPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderProgressPoint;
  return proto.swift.order.v1.OrderProgressPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderProgressPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderProgressPoint}
 */
proto.swift.order.v1.OrderProgressPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubmitted(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChecked(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApproved(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderProgressPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderProgressPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderProgressPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubmitted();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChecked();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getApproved();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
*/
proto.swift.order.v1.OrderProgressPoint.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
 */
proto.swift.order.v1.OrderProgressPoint.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 submitted = 2;
 * @return {number}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.getSubmitted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
 */
proto.swift.order.v1.OrderProgressPoint.prototype.setSubmitted = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 checked = 3;
 * @return {number}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.getChecked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
 */
proto.swift.order.v1.OrderProgressPoint.prototype.setChecked = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 approved = 4;
 * @return {number}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.getApproved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
 */
proto.swift.order.v1.OrderProgressPoint.prototype.setApproved = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 completed = 5;
 * @return {number}
 */
proto.swift.order.v1.OrderProgressPoint.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.OrderProgressPoint} returns this
 */
proto.swift.order.v1.OrderProgressPoint.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.order.v1.OrderFilter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
typeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
statusList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
search: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderFilter}
 */
proto.swift.order.v1.OrderFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderFilter;
  return proto.swift.order.v1.OrderFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderFilter}
 */
proto.swift.order.v1.OrderFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.swift.order.v1.OrderType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addType(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.swift.order.v1.Order.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated OrderType type = 1;
 * @return {!Array<!proto.swift.order.v1.OrderType>}
 */
proto.swift.order.v1.OrderFilter.prototype.getTypeList = function() {
  return /** @type {!Array<!proto.swift.order.v1.OrderType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.swift.order.v1.OrderType>} value
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.setTypeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.swift.order.v1.OrderType} value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.addType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.clearTypeList = function() {
  return this.setTypeList([]);
};


/**
 * repeated Order.Status status = 2;
 * @return {!Array<!proto.swift.order.v1.Order.Status>}
 */
proto.swift.order.v1.OrderFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.swift.order.v1.Order.Status>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.swift.order.v1.Order.Status>} value
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.swift.order.v1.Order.Status} value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional string search = 3;
 * @return {string}
 */
proto.swift.order.v1.OrderFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.OrderFilter} returns this
 */
proto.swift.order.v1.OrderFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.OrderSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.OrderSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.OrderSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.OrderSorting}
 */
proto.swift.order.v1.OrderSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.OrderSorting;
  return proto.swift.order.v1.OrderSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.OrderSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.OrderSorting}
 */
proto.swift.order.v1.OrderSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.order.v1.OrderSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.order.v1.OrderSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.OrderSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.OrderSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.OrderSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.OrderSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.order.v1.OrderSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.order.v1.OrderSorting.Field = {
  ORDER_ID: 0,
  ORDER_REF: 1,
  TYPE: 2,
  STATUS: 3,
  BOOKING_REF: 4,
  CREATED_AT: 5,
  SENDER_NAME: 6,
  RECEIVER_NAME: 7
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.order.v1.OrderSorting.Field}
 */
proto.swift.order.v1.OrderSorting.prototype.getField = function() {
  return /** @type {!proto.swift.order.v1.OrderSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.order.v1.OrderSorting.Field} value
 * @return {!proto.swift.order.v1.OrderSorting} returns this
 */
proto.swift.order.v1.OrderSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.order.v1.OrderSorting.Ordering}
 */
proto.swift.order.v1.OrderSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.order.v1.OrderSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.order.v1.OrderSorting.Ordering} value
 * @return {!proto.swift.order.v1.OrderSorting} returns this
 */
proto.swift.order.v1.OrderSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ListOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ListOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ListOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.order.v1.OrderFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.order.v1.OrderSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ListOrdersRequest}
 */
proto.swift.order.v1.ListOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ListOrdersRequest;
  return proto.swift.order.v1.ListOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ListOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ListOrdersRequest}
 */
proto.swift.order.v1.ListOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.order.v1.OrderFilter;
      reader.readMessage(value,proto.swift.order.v1.OrderFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.order.v1.OrderSorting;
      reader.readMessage(value,proto.swift.order.v1.OrderSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ListOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ListOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ListOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.order.v1.OrderFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.order.v1.OrderSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
*/
proto.swift.order.v1.ListOrdersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
 */
proto.swift.order.v1.ListOrdersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OrderFilter filter = 2;
 * @return {?proto.swift.order.v1.OrderFilter}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.order.v1.OrderFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderFilter, 2));
};


/**
 * @param {?proto.swift.order.v1.OrderFilter|undefined} value
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
*/
proto.swift.order.v1.ListOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
 */
proto.swift.order.v1.ListOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OrderSorting sorting = 3;
 * @return {?proto.swift.order.v1.OrderSorting}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.order.v1.OrderSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderSorting, 3));
};


/**
 * @param {?proto.swift.order.v1.OrderSorting|undefined} value
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
*/
proto.swift.order.v1.ListOrdersRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.ListOrdersRequest} returns this
 */
proto.swift.order.v1.ListOrdersRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.ListOrdersRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.order.v1.ListOrdersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ListOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ListOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ListOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ListOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.swift.order.v1.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ListOrdersResponse}
 */
proto.swift.order.v1.ListOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ListOrdersResponse;
  return proto.swift.order.v1.ListOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ListOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ListOrdersResponse}
 */
proto.swift.order.v1.ListOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ListOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ListOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ListOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ListOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.order.v1.ListOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.ListOrdersResponse} returns this
 */
proto.swift.order.v1.ListOrdersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Order orders = 2;
 * @return {!Array<!proto.swift.order.v1.Order>}
 */
proto.swift.order.v1.ListOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.swift.order.v1.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.order.v1.Order, 2));
};


/**
 * @param {!Array<!proto.swift.order.v1.Order>} value
 * @return {!proto.swift.order.v1.ListOrdersResponse} returns this
*/
proto.swift.order.v1.ListOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.order.v1.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.Order}
 */
proto.swift.order.v1.ListOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.order.v1.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.ListOrdersResponse} returns this
 */
proto.swift.order.v1.ListOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
orderRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderRequest}
 */
proto.swift.order.v1.GetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderRequest;
  return proto.swift.order.v1.GetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderRequest}
 */
proto.swift.order.v1.GetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.swift.order.v1.GetOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.GetOrderRequest} returns this
 */
proto.swift.order.v1.GetOrderRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string order_ref = 2;
 * @return {string}
 */
proto.swift.order.v1.GetOrderRequest.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.GetOrderRequest} returns this
 */
proto.swift.order.v1.GetOrderRequest.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderResponse}
 */
proto.swift.order.v1.GetOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderResponse;
  return proto.swift.order.v1.GetOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderResponse}
 */
proto.swift.order.v1.GetOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.GetOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.GetOrderResponse} returns this
*/
proto.swift.order.v1.GetOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.GetOrderResponse} returns this
 */
proto.swift.order.v1.GetOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.GetOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
orderRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderEventsRequest}
 */
proto.swift.order.v1.GetOrderEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderEventsRequest;
  return proto.swift.order.v1.GetOrderEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderEventsRequest}
 */
proto.swift.order.v1.GetOrderEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrderRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.order.v1.GetOrderEventsRequest} returns this
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string order_ref = 2;
 * @return {string}
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.getOrderRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.order.v1.GetOrderEventsRequest} returns this
 */
proto.swift.order.v1.GetOrderEventsRequest.prototype.setOrderRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.order.v1.GetOrderEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.swift.order.v1.OrderEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderEventsResponse}
 */
proto.swift.order.v1.GetOrderEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderEventsResponse;
  return proto.swift.order.v1.GetOrderEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderEventsResponse}
 */
proto.swift.order.v1.GetOrderEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderEvent;
      reader.readMessage(value,proto.swift.order.v1.OrderEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.order.v1.OrderEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderEvent events = 1;
 * @return {!Array<!proto.swift.order.v1.OrderEvent>}
 */
proto.swift.order.v1.GetOrderEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.swift.order.v1.OrderEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.order.v1.OrderEvent, 1));
};


/**
 * @param {!Array<!proto.swift.order.v1.OrderEvent>} value
 * @return {!proto.swift.order.v1.GetOrderEventsResponse} returns this
*/
proto.swift.order.v1.GetOrderEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.order.v1.OrderEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.order.v1.OrderEvent}
 */
proto.swift.order.v1.GetOrderEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.order.v1.OrderEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.order.v1.GetOrderEventsResponse} returns this
 */
proto.swift.order.v1.GetOrderEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
query: (f = msg.getQuery()) && proto.swift.order.v1.OrderStatsQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderStatsRequest}
 */
proto.swift.order.v1.GetOrderStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderStatsRequest;
  return proto.swift.order.v1.GetOrderStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderStatsRequest}
 */
proto.swift.order.v1.GetOrderStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderStatsQuery;
      reader.readMessage(value,proto.swift.order.v1.OrderStatsQuery.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.OrderStatsQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderStatsQuery query = 1;
 * @return {?proto.swift.order.v1.OrderStatsQuery}
 */
proto.swift.order.v1.GetOrderStatsRequest.prototype.getQuery = function() {
  return /** @type{?proto.swift.order.v1.OrderStatsQuery} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderStatsQuery, 1));
};


/**
 * @param {?proto.swift.order.v1.OrderStatsQuery|undefined} value
 * @return {!proto.swift.order.v1.GetOrderStatsRequest} returns this
*/
proto.swift.order.v1.GetOrderStatsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.GetOrderStatsRequest} returns this
 */
proto.swift.order.v1.GetOrderStatsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.GetOrderStatsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.GetOrderStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.GetOrderStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.GetOrderStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
stats: (f = msg.getStats()) && proto.swift.order.v1.OrderStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.GetOrderStatsResponse}
 */
proto.swift.order.v1.GetOrderStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.GetOrderStatsResponse;
  return proto.swift.order.v1.GetOrderStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.GetOrderStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.GetOrderStatsResponse}
 */
proto.swift.order.v1.GetOrderStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderStats;
      reader.readMessage(value,proto.swift.order.v1.OrderStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.GetOrderStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.GetOrderStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.GetOrderStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.GetOrderStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.OrderStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderStats stats = 1;
 * @return {?proto.swift.order.v1.OrderStats}
 */
proto.swift.order.v1.GetOrderStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.swift.order.v1.OrderStats} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderStats, 1));
};


/**
 * @param {?proto.swift.order.v1.OrderStats|undefined} value
 * @return {!proto.swift.order.v1.GetOrderStatsResponse} returns this
*/
proto.swift.order.v1.GetOrderStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.GetOrderStatsResponse} returns this
 */
proto.swift.order.v1.GetOrderStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.GetOrderStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.CreateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.CreateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.CreateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.CreateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.CreateOrderRequest}
 */
proto.swift.order.v1.CreateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.CreateOrderRequest;
  return proto.swift.order.v1.CreateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.CreateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.CreateOrderRequest}
 */
proto.swift.order.v1.CreateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.CreateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.CreateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.CreateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.CreateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.CreateOrderRequest.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.CreateOrderRequest} returns this
*/
proto.swift.order.v1.CreateOrderRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.CreateOrderRequest} returns this
 */
proto.swift.order.v1.CreateOrderRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.CreateOrderRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.CreateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.CreateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.CreateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.CreateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.CreateOrderResponse}
 */
proto.swift.order.v1.CreateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.CreateOrderResponse;
  return proto.swift.order.v1.CreateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.CreateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.CreateOrderResponse}
 */
proto.swift.order.v1.CreateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.CreateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.CreateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.CreateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.CreateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.CreateOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.CreateOrderResponse} returns this
*/
proto.swift.order.v1.CreateOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.CreateOrderResponse} returns this
 */
proto.swift.order.v1.CreateOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.CreateOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.UpdateOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.UpdateOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.UpdateOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.UpdateOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.UpdateOrderRequest}
 */
proto.swift.order.v1.UpdateOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.UpdateOrderRequest;
  return proto.swift.order.v1.UpdateOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.UpdateOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.UpdateOrderRequest}
 */
proto.swift.order.v1.UpdateOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.UpdateOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.UpdateOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.UpdateOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.UpdateOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.UpdateOrderRequest.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.UpdateOrderRequest} returns this
*/
proto.swift.order.v1.UpdateOrderRequest.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.UpdateOrderRequest} returns this
 */
proto.swift.order.v1.UpdateOrderRequest.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.UpdateOrderRequest.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.UpdateOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.UpdateOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.UpdateOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.UpdateOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.UpdateOrderResponse}
 */
proto.swift.order.v1.UpdateOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.UpdateOrderResponse;
  return proto.swift.order.v1.UpdateOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.UpdateOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.UpdateOrderResponse}
 */
proto.swift.order.v1.UpdateOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.UpdateOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.UpdateOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.UpdateOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.UpdateOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.UpdateOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.UpdateOrderResponse} returns this
*/
proto.swift.order.v1.UpdateOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.UpdateOrderResponse} returns this
 */
proto.swift.order.v1.UpdateOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.UpdateOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ApplyOrderEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ApplyOrderEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ApplyOrderEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ApplyOrderEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
event: (f = msg.getEvent()) && proto.swift.order.v1.OrderEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ApplyOrderEventRequest}
 */
proto.swift.order.v1.ApplyOrderEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ApplyOrderEventRequest;
  return proto.swift.order.v1.ApplyOrderEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ApplyOrderEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ApplyOrderEventRequest}
 */
proto.swift.order.v1.ApplyOrderEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.OrderEvent;
      reader.readMessage(value,proto.swift.order.v1.OrderEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ApplyOrderEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ApplyOrderEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ApplyOrderEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ApplyOrderEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.OrderEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderEvent event = 1;
 * @return {?proto.swift.order.v1.OrderEvent}
 */
proto.swift.order.v1.ApplyOrderEventRequest.prototype.getEvent = function() {
  return /** @type{?proto.swift.order.v1.OrderEvent} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.OrderEvent, 1));
};


/**
 * @param {?proto.swift.order.v1.OrderEvent|undefined} value
 * @return {!proto.swift.order.v1.ApplyOrderEventRequest} returns this
*/
proto.swift.order.v1.ApplyOrderEventRequest.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.ApplyOrderEventRequest} returns this
 */
proto.swift.order.v1.ApplyOrderEventRequest.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.ApplyOrderEventRequest.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.order.v1.ApplyOrderEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.order.v1.ApplyOrderEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.order.v1.ApplyOrderEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ApplyOrderEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
order: (f = msg.getOrder()) && proto.swift.order.v1.Order.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.order.v1.ApplyOrderEventResponse}
 */
proto.swift.order.v1.ApplyOrderEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.order.v1.ApplyOrderEventResponse;
  return proto.swift.order.v1.ApplyOrderEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.order.v1.ApplyOrderEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.order.v1.ApplyOrderEventResponse}
 */
proto.swift.order.v1.ApplyOrderEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.order.v1.Order;
      reader.readMessage(value,proto.swift.order.v1.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.order.v1.ApplyOrderEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.order.v1.ApplyOrderEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.order.v1.ApplyOrderEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.order.v1.ApplyOrderEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.order.v1.Order.serializeBinaryToWriter
    );
  }
};


/**
 * optional Order order = 1;
 * @return {?proto.swift.order.v1.Order}
 */
proto.swift.order.v1.ApplyOrderEventResponse.prototype.getOrder = function() {
  return /** @type{?proto.swift.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, proto.swift.order.v1.Order, 1));
};


/**
 * @param {?proto.swift.order.v1.Order|undefined} value
 * @return {!proto.swift.order.v1.ApplyOrderEventResponse} returns this
*/
proto.swift.order.v1.ApplyOrderEventResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.order.v1.ApplyOrderEventResponse} returns this
 */
proto.swift.order.v1.ApplyOrderEventResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.order.v1.ApplyOrderEventResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.swift.order.v1.OrderType = {
  PURCHASE: 0,
  RETURN: 1
};

goog.object.extend(exports, proto.swift.order.v1);
