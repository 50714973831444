/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: booking/v1/booking.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var contract_v1_contract_pb = require('../../contract/v1/contract_pb.js')

var common_filter_pb = require('../../common/filter_pb.js')

var common_geolocation_pb = require('../../common/geolocation_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')

var booking_v1_nomination_pb = require('../../booking/v1/nomination_pb.js')

var booking_v1_supplier_pb = require('../../booking/v1/supplier_pb.js')

var distance_v1_distance_pb = require('../../distance/v1/distance_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./booking_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.BookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.BookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListBookingsRequest,
 *   !proto.swift.booking.v1.ListBookingsResponse>}
 */
const methodDescriptor_BookingService_ListBookings = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/ListBookings',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListBookingsRequest,
  proto.swift.booking.v1.ListBookingsResponse,
  /**
   * @param {!proto.swift.booking.v1.ListBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.listBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_BookingService_ListBookings,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.listBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/ListBookings',
      request,
      metadata || {},
      methodDescriptor_BookingService_ListBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetBookingRequest,
 *   !proto.swift.booking.v1.GetBookingResponse>}
 */
const methodDescriptor_BookingService_GetBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/GetBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetBookingRequest,
  proto.swift.booking.v1.GetBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.GetBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.getBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.getBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetBookingEventsRequest,
 *   !proto.swift.booking.v1.GetBookingEventsResponse>}
 */
const methodDescriptor_BookingService_GetBookingEvents = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/GetBookingEvents',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetBookingEventsRequest,
  proto.swift.booking.v1.GetBookingEventsResponse,
  /**
   * @param {!proto.swift.booking.v1.GetBookingEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetBookingEventsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetBookingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetBookingEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetBookingEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.getBookingEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingEvents',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingEvents,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetBookingEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetBookingEventsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.getBookingEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingEvents',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetBookingStatsRequest,
 *   !proto.swift.booking.v1.GetBookingStatsResponse>}
 */
const methodDescriptor_BookingService_GetBookingStats = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/GetBookingStats',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetBookingStatsRequest,
  proto.swift.booking.v1.GetBookingStatsResponse,
  /**
   * @param {!proto.swift.booking.v1.GetBookingStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetBookingStatsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetBookingStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetBookingStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetBookingStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.getBookingStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingStats',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingStats,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetBookingStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetBookingStatsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.getBookingStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingStats',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreateBookingRequest,
 *   !proto.swift.booking.v1.CreateBookingResponse>}
 */
const methodDescriptor_BookingService_CreateBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/CreateBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreateBookingRequest,
  proto.swift.booking.v1.CreateBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.CreateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreateBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreateBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreateBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.createBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/CreateBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CreateBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreateBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.createBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/CreateBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CreateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreatePreAcceptedBookingRequest,
 *   !proto.swift.booking.v1.CreatePreAcceptedBookingResponse>}
 */
const methodDescriptor_BookingService_CreatePreAcceptedBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/CreatePreAcceptedBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreatePreAcceptedBookingRequest,
  proto.swift.booking.v1.CreatePreAcceptedBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreatePreAcceptedBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreatePreAcceptedBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreatePreAcceptedBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.createPreAcceptedBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/CreatePreAcceptedBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CreatePreAcceptedBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreatePreAcceptedBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.createPreAcceptedBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/CreatePreAcceptedBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_CreatePreAcceptedBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditBookingRequest,
 *   !proto.swift.booking.v1.EditBookingResponse>}
 */
const methodDescriptor_BookingService_EditBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/EditBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditBookingRequest,
  proto.swift.booking.v1.EditBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.EditBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.editBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/EditBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_EditBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.editBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/EditBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_EditBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.QuoteBookingRequest,
 *   !proto.swift.booking.v1.QuoteBookingResponse>}
 */
const methodDescriptor_BookingService_QuoteBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/QuoteBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.QuoteBookingRequest,
  proto.swift.booking.v1.QuoteBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.QuoteBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.QuoteBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.QuoteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.QuoteBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.QuoteBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.quoteBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/QuoteBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_QuoteBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.QuoteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.QuoteBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.quoteBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/QuoteBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_QuoteBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetQuotesRequest,
 *   !proto.swift.booking.v1.GetQuotesResponse>}
 */
const methodDescriptor_BookingService_GetQuotes = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/GetQuotes',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetQuotesRequest,
  proto.swift.booking.v1.GetQuotesResponse,
  /**
   * @param {!proto.swift.booking.v1.GetQuotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetQuotesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetQuotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetQuotesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetQuotesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.getQuotes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetQuotes',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetQuotes,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetQuotesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetQuotesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.getQuotes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetQuotes',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetQuotes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.AcceptBookingRequest,
 *   !proto.swift.booking.v1.AcceptBookingResponse>}
 */
const methodDescriptor_BookingService_AcceptBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/AcceptBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.AcceptBookingRequest,
  proto.swift.booking.v1.AcceptBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.AcceptBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.AcceptBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.AcceptBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.AcceptBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.AcceptBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.acceptBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/AcceptBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_AcceptBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.AcceptBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.AcceptBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.acceptBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/AcceptBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_AcceptBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateEstimatesRequest,
 *   !proto.swift.booking.v1.UpdateEstimatesResponse>}
 */
const methodDescriptor_BookingService_UpdateEstimates = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateEstimates',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateEstimatesRequest,
  proto.swift.booking.v1.UpdateEstimatesResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateEstimatesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateEstimatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateEstimatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateEstimates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateEstimates',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateEstimates,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateEstimatesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateEstimates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateEstimates',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateEstimates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateTransportModeRequest,
 *   !proto.swift.booking.v1.UpdateTransportModeResponse>}
 */
const methodDescriptor_BookingService_UpdateTransportMode = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateTransportMode',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateTransportModeRequest,
  proto.swift.booking.v1.UpdateTransportModeResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateTransportModeResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateTransportModeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateTransportModeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateTransportMode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportMode',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportMode,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateTransportModeResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateTransportMode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportMode',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportMode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateStatusRequest,
 *   !proto.swift.booking.v1.UpdateStatusResponse>}
 */
const methodDescriptor_BookingService_UpdateStatus = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateStatus',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateStatusRequest,
  proto.swift.booking.v1.UpdateStatusResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateStatusResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateStatus,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateStatusResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateStatus',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateTransportRefRequest,
 *   !proto.swift.booking.v1.UpdateTransportRefResponse>}
 */
const methodDescriptor_BookingService_UpdateTransportRef = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateTransportRef',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateTransportRefRequest,
  proto.swift.booking.v1.UpdateTransportRefResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateTransportRefResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateTransportRefResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateTransportRefResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateTransportRef =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportRef',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportRef,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateTransportRefResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateTransportRef =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportRef',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportRef);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateRequirementsRequest,
 *   !proto.swift.booking.v1.UpdateRequirementsResponse>}
 */
const methodDescriptor_BookingService_UpdateRequirements = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateRequirements',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateRequirementsRequest,
  proto.swift.booking.v1.UpdateRequirementsResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateRequirementsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateRequirementsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateRequirementsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateRequirements =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateRequirements',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateRequirements,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateRequirementsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateRequirements =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateRequirements',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateRequirements);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateBookingItemsRequest,
 *   !proto.swift.booking.v1.UpdateBookingItemsResponse>}
 */
const methodDescriptor_BookingService_UpdateBookingItems = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateBookingItems',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateBookingItemsRequest,
  proto.swift.booking.v1.UpdateBookingItemsResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateBookingItemsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateBookingItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateBookingItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateBookingItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateBookingItems',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateBookingItems,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateBookingItemsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateBookingItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateBookingItems',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateBookingItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateInternalNoteRequest,
 *   !proto.swift.booking.v1.UpdateInternalNoteResponse>}
 */
const methodDescriptor_BookingService_UpdateInternalNote = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateInternalNote',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateInternalNoteRequest,
  proto.swift.booking.v1.UpdateInternalNoteResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateInternalNoteResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateInternalNoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateInternalNoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateInternalNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateInternalNote',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateInternalNote,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateInternalNoteResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateInternalNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateInternalNote',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateInternalNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteBookingRequest,
 *   !proto.swift.booking.v1.DeleteBookingResponse>}
 */
const methodDescriptor_BookingService_DeleteBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/DeleteBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteBookingRequest,
  proto.swift.booking.v1.DeleteBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.deleteBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/DeleteBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_DeleteBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.deleteBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/DeleteBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_DeleteBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetShipmentRefRequest,
 *   !proto.swift.booking.v1.SetShipmentRefResponse>}
 */
const methodDescriptor_BookingService_SetShipmentRef = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/SetShipmentRef',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetShipmentRefRequest,
  proto.swift.booking.v1.SetShipmentRefResponse,
  /**
   * @param {!proto.swift.booking.v1.SetShipmentRefRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetShipmentRefResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetShipmentRefRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetShipmentRefResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetShipmentRefResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.setShipmentRef =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetShipmentRef',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetShipmentRef,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetShipmentRefRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetShipmentRefResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.setShipmentRef =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetShipmentRef',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetShipmentRef);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetBookingPriceRequest,
 *   !proto.swift.booking.v1.SetBookingPriceResponse>}
 */
const methodDescriptor_BookingService_SetBookingPrice = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/SetBookingPrice',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetBookingPriceRequest,
  proto.swift.booking.v1.SetBookingPriceResponse,
  /**
   * @param {!proto.swift.booking.v1.SetBookingPriceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetBookingPriceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetBookingPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetBookingPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetBookingPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.setBookingPrice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetBookingPrice',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetBookingPrice,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetBookingPriceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetBookingPriceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.setBookingPrice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetBookingPrice',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetBookingPrice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.UpdateTransportOperatorRequest,
 *   !proto.swift.booking.v1.UpdateTransportOperatorResponse>}
 */
const methodDescriptor_BookingService_UpdateTransportOperator = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/UpdateTransportOperator',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.UpdateTransportOperatorRequest,
  proto.swift.booking.v1.UpdateTransportOperatorResponse,
  /**
   * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.UpdateTransportOperatorResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.UpdateTransportOperatorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.UpdateTransportOperatorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.updateTransportOperator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportOperator',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportOperator,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.UpdateTransportOperatorResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.updateTransportOperator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/UpdateTransportOperator',
      request,
      metadata || {},
      methodDescriptor_BookingService_UpdateTransportOperator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetActionRequest,
 *   !proto.swift.booking.v1.SetActionResponse>}
 */
const methodDescriptor_BookingService_SetAction = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/SetAction',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetActionRequest,
  proto.swift.booking.v1.SetActionResponse,
  /**
   * @param {!proto.swift.booking.v1.SetActionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetActionResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetActionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetActionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetActionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.setAction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetAction',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetAction,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetActionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetActionResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.setAction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetAction',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetAction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetBookingChargeableWeightRequest,
 *   !proto.swift.booking.v1.GetBookingChargeableWeightResponse>}
 */
const methodDescriptor_BookingService_GetBookingChargeableWeight = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/GetBookingChargeableWeight',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetBookingChargeableWeightRequest,
  proto.swift.booking.v1.GetBookingChargeableWeightResponse,
  /**
   * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetBookingChargeableWeightResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetBookingChargeableWeightResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetBookingChargeableWeightResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.getBookingChargeableWeight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingChargeableWeight',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingChargeableWeight,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetBookingChargeableWeightResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.getBookingChargeableWeight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/GetBookingChargeableWeight',
      request,
      metadata || {},
      methodDescriptor_BookingService_GetBookingChargeableWeight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.InquireBookingRequest,
 *   !proto.swift.booking.v1.InquireBookingResponse>}
 */
const methodDescriptor_BookingService_InquireBooking = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/InquireBooking',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.InquireBookingRequest,
  proto.swift.booking.v1.InquireBookingResponse,
  /**
   * @param {!proto.swift.booking.v1.InquireBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.InquireBookingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.InquireBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.InquireBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.InquireBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.inquireBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/InquireBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_InquireBooking,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.InquireBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.InquireBookingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.inquireBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/InquireBooking',
      request,
      metadata || {},
      methodDescriptor_BookingService_InquireBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.SetFinancialMonthRequest,
 *   !proto.swift.booking.v1.SetFinancialMonthResponse>}
 */
const methodDescriptor_BookingService_SetFinancialMonth = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingService/SetFinancialMonth',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.SetFinancialMonthRequest,
  proto.swift.booking.v1.SetFinancialMonthResponse,
  /**
   * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.SetFinancialMonthResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.SetFinancialMonthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.SetFinancialMonthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingServiceClient.prototype.setFinancialMonth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetFinancialMonth',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetFinancialMonth,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.SetFinancialMonthResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingServicePromiseClient.prototype.setFinancialMonth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingService/SetFinancialMonth',
      request,
      metadata || {},
      methodDescriptor_BookingService_SetFinancialMonth);
};


module.exports = proto.swift.booking.v1;

