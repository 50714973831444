import React from 'react'

import { Button, Tooltip } from 'antd'

import './button.css'

interface IconButtonProps {
  icon?: JSX.Element
  disabled?: boolean
  onClick?: () => void
  border?: boolean
  size?: 'small' | 'large'
  title?: string | JSX.Element
  titleColor?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

export const IconButton = ({
  icon,
  disabled,
  border = false,
  size,
  title,
  placement,
  titleColor,
  onClick,
}: IconButtonProps) => {
  return (
    <Tooltip title={title} placement={placement} color={titleColor}>
      <Button
        type="default"
        onClick={onClick}
        disabled={disabled}
        className={`icon_button ${border ? '' : 'icon_button--no-text'}`}
        size={size}
        icon={icon}
      />
    </Tooltip>
  )
}
