// source: currency/v1/currency.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
goog.exportSymbol('proto.swift.currency.v1.ConvertCurrency', null, global);
goog.exportSymbol('proto.swift.currency.v1.ConvertCurrency.Type', null, global);
goog.exportSymbol('proto.swift.currency.v1.ConvertCurrencyRequest', null, global);
goog.exportSymbol('proto.swift.currency.v1.ConvertCurrencyResponse', null, global);
goog.exportSymbol('proto.swift.currency.v1.ConvertRate', null, global);
goog.exportSymbol('proto.swift.currency.v1.CreateRateRequest', null, global);
goog.exportSymbol('proto.swift.currency.v1.CreateRateResponse', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencyFilter', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencyRate', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencyRate.SourceType', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencySorting', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencySorting.Field', null, global);
goog.exportSymbol('proto.swift.currency.v1.CurrencySorting.Ordering', null, global);
goog.exportSymbol('proto.swift.currency.v1.GetCurrencyRateRequest', null, global);
goog.exportSymbol('proto.swift.currency.v1.GetCurrencyRateResponse', null, global);
goog.exportSymbol('proto.swift.currency.v1.ListCurrencyRatesRequest', null, global);
goog.exportSymbol('proto.swift.currency.v1.ListCurrencyRatesResponse', null, global);
goog.exportSymbol('proto.swift.currency.v1.UpdateExternalRateRequest', null, global);
goog.exportSymbol('proto.swift.currency.v1.UpdateExternalRateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.CurrencyRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.CurrencyRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.CurrencyRate.displayName = 'proto.swift.currency.v1.CurrencyRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ConvertCurrency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.ConvertCurrency.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.ConvertCurrency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ConvertCurrency.displayName = 'proto.swift.currency.v1.ConvertCurrency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ConvertRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.ConvertRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ConvertRate.displayName = 'proto.swift.currency.v1.ConvertRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.CurrencyFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.CurrencyFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.CurrencyFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.CurrencyFilter.displayName = 'proto.swift.currency.v1.CurrencyFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.CurrencySorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.CurrencySorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.CurrencySorting.displayName = 'proto.swift.currency.v1.CurrencySorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.CreateRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.CreateRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.CreateRateRequest.displayName = 'proto.swift.currency.v1.CreateRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.CreateRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.CreateRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.CreateRateResponse.displayName = 'proto.swift.currency.v1.CreateRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ListCurrencyRatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.ListCurrencyRatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ListCurrencyRatesRequest.displayName = 'proto.swift.currency.v1.ListCurrencyRatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ListCurrencyRatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.ListCurrencyRatesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.ListCurrencyRatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ListCurrencyRatesResponse.displayName = 'proto.swift.currency.v1.ListCurrencyRatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.GetCurrencyRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.GetCurrencyRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.GetCurrencyRateRequest.displayName = 'proto.swift.currency.v1.GetCurrencyRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.GetCurrencyRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.GetCurrencyRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.GetCurrencyRateResponse.displayName = 'proto.swift.currency.v1.GetCurrencyRateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ConvertCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.ConvertCurrencyRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.ConvertCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ConvertCurrencyRequest.displayName = 'proto.swift.currency.v1.ConvertCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.ConvertCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.ConvertCurrencyResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.ConvertCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.ConvertCurrencyResponse.displayName = 'proto.swift.currency.v1.ConvertCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.UpdateExternalRateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.currency.v1.UpdateExternalRateRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.currency.v1.UpdateExternalRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.UpdateExternalRateRequest.displayName = 'proto.swift.currency.v1.UpdateExternalRateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.currency.v1.UpdateExternalRateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.currency.v1.UpdateExternalRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.currency.v1.UpdateExternalRateResponse.displayName = 'proto.swift.currency.v1.UpdateExternalRateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.CurrencyRate.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.CurrencyRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.CurrencyRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencyRate.toObject = function(includeInstance, msg) {
  var f, obj = {
currencyRateId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
sourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
source: jspb.Message.getFieldWithDefault(msg, 4, ""),
fromCurrency: jspb.Message.getFieldWithDefault(msg, 5, ""),
rate: jspb.Message.getFieldWithDefault(msg, 6, 0),
toCurrency: jspb.Message.getFieldWithDefault(msg, 7, ""),
lookupDate: (f = msg.getLookupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.CurrencyRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.CurrencyRate;
  return proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.CurrencyRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrencyRateId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.currency.v1.CurrencyRate.SourceType} */ (reader.readEnum());
      msg.setSourceType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setToCurrency(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLookupDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.CurrencyRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.CurrencyRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyRateId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getToCurrency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLookupDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.currency.v1.CurrencyRate.SourceType = {
  UNKNOWN: 0,
  INTERNAL: 1,
  EXTERNAL: 2
};

/**
 * optional int64 currency_rate_id = 1;
 * @return {number}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getCurrencyRateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setCurrencyRateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
*/
proto.swift.currency.v1.CurrencyRate.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.CurrencyRate.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SourceType source_type = 3;
 * @return {!proto.swift.currency.v1.CurrencyRate.SourceType}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getSourceType = function() {
  return /** @type {!proto.swift.currency.v1.CurrencyRate.SourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.currency.v1.CurrencyRate.SourceType} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string source = 4;
 * @return {string}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string from_currency = 5;
 * @return {string}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getFromCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setFromCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 rate = 6;
 * @return {number}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string to_currency = 7;
 * @return {string}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getToCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.setToCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp lookup_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.currency.v1.CurrencyRate.prototype.getLookupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
*/
proto.swift.currency.v1.CurrencyRate.prototype.setLookupDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.CurrencyRate} returns this
 */
proto.swift.currency.v1.CurrencyRate.prototype.clearLookupDate = function() {
  return this.setLookupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.CurrencyRate.prototype.hasLookupDate = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.ConvertCurrency.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ConvertCurrency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ConvertCurrency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrency.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
contextId: jspb.Message.getFieldWithDefault(msg, 2, 0),
contextAlias: jspb.Message.getFieldWithDefault(msg, 3, ""),
bookingRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
currencyFrom: jspb.Message.getFieldWithDefault(msg, 5, ""),
keyCurrency: jspb.Message.getFieldWithDefault(msg, 6, ""),
currencyTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
amount: jspb.Message.getFieldWithDefault(msg, 8, 0),
addonConversionPercentage: jspb.Message.getFieldWithDefault(msg, 9, 0),
convertedValue: jspb.Message.getFieldWithDefault(msg, 10, 0),
rate: jspb.Message.getFieldWithDefault(msg, 11, 0),
lookupDate: (f = msg.getLookupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
validationErrorsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ConvertCurrency}
 */
proto.swift.currency.v1.ConvertCurrency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ConvertCurrency;
  return proto.swift.currency.v1.ConvertCurrency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ConvertCurrency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ConvertCurrency}
 */
proto.swift.currency.v1.ConvertCurrency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.currency.v1.ConvertCurrency.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContextId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContextAlias(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyFrom(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyCurrency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyTo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAddonConversionPercentage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConvertedValue(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRate(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLookupDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidationErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ConvertCurrency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ConvertCurrency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContextId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getContextAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrencyFrom();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKeyCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrencyTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAddonConversionPercentage();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getConvertedValue();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getRate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getLookupDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidationErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.currency.v1.ConvertCurrency.Type = {
  UNKNOWN: 0,
  ARTICLE: 1,
  COST: 2,
  PRICE: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.currency.v1.ConvertCurrency.Type}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getType = function() {
  return /** @type {!proto.swift.currency.v1.ConvertCurrency.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.currency.v1.ConvertCurrency.Type} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 context_id = 2;
 * @return {number}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getContextId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setContextId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string context_alias = 3;
 * @return {string}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getContextAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setContextAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_ref = 4;
 * @return {string}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency_from = 5;
 * @return {string}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getCurrencyFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setCurrencyFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string key_currency = 6;
 * @return {string}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getKeyCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setKeyCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string currency_to = 7;
 * @return {string}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getCurrencyTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setCurrencyTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 amount = 8;
 * @return {number}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 addon_conversion_percentage = 9;
 * @return {number}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getAddonConversionPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setAddonConversionPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 converted_value = 10;
 * @return {number}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getConvertedValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setConvertedValue = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 rate = 11;
 * @return {number}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setRate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp lookup_date = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getLookupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
*/
proto.swift.currency.v1.ConvertCurrency.prototype.setLookupDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.clearLookupDate = function() {
  return this.setLookupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.hasLookupDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated string validation_errors = 13;
 * @return {!Array<string>}
 */
proto.swift.currency.v1.ConvertCurrency.prototype.getValidationErrorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.setValidationErrorsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.addValidationErrors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.ConvertCurrency} returns this
 */
proto.swift.currency.v1.ConvertCurrency.prototype.clearValidationErrorsList = function() {
  return this.setValidationErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ConvertRate.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ConvertRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ConvertRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertRate.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0),
date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ConvertRate}
 */
proto.swift.currency.v1.ConvertRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ConvertRate;
  return proto.swift.currency.v1.ConvertRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ConvertRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ConvertRate}
 */
proto.swift.currency.v1.ConvertRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ConvertRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ConvertRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ConvertRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.swift.currency.v1.ConvertRate.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ConvertRate} returns this
 */
proto.swift.currency.v1.ConvertRate.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.currency.v1.ConvertRate.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.currency.v1.ConvertRate} returns this
*/
proto.swift.currency.v1.ConvertRate.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.ConvertRate} returns this
 */
proto.swift.currency.v1.ConvertRate.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.ConvertRate.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.CurrencyFilter.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.CurrencyFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.CurrencyFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencyFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
fromCurrencyList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
toCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
sourceTypeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
lookupDate: (f = msg.getLookupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.CurrencyFilter}
 */
proto.swift.currency.v1.CurrencyFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.CurrencyFilter;
  return proto.swift.currency.v1.CurrencyFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.CurrencyFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.CurrencyFilter}
 */
proto.swift.currency.v1.CurrencyFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFromCurrency(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLookupDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.CurrencyFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.CurrencyFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencyFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getToCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getLookupDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string from_currency = 2;
 * @return {!Array<string>}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.getFromCurrencyList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.setFromCurrencyList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.addFromCurrency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.clearFromCurrencyList = function() {
  return this.setFromCurrencyList([]);
};


/**
 * optional string to_currency = 1;
 * @return {string}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.getToCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.setToCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string source_type = 3;
 * @return {!Array<string>}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.getSourceTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.setSourceTypeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.addSourceType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.clearSourceTypeList = function() {
  return this.setSourceTypeList([]);
};


/**
 * optional google.protobuf.Timestamp lookup_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.getLookupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
*/
proto.swift.currency.v1.CurrencyFilter.prototype.setLookupDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.CurrencyFilter} returns this
 */
proto.swift.currency.v1.CurrencyFilter.prototype.clearLookupDate = function() {
  return this.setLookupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.CurrencyFilter.prototype.hasLookupDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.CurrencySorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.CurrencySorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.CurrencySorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencySorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.CurrencySorting}
 */
proto.swift.currency.v1.CurrencySorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.CurrencySorting;
  return proto.swift.currency.v1.CurrencySorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.CurrencySorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.CurrencySorting}
 */
proto.swift.currency.v1.CurrencySorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.currency.v1.CurrencySorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.currency.v1.CurrencySorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.CurrencySorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.CurrencySorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.CurrencySorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CurrencySorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.currency.v1.CurrencySorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.currency.v1.CurrencySorting.Field = {
  LOOKUP_DATE: 0,
  FROM_CURRENCY: 1,
  TO_CURRENCY: 2,
  SOURCE: 3
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.currency.v1.CurrencySorting.Field}
 */
proto.swift.currency.v1.CurrencySorting.prototype.getField = function() {
  return /** @type {!proto.swift.currency.v1.CurrencySorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.currency.v1.CurrencySorting.Field} value
 * @return {!proto.swift.currency.v1.CurrencySorting} returns this
 */
proto.swift.currency.v1.CurrencySorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.currency.v1.CurrencySorting.Ordering}
 */
proto.swift.currency.v1.CurrencySorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.currency.v1.CurrencySorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.currency.v1.CurrencySorting.Ordering} value
 * @return {!proto.swift.currency.v1.CurrencySorting} returns this
 */
proto.swift.currency.v1.CurrencySorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.CreateRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.CreateRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.CreateRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CreateRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
currencyRate: (f = msg.getCurrencyRate()) && proto.swift.currency.v1.CurrencyRate.toObject(includeInstance, f),
dailyInternalRate: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.CreateRateRequest}
 */
proto.swift.currency.v1.CreateRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.CreateRateRequest;
  return proto.swift.currency.v1.CreateRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.CreateRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.CreateRateRequest}
 */
proto.swift.currency.v1.CreateRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.CurrencyRate;
      reader.readMessage(value,proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader);
      msg.setCurrencyRate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDailyInternalRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.CreateRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.CreateRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.CreateRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CreateRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter
    );
  }
  f = message.getDailyInternalRate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional CurrencyRate currency_rate = 1;
 * @return {?proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.CreateRateRequest.prototype.getCurrencyRate = function() {
  return /** @type{?proto.swift.currency.v1.CurrencyRate} */ (
    jspb.Message.getWrapperField(this, proto.swift.currency.v1.CurrencyRate, 1));
};


/**
 * @param {?proto.swift.currency.v1.CurrencyRate|undefined} value
 * @return {!proto.swift.currency.v1.CreateRateRequest} returns this
*/
proto.swift.currency.v1.CreateRateRequest.prototype.setCurrencyRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.CreateRateRequest} returns this
 */
proto.swift.currency.v1.CreateRateRequest.prototype.clearCurrencyRate = function() {
  return this.setCurrencyRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.CreateRateRequest.prototype.hasCurrencyRate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool daily_internal_rate = 2;
 * @return {boolean}
 */
proto.swift.currency.v1.CreateRateRequest.prototype.getDailyInternalRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.currency.v1.CreateRateRequest} returns this
 */
proto.swift.currency.v1.CreateRateRequest.prototype.setDailyInternalRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.CreateRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.CreateRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.CreateRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CreateRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
currencyRate: (f = msg.getCurrencyRate()) && proto.swift.currency.v1.CurrencyRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.CreateRateResponse}
 */
proto.swift.currency.v1.CreateRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.CreateRateResponse;
  return proto.swift.currency.v1.CreateRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.CreateRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.CreateRateResponse}
 */
proto.swift.currency.v1.CreateRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.CurrencyRate;
      reader.readMessage(value,proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader);
      msg.setCurrencyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.CreateRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.CreateRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.CreateRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.CreateRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional CurrencyRate currency_rate = 1;
 * @return {?proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.CreateRateResponse.prototype.getCurrencyRate = function() {
  return /** @type{?proto.swift.currency.v1.CurrencyRate} */ (
    jspb.Message.getWrapperField(this, proto.swift.currency.v1.CurrencyRate, 1));
};


/**
 * @param {?proto.swift.currency.v1.CurrencyRate|undefined} value
 * @return {!proto.swift.currency.v1.CreateRateResponse} returns this
*/
proto.swift.currency.v1.CreateRateResponse.prototype.setCurrencyRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.CreateRateResponse} returns this
 */
proto.swift.currency.v1.CreateRateResponse.prototype.clearCurrencyRate = function() {
  return this.setCurrencyRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.CreateRateResponse.prototype.hasCurrencyRate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ListCurrencyRatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.currency.v1.CurrencyFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.currency.v1.CurrencySorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ListCurrencyRatesRequest;
  return proto.swift.currency.v1.ListCurrencyRatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.currency.v1.CurrencyFilter;
      reader.readMessage(value,proto.swift.currency.v1.CurrencyFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.currency.v1.CurrencySorting;
      reader.readMessage(value,proto.swift.currency.v1.CurrencySorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ListCurrencyRatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.currency.v1.CurrencyFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.currency.v1.CurrencySorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
*/
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CurrencyFilter filter = 2;
 * @return {?proto.swift.currency.v1.CurrencyFilter}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.currency.v1.CurrencyFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.currency.v1.CurrencyFilter, 2));
};


/**
 * @param {?proto.swift.currency.v1.CurrencyFilter|undefined} value
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
*/
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CurrencySorting sorting = 3;
 * @return {?proto.swift.currency.v1.CurrencySorting}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.currency.v1.CurrencySorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.currency.v1.CurrencySorting, 3));
};


/**
 * @param {?proto.swift.currency.v1.CurrencySorting|undefined} value
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
*/
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesRequest} returns this
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.ListCurrencyRatesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ListCurrencyRatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ListCurrencyRatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
currencyRateList: jspb.Message.toObjectList(msg.getCurrencyRateList(),
    proto.swift.currency.v1.CurrencyRate.toObject, includeInstance),
totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesResponse}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ListCurrencyRatesResponse;
  return proto.swift.currency.v1.ListCurrencyRatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ListCurrencyRatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesResponse}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.CurrencyRate;
      reader.readMessage(value,proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader);
      msg.addCurrencyRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ListCurrencyRatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ListCurrencyRatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyRateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated CurrencyRate currency_rate = 1;
 * @return {!Array<!proto.swift.currency.v1.CurrencyRate>}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.getCurrencyRateList = function() {
  return /** @type{!Array<!proto.swift.currency.v1.CurrencyRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.currency.v1.CurrencyRate, 1));
};


/**
 * @param {!Array<!proto.swift.currency.v1.CurrencyRate>} value
 * @return {!proto.swift.currency.v1.ListCurrencyRatesResponse} returns this
*/
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.setCurrencyRateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.currency.v1.CurrencyRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.addCurrencyRate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.currency.v1.CurrencyRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.ListCurrencyRatesResponse} returns this
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.clearCurrencyRateList = function() {
  return this.setCurrencyRateList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.currency.v1.ListCurrencyRatesResponse} returns this
 */
proto.swift.currency.v1.ListCurrencyRatesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.GetCurrencyRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.GetCurrencyRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
fromCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
toCurrency: jspb.Message.getFieldWithDefault(msg, 2, ""),
sourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
lookupDate: (f = msg.getLookupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
isBranchRate: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.GetCurrencyRateRequest;
  return proto.swift.currency.v1.GetCurrencyRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromCurrency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToCurrency(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.currency.v1.CurrencyRate.SourceType} */ (reader.readEnum());
      msg.setSourceType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLookupDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBranchRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.GetCurrencyRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.GetCurrencyRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLookupDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsBranchRate();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string from_currency = 1;
 * @return {string}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.getFromCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.setFromCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_currency = 2;
 * @return {string}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.getToCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.setToCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CurrencyRate.SourceType source_type = 3;
 * @return {!proto.swift.currency.v1.CurrencyRate.SourceType}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.getSourceType = function() {
  return /** @type {!proto.swift.currency.v1.CurrencyRate.SourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.currency.v1.CurrencyRate.SourceType} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.setSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp lookup_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.getLookupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
*/
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.setLookupDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.clearLookupDate = function() {
  return this.setLookupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.hasLookupDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_branch_rate = 5;
 * @return {boolean}
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.getIsBranchRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateRequest} returns this
 */
proto.swift.currency.v1.GetCurrencyRateRequest.prototype.setIsBranchRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.GetCurrencyRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.GetCurrencyRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.GetCurrencyRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
currencyRate: (f = msg.getCurrencyRate()) && proto.swift.currency.v1.CurrencyRate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.GetCurrencyRateResponse}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.GetCurrencyRateResponse;
  return proto.swift.currency.v1.GetCurrencyRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.GetCurrencyRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.GetCurrencyRateResponse}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.CurrencyRate;
      reader.readMessage(value,proto.swift.currency.v1.CurrencyRate.deserializeBinaryFromReader);
      msg.setCurrencyRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.GetCurrencyRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.GetCurrencyRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.GetCurrencyRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencyRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.currency.v1.CurrencyRate.serializeBinaryToWriter
    );
  }
};


/**
 * optional CurrencyRate currency_rate = 1;
 * @return {?proto.swift.currency.v1.CurrencyRate}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.getCurrencyRate = function() {
  return /** @type{?proto.swift.currency.v1.CurrencyRate} */ (
    jspb.Message.getWrapperField(this, proto.swift.currency.v1.CurrencyRate, 1));
};


/**
 * @param {?proto.swift.currency.v1.CurrencyRate|undefined} value
 * @return {!proto.swift.currency.v1.GetCurrencyRateResponse} returns this
*/
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.setCurrencyRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.currency.v1.GetCurrencyRateResponse} returns this
 */
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.clearCurrencyRate = function() {
  return this.setCurrencyRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.currency.v1.GetCurrencyRateResponse.prototype.hasCurrencyRate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.ConvertCurrencyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ConvertCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
convertCurrencyList: jspb.Message.toObjectList(msg.getConvertCurrencyList(),
    proto.swift.currency.v1.ConvertCurrency.toObject, includeInstance),
dailyInternalRate: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ConvertCurrencyRequest}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ConvertCurrencyRequest;
  return proto.swift.currency.v1.ConvertCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ConvertCurrencyRequest}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.ConvertCurrency;
      reader.readMessage(value,proto.swift.currency.v1.ConvertCurrency.deserializeBinaryFromReader);
      msg.addConvertCurrency(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDailyInternalRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ConvertCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConvertCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.currency.v1.ConvertCurrency.serializeBinaryToWriter
    );
  }
  f = message.getDailyInternalRate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated ConvertCurrency convert_currency = 1;
 * @return {!Array<!proto.swift.currency.v1.ConvertCurrency>}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.getConvertCurrencyList = function() {
  return /** @type{!Array<!proto.swift.currency.v1.ConvertCurrency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.currency.v1.ConvertCurrency, 1));
};


/**
 * @param {!Array<!proto.swift.currency.v1.ConvertCurrency>} value
 * @return {!proto.swift.currency.v1.ConvertCurrencyRequest} returns this
*/
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.setConvertCurrencyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.currency.v1.ConvertCurrency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.ConvertCurrency}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.addConvertCurrency = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.currency.v1.ConvertCurrency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.ConvertCurrencyRequest} returns this
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.clearConvertCurrencyList = function() {
  return this.setConvertCurrencyList([]);
};


/**
 * optional bool daily_internal_rate = 2;
 * @return {boolean}
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.getDailyInternalRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.currency.v1.ConvertCurrencyRequest} returns this
 */
proto.swift.currency.v1.ConvertCurrencyRequest.prototype.setDailyInternalRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.ConvertCurrencyResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.ConvertCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.ConvertCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
convertCurrencyList: jspb.Message.toObjectList(msg.getConvertCurrencyList(),
    proto.swift.currency.v1.ConvertCurrency.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.ConvertCurrencyResponse}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.ConvertCurrencyResponse;
  return proto.swift.currency.v1.ConvertCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.ConvertCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.ConvertCurrencyResponse}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.currency.v1.ConvertCurrency;
      reader.readMessage(value,proto.swift.currency.v1.ConvertCurrency.deserializeBinaryFromReader);
      msg.addConvertCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.ConvertCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.ConvertCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.ConvertCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConvertCurrencyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.currency.v1.ConvertCurrency.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConvertCurrency convert_currency = 1;
 * @return {!Array<!proto.swift.currency.v1.ConvertCurrency>}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.getConvertCurrencyList = function() {
  return /** @type{!Array<!proto.swift.currency.v1.ConvertCurrency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.currency.v1.ConvertCurrency, 1));
};


/**
 * @param {!Array<!proto.swift.currency.v1.ConvertCurrency>} value
 * @return {!proto.swift.currency.v1.ConvertCurrencyResponse} returns this
*/
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.setConvertCurrencyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.currency.v1.ConvertCurrency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.ConvertCurrency}
 */
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.addConvertCurrency = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.currency.v1.ConvertCurrency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.ConvertCurrencyResponse} returns this
 */
proto.swift.currency.v1.ConvertCurrencyResponse.prototype.clearConvertCurrencyList = function() {
  return this.setConvertCurrencyList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.currency.v1.UpdateExternalRateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.UpdateExternalRateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.UpdateExternalRateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
keyCurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
toCurrenciesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.UpdateExternalRateRequest;
  return proto.swift.currency.v1.UpdateExternalRateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyCurrency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addToCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.UpdateExternalRateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.UpdateExternalRateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string key_currency = 1;
 * @return {string}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.getKeyCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest} returns this
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.setKeyCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string to_currencies = 2;
 * @return {!Array<string>}
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.getToCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest} returns this
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.setToCurrenciesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest} returns this
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.addToCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.currency.v1.UpdateExternalRateRequest} returns this
 */
proto.swift.currency.v1.UpdateExternalRateRequest.prototype.clearToCurrenciesList = function() {
  return this.setToCurrenciesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.currency.v1.UpdateExternalRateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.currency.v1.UpdateExternalRateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.currency.v1.UpdateExternalRateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.UpdateExternalRateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.currency.v1.UpdateExternalRateResponse}
 */
proto.swift.currency.v1.UpdateExternalRateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.currency.v1.UpdateExternalRateResponse;
  return proto.swift.currency.v1.UpdateExternalRateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.currency.v1.UpdateExternalRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.currency.v1.UpdateExternalRateResponse}
 */
proto.swift.currency.v1.UpdateExternalRateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.currency.v1.UpdateExternalRateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.currency.v1.UpdateExternalRateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.currency.v1.UpdateExternalRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.currency.v1.UpdateExternalRateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.swift.currency.v1);
