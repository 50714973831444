// source: iam/v1/branch.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
var common_provider_pb = require('../../common/provider_pb.js');
goog.object.extend(proto, common_provider_pb);
goog.exportSymbol('proto.swift.iam.v1.Branch', null, global);
goog.exportSymbol('proto.swift.iam.v1.BranchConfig', null, global);
goog.exportSymbol('proto.swift.iam.v1.BranchFilter', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateBranchRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.CreateBranchResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.DeleteBranchRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.DeleteBranchResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditBranchRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.EditBranchResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.FinanceInfo', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetBranchRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.GetBranchResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.IbanType', null, global);
goog.exportSymbol('proto.swift.iam.v1.InvoiceIntegration', null, global);
goog.exportSymbol('proto.swift.iam.v1.InvoiceIntegration.InvoiceReferenceCase', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListBranchesRequest', null, global);
goog.exportSymbol('proto.swift.iam.v1.ListBranchesResponse', null, global);
goog.exportSymbol('proto.swift.iam.v1.VismaReference', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.Branch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.Branch.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.Branch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.Branch.displayName = 'proto.swift.iam.v1.Branch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.InvoiceIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.iam.v1.InvoiceIntegration.oneofGroups_);
};
goog.inherits(proto.swift.iam.v1.InvoiceIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.InvoiceIntegration.displayName = 'proto.swift.iam.v1.InvoiceIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.VismaReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.VismaReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.VismaReference.displayName = 'proto.swift.iam.v1.VismaReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.BranchConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.BranchConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.BranchConfig.displayName = 'proto.swift.iam.v1.BranchConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.FinanceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.FinanceInfo.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.FinanceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.FinanceInfo.displayName = 'proto.swift.iam.v1.FinanceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.IbanType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.IbanType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.IbanType.displayName = 'proto.swift.iam.v1.IbanType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.BranchFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.BranchFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.BranchFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.BranchFilter.displayName = 'proto.swift.iam.v1.BranchFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListBranchesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.ListBranchesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListBranchesRequest.displayName = 'proto.swift.iam.v1.ListBranchesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.ListBranchesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.iam.v1.ListBranchesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.iam.v1.ListBranchesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.ListBranchesResponse.displayName = 'proto.swift.iam.v1.ListBranchesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetBranchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetBranchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetBranchRequest.displayName = 'proto.swift.iam.v1.GetBranchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.GetBranchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.GetBranchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.GetBranchResponse.displayName = 'proto.swift.iam.v1.GetBranchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateBranchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateBranchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateBranchRequest.displayName = 'proto.swift.iam.v1.CreateBranchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.CreateBranchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.CreateBranchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.CreateBranchResponse.displayName = 'proto.swift.iam.v1.CreateBranchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditBranchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditBranchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditBranchRequest.displayName = 'proto.swift.iam.v1.EditBranchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.EditBranchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.EditBranchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.EditBranchResponse.displayName = 'proto.swift.iam.v1.EditBranchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.DeleteBranchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.DeleteBranchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.DeleteBranchRequest.displayName = 'proto.swift.iam.v1.DeleteBranchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.iam.v1.DeleteBranchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.iam.v1.DeleteBranchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.iam.v1.DeleteBranchResponse.displayName = 'proto.swift.iam.v1.DeleteBranchResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.Branch.repeatedFields_ = [5,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.Branch.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.Branch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.Branch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Branch.toObject = function(includeInstance, msg) {
  var f, obj = {
branchId: jspb.Message.getFieldWithDefault(msg, 1, 0),
providerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
defaultCurrency: jspb.Message.getFieldWithDefault(msg, 4, ""),
acceptedCurrenciesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
defaultVat: jspb.Message.getFieldWithDefault(msg, 6, 0),
conversionAddon: jspb.Message.getFieldWithDefault(msg, 7, 0),
rounding: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
branchConfig: (f = msg.getBranchConfig()) && proto.swift.iam.v1.BranchConfig.toObject(includeInstance, f),
financeInfo: (f = msg.getFinanceInfo()) && proto.swift.iam.v1.FinanceInfo.toObject(includeInstance, f),
enableCreditSeries: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
euVat: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
autoInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
reminderOverdueDays: jspb.Message.getFieldWithDefault(msg, 14, 0),
dailyInternalRate: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
invoiceIntegrationsList: jspb.Message.toObjectList(msg.getInvoiceIntegrationsList(),
    proto.swift.iam.v1.InvoiceIntegration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.Branch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.Branch;
  return proto.swift.iam.v1.Branch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.Branch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.Branch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProviderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultCurrency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedCurrencies(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultVat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConversionAddon(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRounding(value);
      break;
    case 9:
      var value = new proto.swift.iam.v1.BranchConfig;
      reader.readMessage(value,proto.swift.iam.v1.BranchConfig.deserializeBinaryFromReader);
      msg.setBranchConfig(value);
      break;
    case 10:
      var value = new proto.swift.iam.v1.FinanceInfo;
      reader.readMessage(value,proto.swift.iam.v1.FinanceInfo.deserializeBinaryFromReader);
      msg.setFinanceInfo(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableCreditSeries(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEuVat(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoInvoicing(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReminderOverdueDays(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDailyInternalRate(value);
      break;
    case 16:
      var value = new proto.swift.iam.v1.InvoiceIntegration;
      reader.readMessage(value,proto.swift.iam.v1.InvoiceIntegration.deserializeBinaryFromReader);
      msg.addInvoiceIntegrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.Branch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.Branch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.Branch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.Branch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDefaultCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAcceptedCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getDefaultVat();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getConversionAddon();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getRounding();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBranchConfig();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.iam.v1.BranchConfig.serializeBinaryToWriter
    );
  }
  f = message.getFinanceInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.swift.iam.v1.FinanceInfo.serializeBinaryToWriter
    );
  }
  f = message.getEnableCreditSeries();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getEuVat();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAutoInvoicing();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getReminderOverdueDays();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDailyInternalRate();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getInvoiceIntegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.swift.iam.v1.InvoiceIntegration.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.Branch.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 provider_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.Branch.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.swift.iam.v1.Branch.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string default_currency = 4;
 * @return {string}
 */
proto.swift.iam.v1.Branch.prototype.getDefaultCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setDefaultCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string accepted_currencies = 5;
 * @return {!Array<string>}
 */
proto.swift.iam.v1.Branch.prototype.getAcceptedCurrenciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setAcceptedCurrenciesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.addAcceptedCurrencies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.clearAcceptedCurrenciesList = function() {
  return this.setAcceptedCurrenciesList([]);
};


/**
 * optional int64 default_vat = 6;
 * @return {number}
 */
proto.swift.iam.v1.Branch.prototype.getDefaultVat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setDefaultVat = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 conversion_addon = 7;
 * @return {number}
 */
proto.swift.iam.v1.Branch.prototype.getConversionAddon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setConversionAddon = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool rounding = 8;
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.getRounding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setRounding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional BranchConfig branch_config = 9;
 * @return {?proto.swift.iam.v1.BranchConfig}
 */
proto.swift.iam.v1.Branch.prototype.getBranchConfig = function() {
  return /** @type{?proto.swift.iam.v1.BranchConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.BranchConfig, 9));
};


/**
 * @param {?proto.swift.iam.v1.BranchConfig|undefined} value
 * @return {!proto.swift.iam.v1.Branch} returns this
*/
proto.swift.iam.v1.Branch.prototype.setBranchConfig = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.clearBranchConfig = function() {
  return this.setBranchConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.hasBranchConfig = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FinanceInfo finance_info = 10;
 * @return {?proto.swift.iam.v1.FinanceInfo}
 */
proto.swift.iam.v1.Branch.prototype.getFinanceInfo = function() {
  return /** @type{?proto.swift.iam.v1.FinanceInfo} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.FinanceInfo, 10));
};


/**
 * @param {?proto.swift.iam.v1.FinanceInfo|undefined} value
 * @return {!proto.swift.iam.v1.Branch} returns this
*/
proto.swift.iam.v1.Branch.prototype.setFinanceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.clearFinanceInfo = function() {
  return this.setFinanceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.hasFinanceInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool enable_credit_series = 11;
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.getEnableCreditSeries = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setEnableCreditSeries = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool eu_vat = 12;
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.getEuVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setEuVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool auto_invoicing = 13;
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.getAutoInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setAutoInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 reminder_overdue_days = 14;
 * @return {number}
 */
proto.swift.iam.v1.Branch.prototype.getReminderOverdueDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setReminderOverdueDays = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool daily_internal_rate = 15;
 * @return {boolean}
 */
proto.swift.iam.v1.Branch.prototype.getDailyInternalRate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.setDailyInternalRate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated InvoiceIntegration invoice_integrations = 16;
 * @return {!Array<!proto.swift.iam.v1.InvoiceIntegration>}
 */
proto.swift.iam.v1.Branch.prototype.getInvoiceIntegrationsList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.InvoiceIntegration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.InvoiceIntegration, 16));
};


/**
 * @param {!Array<!proto.swift.iam.v1.InvoiceIntegration>} value
 * @return {!proto.swift.iam.v1.Branch} returns this
*/
proto.swift.iam.v1.Branch.prototype.setInvoiceIntegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.swift.iam.v1.InvoiceIntegration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.InvoiceIntegration}
 */
proto.swift.iam.v1.Branch.prototype.addInvoiceIntegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.swift.iam.v1.InvoiceIntegration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.Branch} returns this
 */
proto.swift.iam.v1.Branch.prototype.clearInvoiceIntegrationsList = function() {
  return this.setInvoiceIntegrationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.iam.v1.InvoiceIntegration.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.swift.iam.v1.InvoiceIntegration.InvoiceReferenceCase = {
  INVOICE_REFERENCE_NOT_SET: 0,
  VISMA_REFERENCE: 3
};

/**
 * @return {proto.swift.iam.v1.InvoiceIntegration.InvoiceReferenceCase}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.getInvoiceReferenceCase = function() {
  return /** @type {proto.swift.iam.v1.InvoiceIntegration.InvoiceReferenceCase} */(jspb.Message.computeOneofCase(this, proto.swift.iam.v1.InvoiceIntegration.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.InvoiceIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.InvoiceIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.InvoiceIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {
providerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
branchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
vismaReference: (f = msg.getVismaReference()) && proto.swift.iam.v1.VismaReference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.InvoiceIntegration}
 */
proto.swift.iam.v1.InvoiceIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.InvoiceIntegration;
  return proto.swift.iam.v1.InvoiceIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.InvoiceIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.InvoiceIntegration}
 */
proto.swift.iam.v1.InvoiceIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.common.ProviderType} */ (reader.readEnum());
      msg.setProviderType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    case 3:
      var value = new proto.swift.iam.v1.VismaReference;
      reader.readMessage(value,proto.swift.iam.v1.VismaReference.deserializeBinaryFromReader);
      msg.setVismaReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.InvoiceIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.InvoiceIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.InvoiceIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getVismaReference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.iam.v1.VismaReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.ProviderType provider_type = 1;
 * @return {!proto.swift.common.ProviderType}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.getProviderType = function() {
  return /** @type {!proto.swift.common.ProviderType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.common.ProviderType} value
 * @return {!proto.swift.iam.v1.InvoiceIntegration} returns this
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.setProviderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 branch_id = 2;
 * @return {number}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.InvoiceIntegration} returns this
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional VismaReference visma_reference = 3;
 * @return {?proto.swift.iam.v1.VismaReference}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.getVismaReference = function() {
  return /** @type{?proto.swift.iam.v1.VismaReference} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.VismaReference, 3));
};


/**
 * @param {?proto.swift.iam.v1.VismaReference|undefined} value
 * @return {!proto.swift.iam.v1.InvoiceIntegration} returns this
*/
proto.swift.iam.v1.InvoiceIntegration.prototype.setVismaReference = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.swift.iam.v1.InvoiceIntegration.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.InvoiceIntegration} returns this
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.clearVismaReference = function() {
  return this.setVismaReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.InvoiceIntegration.prototype.hasVismaReference = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.VismaReference.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.VismaReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.VismaReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.VismaReference.toObject = function(includeInstance, msg) {
  var f, obj = {
customerNo: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.VismaReference}
 */
proto.swift.iam.v1.VismaReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.VismaReference;
  return proto.swift.iam.v1.VismaReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.VismaReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.VismaReference}
 */
proto.swift.iam.v1.VismaReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.VismaReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.VismaReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.VismaReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.VismaReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerNo();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 customer_no = 1;
 * @return {number}
 */
proto.swift.iam.v1.VismaReference.prototype.getCustomerNo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.VismaReference} returns this
 */
proto.swift.iam.v1.VismaReference.prototype.setCustomerNo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.BranchConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.BranchConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.BranchConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.BranchConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
address: (f = msg.getAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.BranchConfig}
 */
proto.swift.iam.v1.BranchConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.BranchConfig;
  return proto.swift.iam.v1.BranchConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.BranchConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.BranchConfig}
 */
proto.swift.iam.v1.BranchConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.BranchConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.BranchConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.BranchConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.BranchConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.crm.v1.Address address = 1;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.BranchConfig.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 1));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.BranchConfig} returns this
*/
proto.swift.iam.v1.BranchConfig.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.BranchConfig} returns this
 */
proto.swift.iam.v1.BranchConfig.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.BranchConfig.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.FinanceInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.FinanceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.FinanceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.FinanceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.FinanceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
orgNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
vat: jspb.Message.getFieldWithDefault(msg, 2, ""),
ibanList: jspb.Message.toObjectList(msg.getIbanList(),
    proto.swift.iam.v1.IbanType.toObject, includeInstance),
bicSwift: jspb.Message.getFieldWithDefault(msg, 4, ""),
bankgiro: jspb.Message.getFieldWithDefault(msg, 5, ""),
bankname: jspb.Message.getFieldWithDefault(msg, 6, ""),
financeEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
invoiceAddress: (f = msg.getInvoiceAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.FinanceInfo}
 */
proto.swift.iam.v1.FinanceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.FinanceInfo;
  return proto.swift.iam.v1.FinanceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.FinanceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.FinanceInfo}
 */
proto.swift.iam.v1.FinanceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVat(value);
      break;
    case 3:
      var value = new proto.swift.iam.v1.IbanType;
      reader.readMessage(value,proto.swift.iam.v1.IbanType.deserializeBinaryFromReader);
      msg.addIban(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBicSwift(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankgiro(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinanceEmail(value);
      break;
    case 8:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setInvoiceAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.FinanceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.FinanceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.FinanceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.FinanceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIbanList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.swift.iam.v1.IbanType.serializeBinaryToWriter
    );
  }
  f = message.getBicSwift();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankgiro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBankname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFinanceEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvoiceAddress();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string org_number = 1;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getOrgNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setOrgNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vat = 2;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getVat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setVat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated IbanType iban = 3;
 * @return {!Array<!proto.swift.iam.v1.IbanType>}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getIbanList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.IbanType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.IbanType, 3));
};


/**
 * @param {!Array<!proto.swift.iam.v1.IbanType>} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
*/
proto.swift.iam.v1.FinanceInfo.prototype.setIbanList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.swift.iam.v1.IbanType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.IbanType}
 */
proto.swift.iam.v1.FinanceInfo.prototype.addIban = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.swift.iam.v1.IbanType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.clearIbanList = function() {
  return this.setIbanList([]);
};


/**
 * optional string bic_swift = 4;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getBicSwift = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setBicSwift = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bankgiro = 5;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getBankgiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setBankgiro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bankname = 6;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getBankname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setBankname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string finance_email = 7;
 * @return {string}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getFinanceEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.setFinanceEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional swift.crm.v1.Address invoice_address = 8;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.iam.v1.FinanceInfo.prototype.getInvoiceAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 8));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
*/
proto.swift.iam.v1.FinanceInfo.prototype.setInvoiceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.FinanceInfo} returns this
 */
proto.swift.iam.v1.FinanceInfo.prototype.clearInvoiceAddress = function() {
  return this.setInvoiceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.FinanceInfo.prototype.hasInvoiceAddress = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.IbanType.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.IbanType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.IbanType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.IbanType.toObject = function(includeInstance, msg) {
  var f, obj = {
iban: jspb.Message.getFieldWithDefault(msg, 1, ""),
ibanDescription: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.IbanType}
 */
proto.swift.iam.v1.IbanType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.IbanType;
  return proto.swift.iam.v1.IbanType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.IbanType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.IbanType}
 */
proto.swift.iam.v1.IbanType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIban(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIbanDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.IbanType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.IbanType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.IbanType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.IbanType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIban();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIbanDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string iban = 1;
 * @return {string}
 */
proto.swift.iam.v1.IbanType.prototype.getIban = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.IbanType} returns this
 */
proto.swift.iam.v1.IbanType.prototype.setIban = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iban_description = 2;
 * @return {string}
 */
proto.swift.iam.v1.IbanType.prototype.getIbanDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.iam.v1.IbanType} returns this
 */
proto.swift.iam.v1.IbanType.prototype.setIbanDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.BranchFilter.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.BranchFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.BranchFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.BranchFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.BranchFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
branchIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
providerIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.BranchFilter}
 */
proto.swift.iam.v1.BranchFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.BranchFilter;
  return proto.swift.iam.v1.BranchFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.BranchFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.BranchFilter}
 */
proto.swift.iam.v1.BranchFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBranchId(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.BranchFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.BranchFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.BranchFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.BranchFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getProviderIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 branch_id = 1;
 * @return {!Array<number>}
 */
proto.swift.iam.v1.BranchFilter.prototype.getBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.setBranchIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.addBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.clearBranchIdList = function() {
  return this.setBranchIdList([]);
};


/**
 * repeated int64 provider_id = 2;
 * @return {!Array<number>}
 */
proto.swift.iam.v1.BranchFilter.prototype.getProviderIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.setProviderIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.addProviderId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.BranchFilter} returns this
 */
proto.swift.iam.v1.BranchFilter.prototype.clearProviderIdList = function() {
  return this.setProviderIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListBranchesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListBranchesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListBranchesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.iam.v1.BranchFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListBranchesRequest}
 */
proto.swift.iam.v1.ListBranchesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListBranchesRequest;
  return proto.swift.iam.v1.ListBranchesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListBranchesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListBranchesRequest}
 */
proto.swift.iam.v1.ListBranchesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.BranchFilter;
      reader.readMessage(value,proto.swift.iam.v1.BranchFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListBranchesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListBranchesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListBranchesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.iam.v1.BranchFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.iam.v1.ListBranchesRequest} returns this
*/
proto.swift.iam.v1.ListBranchesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListBranchesRequest} returns this
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BranchFilter filter = 2;
 * @return {?proto.swift.iam.v1.BranchFilter}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.iam.v1.BranchFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.BranchFilter, 2));
};


/**
 * @param {?proto.swift.iam.v1.BranchFilter|undefined} value
 * @return {!proto.swift.iam.v1.ListBranchesRequest} returns this
*/
proto.swift.iam.v1.ListBranchesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.ListBranchesRequest} returns this
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.ListBranchesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.iam.v1.ListBranchesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.ListBranchesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.ListBranchesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListBranchesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
branchesList: jspb.Message.toObjectList(msg.getBranchesList(),
    proto.swift.iam.v1.Branch.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.ListBranchesResponse}
 */
proto.swift.iam.v1.ListBranchesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.ListBranchesResponse;
  return proto.swift.iam.v1.ListBranchesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.ListBranchesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.ListBranchesResponse}
 */
proto.swift.iam.v1.ListBranchesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.addBranches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.ListBranchesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.ListBranchesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.ListBranchesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBranchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.ListBranchesResponse} returns this
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Branch branches = 2;
 * @return {!Array<!proto.swift.iam.v1.Branch>}
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.getBranchesList = function() {
  return /** @type{!Array<!proto.swift.iam.v1.Branch>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.iam.v1.Branch, 2));
};


/**
 * @param {!Array<!proto.swift.iam.v1.Branch>} value
 * @return {!proto.swift.iam.v1.ListBranchesResponse} returns this
*/
proto.swift.iam.v1.ListBranchesResponse.prototype.setBranchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.iam.v1.Branch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.addBranches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.iam.v1.Branch, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.iam.v1.ListBranchesResponse} returns this
 */
proto.swift.iam.v1.ListBranchesResponse.prototype.clearBranchesList = function() {
  return this.setBranchesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetBranchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetBranchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetBranchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetBranchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
branchId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetBranchRequest}
 */
proto.swift.iam.v1.GetBranchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetBranchRequest;
  return proto.swift.iam.v1.GetBranchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetBranchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetBranchRequest}
 */
proto.swift.iam.v1.GetBranchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetBranchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetBranchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetBranchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetBranchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.GetBranchRequest.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.GetBranchRequest} returns this
 */
proto.swift.iam.v1.GetBranchRequest.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.GetBranchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.GetBranchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.GetBranchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetBranchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
branch: (f = msg.getBranch()) && proto.swift.iam.v1.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.GetBranchResponse}
 */
proto.swift.iam.v1.GetBranchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.GetBranchResponse;
  return proto.swift.iam.v1.GetBranchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.GetBranchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.GetBranchResponse}
 */
proto.swift.iam.v1.GetBranchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.GetBranchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.GetBranchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.GetBranchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.GetBranchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional Branch branch = 1;
 * @return {?proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.GetBranchResponse.prototype.getBranch = function() {
  return /** @type{?proto.swift.iam.v1.Branch} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Branch, 1));
};


/**
 * @param {?proto.swift.iam.v1.Branch|undefined} value
 * @return {!proto.swift.iam.v1.GetBranchResponse} returns this
*/
proto.swift.iam.v1.GetBranchResponse.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.GetBranchResponse} returns this
 */
proto.swift.iam.v1.GetBranchResponse.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.GetBranchResponse.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateBranchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateBranchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateBranchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateBranchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
branch: (f = msg.getBranch()) && proto.swift.iam.v1.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateBranchRequest}
 */
proto.swift.iam.v1.CreateBranchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateBranchRequest;
  return proto.swift.iam.v1.CreateBranchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateBranchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateBranchRequest}
 */
proto.swift.iam.v1.CreateBranchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateBranchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateBranchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateBranchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateBranchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional Branch branch = 1;
 * @return {?proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.CreateBranchRequest.prototype.getBranch = function() {
  return /** @type{?proto.swift.iam.v1.Branch} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Branch, 1));
};


/**
 * @param {?proto.swift.iam.v1.Branch|undefined} value
 * @return {!proto.swift.iam.v1.CreateBranchRequest} returns this
*/
proto.swift.iam.v1.CreateBranchRequest.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateBranchRequest} returns this
 */
proto.swift.iam.v1.CreateBranchRequest.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateBranchRequest.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.CreateBranchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.CreateBranchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.CreateBranchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateBranchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
branch: (f = msg.getBranch()) && proto.swift.iam.v1.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.CreateBranchResponse}
 */
proto.swift.iam.v1.CreateBranchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.CreateBranchResponse;
  return proto.swift.iam.v1.CreateBranchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.CreateBranchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.CreateBranchResponse}
 */
proto.swift.iam.v1.CreateBranchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.CreateBranchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.CreateBranchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.CreateBranchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.CreateBranchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional Branch branch = 1;
 * @return {?proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.CreateBranchResponse.prototype.getBranch = function() {
  return /** @type{?proto.swift.iam.v1.Branch} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Branch, 1));
};


/**
 * @param {?proto.swift.iam.v1.Branch|undefined} value
 * @return {!proto.swift.iam.v1.CreateBranchResponse} returns this
*/
proto.swift.iam.v1.CreateBranchResponse.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.CreateBranchResponse} returns this
 */
proto.swift.iam.v1.CreateBranchResponse.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.CreateBranchResponse.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditBranchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditBranchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditBranchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditBranchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
branch: (f = msg.getBranch()) && proto.swift.iam.v1.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditBranchRequest}
 */
proto.swift.iam.v1.EditBranchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditBranchRequest;
  return proto.swift.iam.v1.EditBranchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditBranchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditBranchRequest}
 */
proto.swift.iam.v1.EditBranchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditBranchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditBranchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditBranchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditBranchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional Branch branch = 1;
 * @return {?proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.EditBranchRequest.prototype.getBranch = function() {
  return /** @type{?proto.swift.iam.v1.Branch} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Branch, 1));
};


/**
 * @param {?proto.swift.iam.v1.Branch|undefined} value
 * @return {!proto.swift.iam.v1.EditBranchRequest} returns this
*/
proto.swift.iam.v1.EditBranchRequest.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditBranchRequest} returns this
 */
proto.swift.iam.v1.EditBranchRequest.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditBranchRequest.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.EditBranchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.EditBranchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.EditBranchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditBranchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
branch: (f = msg.getBranch()) && proto.swift.iam.v1.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.EditBranchResponse}
 */
proto.swift.iam.v1.EditBranchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.EditBranchResponse;
  return proto.swift.iam.v1.EditBranchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.EditBranchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.EditBranchResponse}
 */
proto.swift.iam.v1.EditBranchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.iam.v1.Branch;
      reader.readMessage(value,proto.swift.iam.v1.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.EditBranchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.EditBranchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.EditBranchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.EditBranchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.iam.v1.Branch.serializeBinaryToWriter
    );
  }
};


/**
 * optional Branch branch = 1;
 * @return {?proto.swift.iam.v1.Branch}
 */
proto.swift.iam.v1.EditBranchResponse.prototype.getBranch = function() {
  return /** @type{?proto.swift.iam.v1.Branch} */ (
    jspb.Message.getWrapperField(this, proto.swift.iam.v1.Branch, 1));
};


/**
 * @param {?proto.swift.iam.v1.Branch|undefined} value
 * @return {!proto.swift.iam.v1.EditBranchResponse} returns this
*/
proto.swift.iam.v1.EditBranchResponse.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.iam.v1.EditBranchResponse} returns this
 */
proto.swift.iam.v1.EditBranchResponse.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.iam.v1.EditBranchResponse.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.DeleteBranchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.DeleteBranchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.DeleteBranchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.DeleteBranchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
branchId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.DeleteBranchRequest}
 */
proto.swift.iam.v1.DeleteBranchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.DeleteBranchRequest;
  return proto.swift.iam.v1.DeleteBranchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.DeleteBranchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.DeleteBranchRequest}
 */
proto.swift.iam.v1.DeleteBranchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.DeleteBranchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.DeleteBranchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.DeleteBranchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.DeleteBranchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.DeleteBranchRequest.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.DeleteBranchRequest} returns this
 */
proto.swift.iam.v1.DeleteBranchRequest.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.iam.v1.DeleteBranchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.iam.v1.DeleteBranchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.iam.v1.DeleteBranchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.DeleteBranchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
branchId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.iam.v1.DeleteBranchResponse}
 */
proto.swift.iam.v1.DeleteBranchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.iam.v1.DeleteBranchResponse;
  return proto.swift.iam.v1.DeleteBranchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.iam.v1.DeleteBranchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.iam.v1.DeleteBranchResponse}
 */
proto.swift.iam.v1.DeleteBranchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.iam.v1.DeleteBranchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.iam.v1.DeleteBranchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.iam.v1.DeleteBranchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.iam.v1.DeleteBranchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 branch_id = 1;
 * @return {number}
 */
proto.swift.iam.v1.DeleteBranchResponse.prototype.getBranchId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.iam.v1.DeleteBranchResponse} returns this
 */
proto.swift.iam.v1.DeleteBranchResponse.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.swift.iam.v1);
