/**
 * @fileoverview gRPC-Web generated client stub for swift.contract.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: contract/v1/contract.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var common_contract_pb = require('../../common/contract_pb.js')

var common_filter_pb = require('../../common/filter_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')

var common_query_pb = require('../../common/query_pb.js')
const proto = {};
proto.swift = {};
proto.swift.contract = {};
proto.swift.contract.v1 = require('./contract_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.contract.v1.ContractServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.contract.v1.ContractServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.ListContractsRequest,
 *   !proto.swift.contract.v1.ListContractsResponse>}
 */
const methodDescriptor_ContractService_ListContracts = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/ListContracts',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.ListContractsRequest,
  proto.swift.contract.v1.ListContractsResponse,
  /**
   * @param {!proto.swift.contract.v1.ListContractsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.ListContractsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.ListContractsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.ListContractsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.ListContractsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.listContracts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/ListContracts',
      request,
      metadata || {},
      methodDescriptor_ContractService_ListContracts,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.ListContractsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.ListContractsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.listContracts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/ListContracts',
      request,
      metadata || {},
      methodDescriptor_ContractService_ListContracts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.GetContractRequest,
 *   !proto.swift.contract.v1.GetContractResponse>}
 */
const methodDescriptor_ContractService_GetContract = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/GetContract',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.GetContractRequest,
  proto.swift.contract.v1.GetContractResponse,
  /**
   * @param {!proto.swift.contract.v1.GetContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.GetContractResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.GetContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.GetContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.getContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/GetContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContract,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.GetContractResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.getContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/GetContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.CreateContractRequest,
 *   !proto.swift.contract.v1.CreateContractResponse>}
 */
const methodDescriptor_ContractService_CreateContract = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/CreateContract',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.CreateContractRequest,
  proto.swift.contract.v1.CreateContractResponse,
  /**
   * @param {!proto.swift.contract.v1.CreateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.CreateContractResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.CreateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.CreateContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.CreateContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.createContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/CreateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateContract,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.CreateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.CreateContractResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.createContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/CreateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.ListSurchargesRequest,
 *   !proto.swift.contract.v1.ListSurchargesResponse>}
 */
const methodDescriptor_ContractService_ListSurcharges = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/ListSurcharges',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.ListSurchargesRequest,
  proto.swift.contract.v1.ListSurchargesResponse,
  /**
   * @param {!proto.swift.contract.v1.ListSurchargesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.ListSurchargesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.ListSurchargesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.ListSurchargesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.ListSurchargesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.listSurcharges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/ListSurcharges',
      request,
      metadata || {},
      methodDescriptor_ContractService_ListSurcharges,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.ListSurchargesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.ListSurchargesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.listSurcharges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/ListSurcharges',
      request,
      metadata || {},
      methodDescriptor_ContractService_ListSurcharges);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.CreateSurchargeRequest,
 *   !proto.swift.contract.v1.CreateSurchargeResponse>}
 */
const methodDescriptor_ContractService_CreateSurcharge = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/CreateSurcharge',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.CreateSurchargeRequest,
  proto.swift.contract.v1.CreateSurchargeResponse,
  /**
   * @param {!proto.swift.contract.v1.CreateSurchargeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.CreateSurchargeResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.CreateSurchargeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.CreateSurchargeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.CreateSurchargeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.createSurcharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/CreateSurcharge',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateSurcharge,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.CreateSurchargeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.CreateSurchargeResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.createSurcharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/CreateSurcharge',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateSurcharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.UpdateContractRequest,
 *   !proto.swift.contract.v1.UpdateContractResponse>}
 */
const methodDescriptor_ContractService_UpdateContract = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/UpdateContract',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.UpdateContractRequest,
  proto.swift.contract.v1.UpdateContractResponse,
  /**
   * @param {!proto.swift.contract.v1.UpdateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.UpdateContractResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.UpdateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.UpdateContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.UpdateContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.updateContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/UpdateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateContract,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.UpdateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.UpdateContractResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.updateContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/UpdateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.contract.v1.UpdateSurchargeRequest,
 *   !proto.swift.contract.v1.UpdateSurchargeResponse>}
 */
const methodDescriptor_ContractService_UpdateSurcharges = new grpc.web.MethodDescriptor(
  '/swift.contract.v1.ContractService/UpdateSurcharges',
  grpc.web.MethodType.UNARY,
  proto.swift.contract.v1.UpdateSurchargeRequest,
  proto.swift.contract.v1.UpdateSurchargeResponse,
  /**
   * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.contract.v1.UpdateSurchargeResponse.deserializeBinary
);


/**
 * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.contract.v1.UpdateSurchargeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.contract.v1.UpdateSurchargeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.contract.v1.ContractServiceClient.prototype.updateSurcharges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.contract.v1.ContractService/UpdateSurcharges',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateSurcharges,
      callback);
};


/**
 * @param {!proto.swift.contract.v1.UpdateSurchargeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.contract.v1.UpdateSurchargeResponse>}
 *     Promise that resolves to the response
 */
proto.swift.contract.v1.ContractServicePromiseClient.prototype.updateSurcharges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.contract.v1.ContractService/UpdateSurcharges',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateSurcharges);
};


module.exports = proto.swift.contract.v1;

