/**
 * @fileoverview gRPC-Web generated client stub for swift.currency.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: currency/v1/currency.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')
const proto = {};
proto.swift = {};
proto.swift.currency = {};
proto.swift.currency.v1 = require('./currency_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.currency.v1.CurrencyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.currency.v1.CurrencyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.currency.v1.CreateRateRequest,
 *   !proto.swift.currency.v1.CreateRateResponse>}
 */
const methodDescriptor_CurrencyService_CreateRate = new grpc.web.MethodDescriptor(
  '/swift.currency.v1.CurrencyService/CreateRate',
  grpc.web.MethodType.UNARY,
  proto.swift.currency.v1.CreateRateRequest,
  proto.swift.currency.v1.CreateRateResponse,
  /**
   * @param {!proto.swift.currency.v1.CreateRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.currency.v1.CreateRateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.currency.v1.CreateRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.currency.v1.CreateRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.currency.v1.CreateRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.currency.v1.CurrencyServiceClient.prototype.createRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/CreateRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_CreateRate,
      callback);
};


/**
 * @param {!proto.swift.currency.v1.CreateRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.currency.v1.CreateRateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.currency.v1.CurrencyServicePromiseClient.prototype.createRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/CreateRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_CreateRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.currency.v1.ListCurrencyRatesRequest,
 *   !proto.swift.currency.v1.ListCurrencyRatesResponse>}
 */
const methodDescriptor_CurrencyService_ListCurrencyRates = new grpc.web.MethodDescriptor(
  '/swift.currency.v1.CurrencyService/ListCurrencyRates',
  grpc.web.MethodType.UNARY,
  proto.swift.currency.v1.ListCurrencyRatesRequest,
  proto.swift.currency.v1.ListCurrencyRatesResponse,
  /**
   * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.currency.v1.ListCurrencyRatesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.currency.v1.ListCurrencyRatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.currency.v1.ListCurrencyRatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.currency.v1.CurrencyServiceClient.prototype.listCurrencyRates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/ListCurrencyRates',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_ListCurrencyRates,
      callback);
};


/**
 * @param {!proto.swift.currency.v1.ListCurrencyRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.currency.v1.ListCurrencyRatesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.currency.v1.CurrencyServicePromiseClient.prototype.listCurrencyRates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/ListCurrencyRates',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_ListCurrencyRates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.currency.v1.GetCurrencyRateRequest,
 *   !proto.swift.currency.v1.GetCurrencyRateResponse>}
 */
const methodDescriptor_CurrencyService_GetCurrencyRate = new grpc.web.MethodDescriptor(
  '/swift.currency.v1.CurrencyService/GetCurrencyRate',
  grpc.web.MethodType.UNARY,
  proto.swift.currency.v1.GetCurrencyRateRequest,
  proto.swift.currency.v1.GetCurrencyRateResponse,
  /**
   * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.currency.v1.GetCurrencyRateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.currency.v1.GetCurrencyRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.currency.v1.GetCurrencyRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.currency.v1.CurrencyServiceClient.prototype.getCurrencyRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/GetCurrencyRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_GetCurrencyRate,
      callback);
};


/**
 * @param {!proto.swift.currency.v1.GetCurrencyRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.currency.v1.GetCurrencyRateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.currency.v1.CurrencyServicePromiseClient.prototype.getCurrencyRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/GetCurrencyRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_GetCurrencyRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.currency.v1.ConvertCurrencyRequest,
 *   !proto.swift.currency.v1.ConvertCurrencyResponse>}
 */
const methodDescriptor_CurrencyService_ConvertCurrency = new grpc.web.MethodDescriptor(
  '/swift.currency.v1.CurrencyService/ConvertCurrency',
  grpc.web.MethodType.UNARY,
  proto.swift.currency.v1.ConvertCurrencyRequest,
  proto.swift.currency.v1.ConvertCurrencyResponse,
  /**
   * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.currency.v1.ConvertCurrencyResponse.deserializeBinary
);


/**
 * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.currency.v1.ConvertCurrencyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.currency.v1.ConvertCurrencyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.currency.v1.CurrencyServiceClient.prototype.convertCurrency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/ConvertCurrency',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_ConvertCurrency,
      callback);
};


/**
 * @param {!proto.swift.currency.v1.ConvertCurrencyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.currency.v1.ConvertCurrencyResponse>}
 *     Promise that resolves to the response
 */
proto.swift.currency.v1.CurrencyServicePromiseClient.prototype.convertCurrency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/ConvertCurrency',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_ConvertCurrency);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.currency.v1.UpdateExternalRateRequest,
 *   !proto.swift.currency.v1.UpdateExternalRateResponse>}
 */
const methodDescriptor_CurrencyService_UpdateExternalRate = new grpc.web.MethodDescriptor(
  '/swift.currency.v1.CurrencyService/UpdateExternalRate',
  grpc.web.MethodType.UNARY,
  proto.swift.currency.v1.UpdateExternalRateRequest,
  proto.swift.currency.v1.UpdateExternalRateResponse,
  /**
   * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.currency.v1.UpdateExternalRateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.currency.v1.UpdateExternalRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.currency.v1.UpdateExternalRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.currency.v1.CurrencyServiceClient.prototype.updateExternalRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/UpdateExternalRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_UpdateExternalRate,
      callback);
};


/**
 * @param {!proto.swift.currency.v1.UpdateExternalRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.currency.v1.UpdateExternalRateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.currency.v1.CurrencyServicePromiseClient.prototype.updateExternalRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.currency.v1.CurrencyService/UpdateExternalRate',
      request,
      metadata || {},
      methodDescriptor_CurrencyService_UpdateExternalRate);
};


module.exports = proto.swift.currency.v1;

