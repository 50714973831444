/**
 * @fileoverview gRPC-Web generated client stub for swift.iam.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: iam/v1/organization.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_query_pb = require('../../common/query_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var crm_v1_address_pb = require('../../crm/v1/address_pb.js')
const proto = {};
proto.swift = {};
proto.swift.iam = {};
proto.swift.iam.v1 = require('./organization_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.OrganizationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.OrganizationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ListOrganizationsRequest,
 *   !proto.swift.iam.v1.ListOrganizationsResponse>}
 */
const methodDescriptor_OrganizationService_ListOrganizations = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/ListOrganizations',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ListOrganizationsRequest,
  proto.swift.iam.v1.ListOrganizationsResponse,
  /**
   * @param {!proto.swift.iam.v1.ListOrganizationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ListOrganizationsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ListOrganizationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ListOrganizationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ListOrganizationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.listOrganizations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/ListOrganizations',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_ListOrganizations,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ListOrganizationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ListOrganizationsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.listOrganizations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/ListOrganizations',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_ListOrganizations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.GetOrganizationRequest,
 *   !proto.swift.iam.v1.GetOrganizationResponse>}
 */
const methodDescriptor_OrganizationService_GetOrganization = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/GetOrganization',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.GetOrganizationRequest,
  proto.swift.iam.v1.GetOrganizationResponse,
  /**
   * @param {!proto.swift.iam.v1.GetOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.GetOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.GetOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.GetOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.GetOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.getOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_GetOrganization,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.GetOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.GetOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.getOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_GetOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.CreateOrganizationRequest,
 *   !proto.swift.iam.v1.CreateOrganizationResponse>}
 */
const methodDescriptor_OrganizationService_CreateOrganization = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/CreateOrganization',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.CreateOrganizationRequest,
  proto.swift.iam.v1.CreateOrganizationResponse,
  /**
   * @param {!proto.swift.iam.v1.CreateOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.CreateOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.CreateOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.CreateOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.CreateOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.createOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_CreateOrganization,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.CreateOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.CreateOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.createOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_CreateOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.EditOrganizationRequest,
 *   !proto.swift.iam.v1.EditOrganizationResponse>}
 */
const methodDescriptor_OrganizationService_EditOrganization = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/EditOrganization',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.EditOrganizationRequest,
  proto.swift.iam.v1.EditOrganizationResponse,
  /**
   * @param {!proto.swift.iam.v1.EditOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.EditOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.EditOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.EditOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.EditOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.editOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditOrganization,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.EditOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.EditOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.editOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditOrganization',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.EditInvoicingRequest,
 *   !proto.swift.iam.v1.EditInvoicingResponse>}
 */
const methodDescriptor_OrganizationService_EditInvoicing = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/EditInvoicing',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.EditInvoicingRequest,
  proto.swift.iam.v1.EditInvoicingResponse,
  /**
   * @param {!proto.swift.iam.v1.EditInvoicingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.EditInvoicingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.EditInvoicingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.EditInvoicingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.EditInvoicingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.editInvoicing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditInvoicing',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditInvoicing,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.EditInvoicingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.EditInvoicingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.editInvoicing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditInvoicing',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditInvoicing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.EditInstructionsRequest,
 *   !proto.swift.iam.v1.EditInstructionsResponse>}
 */
const methodDescriptor_OrganizationService_EditInstructions = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.OrganizationService/EditInstructions',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.EditInstructionsRequest,
  proto.swift.iam.v1.EditInstructionsResponse,
  /**
   * @param {!proto.swift.iam.v1.EditInstructionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.EditInstructionsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.EditInstructionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.EditInstructionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.EditInstructionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.OrganizationServiceClient.prototype.editInstructions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditInstructions',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditInstructions,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.EditInstructionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.EditInstructionsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.OrganizationServicePromiseClient.prototype.editInstructions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.OrganizationService/EditInstructions',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_EditInstructions);
};


module.exports = proto.swift.iam.v1;

