/**
 * @fileoverview gRPC-Web generated client stub for swift.invoicing.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: invoicing/v1/provider.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var common_provider_pb = require('../../common/provider_pb.js')
const proto = {};
proto.swift = {};
proto.swift.invoicing = {};
proto.swift.invoicing.v1 = require('./provider_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.ProviderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.ListProvidersRequest,
 *   !proto.swift.invoicing.v1.ListProvidersResponse>}
 */
const methodDescriptor_ProviderService_ListProviders = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/ListProviders',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.ListProvidersRequest,
  proto.swift.invoicing.v1.ListProvidersResponse,
  /**
   * @param {!proto.swift.invoicing.v1.ListProvidersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.ListProvidersResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.ListProvidersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.ListProvidersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.ListProvidersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.listProviders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/ListProviders',
      request,
      metadata || {},
      methodDescriptor_ProviderService_ListProviders,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.ListProvidersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.ListProvidersResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.listProviders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/ListProviders',
      request,
      metadata || {},
      methodDescriptor_ProviderService_ListProviders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.GetProviderRequest,
 *   !proto.swift.invoicing.v1.GetProviderResponse>}
 */
const methodDescriptor_ProviderService_GetProvider = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/GetProvider',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.GetProviderRequest,
  proto.swift.invoicing.v1.GetProviderResponse,
  /**
   * @param {!proto.swift.invoicing.v1.GetProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.GetProviderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.GetProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.GetProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.GetProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.getProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/GetProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_GetProvider,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.GetProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.GetProviderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.getProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/GetProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_GetProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.GetBranchProviderRequest,
 *   !proto.swift.invoicing.v1.GetBranchProviderResponse>}
 */
const methodDescriptor_ProviderService_GetBranchProvider = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/GetBranchProvider',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.GetBranchProviderRequest,
  proto.swift.invoicing.v1.GetBranchProviderResponse,
  /**
   * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.GetBranchProviderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.GetBranchProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.GetBranchProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.getBranchProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/GetBranchProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_GetBranchProvider,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.GetBranchProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.GetBranchProviderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.getBranchProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/GetBranchProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_GetBranchProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.CreateProviderRequest,
 *   !proto.swift.invoicing.v1.CreateProviderResponse>}
 */
const methodDescriptor_ProviderService_CreateProvider = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/CreateProvider',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.CreateProviderRequest,
  proto.swift.invoicing.v1.CreateProviderResponse,
  /**
   * @param {!proto.swift.invoicing.v1.CreateProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.CreateProviderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.CreateProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.CreateProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.CreateProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.createProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/CreateProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_CreateProvider,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.CreateProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.CreateProviderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.createProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/CreateProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_CreateProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.EditProviderRequest,
 *   !proto.swift.invoicing.v1.EditProviderResponse>}
 */
const methodDescriptor_ProviderService_EditProvider = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/EditProvider',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.EditProviderRequest,
  proto.swift.invoicing.v1.EditProviderResponse,
  /**
   * @param {!proto.swift.invoicing.v1.EditProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.EditProviderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.EditProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.EditProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.EditProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.editProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/EditProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_EditProvider,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.EditProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.EditProviderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.editProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/EditProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_EditProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.DeleteProviderRequest,
 *   !proto.swift.invoicing.v1.DeleteProviderResponse>}
 */
const methodDescriptor_ProviderService_DeleteProvider = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/DeleteProvider',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.DeleteProviderRequest,
  proto.swift.invoicing.v1.DeleteProviderResponse,
  /**
   * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.DeleteProviderResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.DeleteProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.DeleteProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.deleteProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/DeleteProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_DeleteProvider,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.DeleteProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.DeleteProviderResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.deleteProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/DeleteProvider',
      request,
      metadata || {},
      methodDescriptor_ProviderService_DeleteProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.SetAccountingRequest,
 *   !proto.swift.invoicing.v1.SetAccountingResponse>}
 */
const methodDescriptor_ProviderService_SetAccounting = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/SetAccounting',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.SetAccountingRequest,
  proto.swift.invoicing.v1.SetAccountingResponse,
  /**
   * @param {!proto.swift.invoicing.v1.SetAccountingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.SetAccountingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.SetAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.SetAccountingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.SetAccountingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.setAccounting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/SetAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_SetAccounting,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.SetAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.SetAccountingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.setAccounting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/SetAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_SetAccounting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.ListAccountingRequest,
 *   !proto.swift.invoicing.v1.ListAccountingResponse>}
 */
const methodDescriptor_ProviderService_ListAccounting = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/ListAccounting',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.ListAccountingRequest,
  proto.swift.invoicing.v1.ListAccountingResponse,
  /**
   * @param {!proto.swift.invoicing.v1.ListAccountingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.ListAccountingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.ListAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.ListAccountingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.ListAccountingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.listAccounting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/ListAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_ListAccounting,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.ListAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.ListAccountingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.listAccounting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/ListAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_ListAccounting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.invoicing.v1.DeleteAccountingRequest,
 *   !proto.swift.invoicing.v1.DeleteAccountingResponse>}
 */
const methodDescriptor_ProviderService_DeleteAccounting = new grpc.web.MethodDescriptor(
  '/swift.invoicing.v1.ProviderService/DeleteAccounting',
  grpc.web.MethodType.UNARY,
  proto.swift.invoicing.v1.DeleteAccountingRequest,
  proto.swift.invoicing.v1.DeleteAccountingResponse,
  /**
   * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.invoicing.v1.DeleteAccountingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.invoicing.v1.DeleteAccountingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.invoicing.v1.DeleteAccountingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.invoicing.v1.ProviderServiceClient.prototype.deleteAccounting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/DeleteAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_DeleteAccounting,
      callback);
};


/**
 * @param {!proto.swift.invoicing.v1.DeleteAccountingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.invoicing.v1.DeleteAccountingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.invoicing.v1.ProviderServicePromiseClient.prototype.deleteAccounting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.invoicing.v1.ProviderService/DeleteAccounting',
      request,
      metadata || {},
      methodDescriptor_ProviderService_DeleteAccounting);
};


module.exports = proto.swift.invoicing.v1;

