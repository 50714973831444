import { ISurcharge } from 'shared/interface/surcharge.interface'

import {
  ActionTypes,
  CLEAR_ERROR_MESSAGE,
  CREATE_SURCHARGE,
  CREATE_SURCHARGE_ERROR,
  CREATE_SURCHARGE_RESPONSE,
  LIST_SURCHARGES,
  LIST_SURCHARGES_RESPONSE,
  SET_IS_FETCHING,
  SURCHARGE_ERROR,
  UPDATE_SURCHARGE,
  UPDATE_SURCHARGE_ERROR,
  UPDATE_SURCHARGE_RESPONSE,
} from './actions'

// Define the initial state
export interface State {
  surcharges: ISurcharge[]
  loading?: boolean
  error?: { message: string; stack?: string; code?: number }
  createError?: { message: string; stack: string; code: number }
}

const initialState: State = {
  surcharges: [],
  loading: false,
}

// Reducer function
const surchargeReducer = (state = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case CREATE_SURCHARGE:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case CREATE_SURCHARGE_RESPONSE:
      return {
        ...state,
        loading: false,
        surcharges: [...state.surcharges, action.payload.surcharge],
      }

    case CREATE_SURCHARGE_ERROR:
      return {
        ...state,
        loading: false,
        createError: action.payload.message,
      }

    case LIST_SURCHARGES:
      return {
        ...state,
        loading: true,
        surcharges: [],
      }
    case LIST_SURCHARGES_RESPONSE:
      return {
        ...state,
        loading: false,
        surcharges: action.payload.surcharges,
      }

    case SET_IS_FETCHING:
      return {
        ...state,
        loading: action.payload.isFetching,
      }

    case SURCHARGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      }

    case UPDATE_SURCHARGE:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case UPDATE_SURCHARGE_RESPONSE:
      return {
        ...state,
        loading: false,
        surcharges: state.surcharges
          .map((surcharge) => {
            return (
              action.payload.surcharges.find((s) => s.surchargeId === surcharge.surchargeId) ??
              surcharge
            )
          })
          .concat(
            action.payload.surcharges.filter(
              (s) => !state.surcharges.some((existing) => existing.surchargeId === s.surchargeId),
            ),
          ),
      }
    case UPDATE_SURCHARGE_ERROR:
      return {
        ...state,
        loading: false,
        createError: action.payload.message,
      }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        createError: undefined,
      }
    default:
      return state
  }
}

export default surchargeReducer
