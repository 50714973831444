/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: booking/v1/bookingtemplate.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./bookingtemplate_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.BookingTemplateServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.BookingTemplateServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListBookingTemplatesRequest,
 *   !proto.swift.booking.v1.ListBookingTemplatesResponse>}
 */
const methodDescriptor_BookingTemplateService_ListBookingTemplates = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingTemplateService/ListBookingTemplates',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListBookingTemplatesRequest,
  proto.swift.booking.v1.ListBookingTemplatesResponse,
  /**
   * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListBookingTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListBookingTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListBookingTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingTemplateServiceClient.prototype.listBookingTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/ListBookingTemplates',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_ListBookingTemplates,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListBookingTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingTemplateServicePromiseClient.prototype.listBookingTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/ListBookingTemplates',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_ListBookingTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetBookingTemplateRequest,
 *   !proto.swift.booking.v1.GetBookingTemplateResponse>}
 */
const methodDescriptor_BookingTemplateService_GetBookingTemplate = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingTemplateService/GetBookingTemplate',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetBookingTemplateRequest,
  proto.swift.booking.v1.GetBookingTemplateResponse,
  /**
   * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetBookingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetBookingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetBookingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingTemplateServiceClient.prototype.getBookingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/GetBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_GetBookingTemplate,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetBookingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingTemplateServicePromiseClient.prototype.getBookingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/GetBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_GetBookingTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreateBookingTemplateRequest,
 *   !proto.swift.booking.v1.CreateBookingTemplateResponse>}
 */
const methodDescriptor_BookingTemplateService_CreateBookingTemplate = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingTemplateService/CreateBookingTemplate',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreateBookingTemplateRequest,
  proto.swift.booking.v1.CreateBookingTemplateResponse,
  /**
   * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreateBookingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreateBookingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreateBookingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingTemplateServiceClient.prototype.createBookingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/CreateBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_CreateBookingTemplate,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreateBookingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingTemplateServicePromiseClient.prototype.createBookingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/CreateBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_CreateBookingTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeleteBookingTemplateRequest,
 *   !proto.swift.booking.v1.DeleteBookingTemplateResponse>}
 */
const methodDescriptor_BookingTemplateService_DeleteBookingTemplate = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.BookingTemplateService/DeleteBookingTemplate',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeleteBookingTemplateRequest,
  proto.swift.booking.v1.DeleteBookingTemplateResponse,
  /**
   * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeleteBookingTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeleteBookingTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeleteBookingTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.BookingTemplateServiceClient.prototype.deleteBookingTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/DeleteBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_DeleteBookingTemplate,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeleteBookingTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.BookingTemplateServicePromiseClient.prototype.deleteBookingTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.BookingTemplateService/DeleteBookingTemplate',
      request,
      metadata || {},
      methodDescriptor_BookingTemplateService_DeleteBookingTemplate);
};


module.exports = proto.swift.booking.v1;

