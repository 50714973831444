// source: booking/v1/booking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var contract_v1_contract_pb = require('../../contract/v1/contract_pb.js');
goog.object.extend(proto, contract_v1_contract_pb);
var common_filter_pb = require('../../common/filter_pb.js');
goog.object.extend(proto, common_filter_pb);
var common_geolocation_pb = require('../../common/geolocation_pb.js');
goog.object.extend(proto, common_geolocation_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
var booking_v1_nomination_pb = require('../../booking/v1/nomination_pb.js');
goog.object.extend(proto, booking_v1_nomination_pb);
var booking_v1_supplier_pb = require('../../booking/v1/supplier_pb.js');
goog.object.extend(proto, booking_v1_supplier_pb);
var distance_v1_distance_pb = require('../../distance/v1/distance_pb.js');
goog.object.extend(proto, distance_v1_distance_pb);
goog.exportSymbol('proto.swift.booking.v1.AcceptBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.AcceptBookingRequest.DataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.AcceptBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.AcceptDirectSupplier', null, global);
goog.exportSymbol('proto.swift.booking.v1.AcceptManagedSupplier', null, global);
goog.exportSymbol('proto.swift.booking.v1.Action', null, global);
goog.exportSymbol('proto.swift.booking.v1.Action.Type', null, global);
goog.exportSymbol('proto.swift.booking.v1.Booking', null, global);
goog.exportSymbol('proto.swift.booking.v1.Booking.ServiceSelection', null, global);
goog.exportSymbol('proto.swift.booking.v1.Booking.Source', null, global);
goog.exportSymbol('proto.swift.booking.v1.Booking.Status', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingEmissions', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingEmissionsPoint', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingEvent', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingFilter', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingItem', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingProgress', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingProgressPoint', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingSorting', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingSorting.Field', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingSorting.Ordering', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingStats', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingStats.DataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingStatsQuery', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingStatsQuery.Grouping', null, global);
goog.exportSymbol('proto.swift.booking.v1.BookingStatsQuery.Type', null, global);
goog.exportSymbol('proto.swift.booking.v1.BoxTrailer', null, global);
goog.exportSymbol('proto.swift.booking.v1.Colli', null, global);
goog.exportSymbol('proto.swift.booking.v1.Colli.Type', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreatePreAcceptedBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreatePreAcceptedBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.CustomsHandlingExport', null, global);
goog.exportSymbol('proto.swift.booking.v1.CustomsHandlingImport', null, global);
goog.exportSymbol('proto.swift.booking.v1.DangerousGoods', null, global);
goog.exportSymbol('proto.swift.booking.v1.DangerousGoods.Class', null, global);
goog.exportSymbol('proto.swift.booking.v1.DateFilter', null, global);
goog.exportSymbol('proto.swift.booking.v1.DateFilter.Field', null, global);
goog.exportSymbol('proto.swift.booking.v1.DateFilter.Relative', null, global);
goog.exportSymbol('proto.swift.booking.v1.DateFilter.ValueCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Dimension', null, global);
goog.exportSymbol('proto.swift.booking.v1.DryLoad', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ExtraLoading', null, global);
goog.exportSymbol('proto.swift.booking.v1.ExtraUnloading', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingChargeableWeightRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingChargeableWeightResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingEventsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingEventsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingStatsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingStatsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetQuotesRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetQuotesResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.HangingGarments', null, global);
goog.exportSymbol('proto.swift.booking.v1.InquireBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.InquireBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListBookingsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListBookingsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.LoadingNote', null, global);
goog.exportSymbol('proto.swift.booking.v1.OtherRequirement', null, global);
goog.exportSymbol('proto.swift.booking.v1.QuoteBookingRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.QuoteBookingResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ReceiverNotification', null, global);
goog.exportSymbol('proto.swift.booking.v1.Requirement', null, global);
goog.exportSymbol('proto.swift.booking.v1.Requirement.DataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.Requirement.Type', null, global);
goog.exportSymbol('proto.swift.booking.v1.SenderNotification', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetActionRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetActionResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetBookingPriceRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetBookingPriceResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetFinancialMonthRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetFinancialMonthResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetShipmentRefRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetShipmentRefResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SubmodeItem', null, global);
goog.exportSymbol('proto.swift.booking.v1.SubmodeItem.ContainerType', null, global);
goog.exportSymbol('proto.swift.booking.v1.SubmodeItem.SpaceUnit', null, global);
goog.exportSymbol('proto.swift.booking.v1.TailLiftAtLoading', null, global);
goog.exportSymbol('proto.swift.booking.v1.TailLiftAtUnloading', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemperatureControlled', null, global);
goog.exportSymbol('proto.swift.booking.v1.UnloadingNote', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateBookingItemsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateBookingItemsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateEstimatesRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateEstimatesResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateInternalNoteRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateInternalNoteResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateRequirementsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateRequirementsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateStatusRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateStatusResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportModeRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportModeResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportOperatorRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportOperatorResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportRefRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.UpdateTransportRefResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Volume', null, global);
goog.exportSymbol('proto.swift.booking.v1.Volume.Unit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Booking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.Booking.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.Booking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Booking.displayName = 'proto.swift.booking.v1.Booking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Requirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.booking.v1.Requirement.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.Requirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Requirement.displayName = 'proto.swift.booking.v1.Requirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DangerousGoods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DangerousGoods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DangerousGoods.displayName = 'proto.swift.booking.v1.DangerousGoods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Action.displayName = 'proto.swift.booking.v1.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TemperatureControlled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TemperatureControlled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TemperatureControlled.displayName = 'proto.swift.booking.v1.TemperatureControlled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DryLoad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DryLoad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DryLoad.displayName = 'proto.swift.booking.v1.DryLoad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.HangingGarments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.HangingGarments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.HangingGarments.displayName = 'proto.swift.booking.v1.HangingGarments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BoxTrailer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BoxTrailer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BoxTrailer.displayName = 'proto.swift.booking.v1.BoxTrailer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TailLiftAtLoading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TailLiftAtLoading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TailLiftAtLoading.displayName = 'proto.swift.booking.v1.TailLiftAtLoading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TailLiftAtUnloading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TailLiftAtUnloading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TailLiftAtUnloading.displayName = 'proto.swift.booking.v1.TailLiftAtUnloading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SenderNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SenderNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SenderNotification.displayName = 'proto.swift.booking.v1.SenderNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ReceiverNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ReceiverNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ReceiverNotification.displayName = 'proto.swift.booking.v1.ReceiverNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.LoadingNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.LoadingNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.LoadingNote.displayName = 'proto.swift.booking.v1.LoadingNote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UnloadingNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UnloadingNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UnloadingNote.displayName = 'proto.swift.booking.v1.UnloadingNote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ExtraLoading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ExtraLoading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ExtraLoading.displayName = 'proto.swift.booking.v1.ExtraLoading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ExtraUnloading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ExtraUnloading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ExtraUnloading.displayName = 'proto.swift.booking.v1.ExtraUnloading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CustomsHandlingImport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CustomsHandlingImport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CustomsHandlingImport.displayName = 'proto.swift.booking.v1.CustomsHandlingImport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CustomsHandlingExport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CustomsHandlingExport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CustomsHandlingExport.displayName = 'proto.swift.booking.v1.CustomsHandlingExport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.OtherRequirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.OtherRequirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.OtherRequirement.displayName = 'proto.swift.booking.v1.OtherRequirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SubmodeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.SubmodeItem.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.SubmodeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SubmodeItem.displayName = 'proto.swift.booking.v1.SubmodeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Colli = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.Colli, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Colli.displayName = 'proto.swift.booking.v1.Colli';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Volume.displayName = 'proto.swift.booking.v1.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Dimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.Dimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Dimension.displayName = 'proto.swift.booking.v1.Dimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingItem.displayName = 'proto.swift.booking.v1.BookingItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingEvent.displayName = 'proto.swift.booking.v1.BookingEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingStatsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingStatsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingStatsQuery.displayName = 'proto.swift.booking.v1.BookingStatsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.booking.v1.BookingStats.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.BookingStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingStats.displayName = 'proto.swift.booking.v1.BookingStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.BookingProgress.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.BookingProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingProgress.displayName = 'proto.swift.booking.v1.BookingProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingProgressPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingProgressPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingProgressPoint.displayName = 'proto.swift.booking.v1.BookingProgressPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingEmissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.BookingEmissions.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.BookingEmissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingEmissions.displayName = 'proto.swift.booking.v1.BookingEmissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingEmissionsPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingEmissionsPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingEmissionsPoint.displayName = 'proto.swift.booking.v1.BookingEmissionsPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.BookingFilter.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.BookingFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingFilter.displayName = 'proto.swift.booking.v1.BookingFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.booking.v1.DateFilter.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.DateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DateFilter.displayName = 'proto.swift.booking.v1.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BookingSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingSorting.displayName = 'proto.swift.booking.v1.BookingSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.AcceptDirectSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.AcceptDirectSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.AcceptDirectSupplier.displayName = 'proto.swift.booking.v1.AcceptDirectSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.AcceptManagedSupplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.AcceptManagedSupplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.AcceptManagedSupplier.displayName = 'proto.swift.booking.v1.AcceptManagedSupplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListBookingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListBookingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListBookingsRequest.displayName = 'proto.swift.booking.v1.ListBookingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListBookingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListBookingsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListBookingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListBookingsResponse.displayName = 'proto.swift.booking.v1.ListBookingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingRequest.displayName = 'proto.swift.booking.v1.GetBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingResponse.displayName = 'proto.swift.booking.v1.GetBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingEventsRequest.displayName = 'proto.swift.booking.v1.GetBookingEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.GetBookingEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingEventsResponse.displayName = 'proto.swift.booking.v1.GetBookingEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingStatsRequest.displayName = 'proto.swift.booking.v1.GetBookingStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingStatsResponse.displayName = 'proto.swift.booking.v1.GetBookingStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateBookingRequest.displayName = 'proto.swift.booking.v1.CreateBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.CreateBookingResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.CreateBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateBookingResponse.displayName = 'proto.swift.booking.v1.CreateBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreatePreAcceptedBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreatePreAcceptedBookingRequest.displayName = 'proto.swift.booking.v1.CreatePreAcceptedBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreatePreAcceptedBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreatePreAcceptedBookingResponse.displayName = 'proto.swift.booking.v1.CreatePreAcceptedBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditBookingRequest.displayName = 'proto.swift.booking.v1.EditBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditBookingResponse.displayName = 'proto.swift.booking.v1.EditBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.QuoteBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.QuoteBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.QuoteBookingRequest.displayName = 'proto.swift.booking.v1.QuoteBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.QuoteBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.QuoteBookingResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.QuoteBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.QuoteBookingResponse.displayName = 'proto.swift.booking.v1.QuoteBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetQuotesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetQuotesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetQuotesRequest.displayName = 'proto.swift.booking.v1.GetQuotesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetQuotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.GetQuotesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.GetQuotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetQuotesResponse.displayName = 'proto.swift.booking.v1.GetQuotesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.AcceptBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.swift.booking.v1.AcceptBookingRequest.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.AcceptBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.AcceptBookingRequest.displayName = 'proto.swift.booking.v1.AcceptBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.AcceptBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.AcceptBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.AcceptBookingResponse.displayName = 'proto.swift.booking.v1.AcceptBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportRefRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportRefRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportRefRequest.displayName = 'proto.swift.booking.v1.UpdateTransportRefRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportRefResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportRefResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportRefResponse.displayName = 'proto.swift.booking.v1.UpdateTransportRefResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateEstimatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateEstimatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateEstimatesRequest.displayName = 'proto.swift.booking.v1.UpdateEstimatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateEstimatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateEstimatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateEstimatesResponse.displayName = 'proto.swift.booking.v1.UpdateEstimatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportModeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportModeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportModeRequest.displayName = 'proto.swift.booking.v1.UpdateTransportModeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportModeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportModeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportModeResponse.displayName = 'proto.swift.booking.v1.UpdateTransportModeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.UpdateStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.UpdateStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateStatusRequest.displayName = 'proto.swift.booking.v1.UpdateStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateStatusResponse.displayName = 'proto.swift.booking.v1.UpdateStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateBookingItemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateBookingItemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateBookingItemsRequest.displayName = 'proto.swift.booking.v1.UpdateBookingItemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateBookingItemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateBookingItemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateBookingItemsResponse.displayName = 'proto.swift.booking.v1.UpdateBookingItemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateRequirementsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.UpdateRequirementsRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.UpdateRequirementsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateRequirementsRequest.displayName = 'proto.swift.booking.v1.UpdateRequirementsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateRequirementsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateRequirementsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateRequirementsResponse.displayName = 'proto.swift.booking.v1.UpdateRequirementsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateInternalNoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateInternalNoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateInternalNoteRequest.displayName = 'proto.swift.booking.v1.UpdateInternalNoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateInternalNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateInternalNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateInternalNoteResponse.displayName = 'proto.swift.booking.v1.UpdateInternalNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteBookingRequest.displayName = 'proto.swift.booking.v1.DeleteBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteBookingResponse.displayName = 'proto.swift.booking.v1.DeleteBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetShipmentRefRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.SetShipmentRefRequest.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.SetShipmentRefRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetShipmentRefRequest.displayName = 'proto.swift.booking.v1.SetShipmentRefRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetShipmentRefResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetShipmentRefResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetShipmentRefResponse.displayName = 'proto.swift.booking.v1.SetShipmentRefResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetBookingPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetBookingPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetBookingPriceRequest.displayName = 'proto.swift.booking.v1.SetBookingPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetBookingPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetBookingPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetBookingPriceResponse.displayName = 'proto.swift.booking.v1.SetBookingPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportOperatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportOperatorRequest.displayName = 'proto.swift.booking.v1.UpdateTransportOperatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UpdateTransportOperatorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UpdateTransportOperatorResponse.displayName = 'proto.swift.booking.v1.UpdateTransportOperatorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetActionRequest.displayName = 'proto.swift.booking.v1.SetActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetActionResponse.displayName = 'proto.swift.booking.v1.SetActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingChargeableWeightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingChargeableWeightRequest.displayName = 'proto.swift.booking.v1.GetBookingChargeableWeightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingChargeableWeightResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingChargeableWeightResponse.displayName = 'proto.swift.booking.v1.GetBookingChargeableWeightResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.InquireBookingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.InquireBookingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.InquireBookingRequest.displayName = 'proto.swift.booking.v1.InquireBookingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.InquireBookingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.InquireBookingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.InquireBookingResponse.displayName = 'proto.swift.booking.v1.InquireBookingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetFinancialMonthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetFinancialMonthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetFinancialMonthRequest.displayName = 'proto.swift.booking.v1.SetFinancialMonthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetFinancialMonthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetFinancialMonthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetFinancialMonthResponse.displayName = 'proto.swift.booking.v1.SetFinancialMonthResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.Booking.repeatedFields_ = [4,14,15,57];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Booking.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Booking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Booking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Booking.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
bookingRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
orderRefsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
shipmentRef: jspb.Message.getFieldWithDefault(msg, 5, ""),
nominationId: jspb.Message.getFieldWithDefault(msg, 6, 0),
bookingTemplateId: jspb.Message.getFieldWithDefault(msg, 7, 0),
sender: (f = msg.getSender()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
receiver: (f = msg.getReceiver()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
desiredPickupDate: (f = msg.getDesiredPickupDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
desiredDeliveryDate: (f = msg.getDesiredDeliveryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
desiredLatestPickupTime: (f = msg.getDesiredLatestPickupTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
desiredLatestDeliveryTime: (f = msg.getDesiredLatestDeliveryTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
desiredTransportModesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
requirementsList: jspb.Message.toObjectList(msg.getRequirementsList(),
    proto.swift.booking.v1.Requirement.toObject, includeInstance),
attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
invoiceRef: jspb.Message.getFieldWithDefault(msg, 17, ""),
loadingRef: jspb.Message.getFieldWithDefault(msg, 18, ""),
unloadingRef: jspb.Message.getFieldWithDefault(msg, 19, ""),
externalRef: jspb.Message.getFieldWithDefault(msg, 20, ""),
directDistance: jspb.Message.getFieldWithDefault(msg, 21, 0),
serviceSelection: jspb.Message.getFieldWithDefault(msg, 22, 0),
transportRef: jspb.Message.getFieldWithDefault(msg, 23, ""),
nominatedSupplierType: jspb.Message.getFieldWithDefault(msg, 24, 0),
nominatedTransportMode: jspb.Message.getFieldWithDefault(msg, 25, 0),
status: jspb.Message.getFieldWithDefault(msg, 26, 0),
currentGeolocation: (f = msg.getCurrentGeolocation()) && common_geolocation_pb.Geolocation.toObject(includeInstance, f),
currentLocation: jspb.Message.getFieldWithDefault(msg, 28, ""),
estimatedTimeDeparture: (f = msg.getEstimatedTimeDeparture()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
estimatedTimeArrival: (f = msg.getEstimatedTimeArrival()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
actualTimeDeparture: (f = msg.getActualTimeDeparture()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
actualTimeArrival: (f = msg.getActualTimeArrival()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
exceptionTime: (f = msg.getExceptionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
internalNote: jspb.Message.getFieldWithDefault(msg, 34, ""),
internalNoteEditedAt: (f = msg.getInternalNoteEditedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
action: (f = msg.getAction()) && proto.swift.booking.v1.Action.toObject(includeInstance, f),
numMessages: jspb.Message.getFieldWithDefault(msg, 37, 0),
lastMessage: jspb.Message.getFieldWithDefault(msg, 38, ""),
lastMessageAt: (f = msg.getLastMessageAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
source: jspb.Message.getFieldWithDefault(msg, 40, 0),
sourceRefsMap: (f = msg.getSourceRefsMap()) ? f.toObject(includeInstance, undefined) : [],
sourceDesc: jspb.Message.getFieldWithDefault(msg, 42, ""),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
latestDescription: jspb.Message.getFieldWithDefault(msg, 45, ""),
latestDescriptionCreatedAt: (f = msg.getLatestDescriptionCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
uiConfigJson: jspb.Message.getFieldWithDefault(msg, 47, ""),
co2Emissions: jspb.Message.getFieldWithDefault(msg, 48, 0),
distance: jspb.Message.getFieldWithDefault(msg, 49, 0),
distanceUnit: jspb.Message.getFieldWithDefault(msg, 50, 0),
acceptor: (f = msg.getAcceptor()) && common_owner_pb.Owner.toObject(includeInstance, f),
acceptedAt: (f = msg.getAcceptedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
price: jspb.Message.getFieldWithDefault(msg, 53, 0),
priceCurrency: jspb.Message.getFieldWithDefault(msg, 54, ""),
contractRef: jspb.Message.getFieldWithDefault(msg, 55, ""),
transportSubmodeType: jspb.Message.getFieldWithDefault(msg, 56, 0),
transportSubmodeItemsList: jspb.Message.toObjectList(msg.getTransportSubmodeItemsList(),
    proto.swift.booking.v1.SubmodeItem.toObject, includeInstance),
autoInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 58, false),
financialMonth: (f = msg.getFinancialMonth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.Booking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Booking;
  return proto.swift.booking.v1.Booking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Booking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.Booking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrderRefs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNominationId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingTemplateId(value);
      break;
    case 8:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 9:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setReceiver(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDesiredPickupDate(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDesiredDeliveryDate(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDesiredLatestPickupTime(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDesiredLatestDeliveryTime(value);
      break;
    case 14:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDesiredTransportModes(values[i]);
      }
      break;
    case 15:
      var value = new proto.swift.booking.v1.Requirement;
      reader.readMessage(value,proto.swift.booking.v1.Requirement.deserializeBinaryFromReader);
      msg.addRequirements(value);
      break;
    case 16:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceRef(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoadingRef(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnloadingRef(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDirectDistance(value);
      break;
    case 22:
      var value = /** @type {!proto.swift.booking.v1.Booking.ServiceSelection} */ (reader.readEnum());
      msg.setServiceSelection(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportRef(value);
      break;
    case 24:
      var value = /** @type {!proto.swift.booking.v1.SupplierType} */ (reader.readEnum());
      msg.setNominatedSupplierType(value);
      break;
    case 25:
      var value = /** @type {!proto.swift.common.TransportMode} */ (reader.readEnum());
      msg.setNominatedTransportMode(value);
      break;
    case 26:
      var value = /** @type {!proto.swift.booking.v1.Booking.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 27:
      var value = new common_geolocation_pb.Geolocation;
      reader.readMessage(value,common_geolocation_pb.Geolocation.deserializeBinaryFromReader);
      msg.setCurrentGeolocation(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentLocation(value);
      break;
    case 29:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeDeparture(value);
      break;
    case 30:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeArrival(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualTimeDeparture(value);
      break;
    case 32:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActualTimeArrival(value);
      break;
    case 33:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExceptionTime(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 35:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setInternalNoteEditedAt(value);
      break;
    case 36:
      var value = new proto.swift.booking.v1.Action;
      reader.readMessage(value,proto.swift.booking.v1.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumMessages(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastMessage(value);
      break;
    case 39:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastMessageAt(value);
      break;
    case 40:
      var value = /** @type {!proto.swift.booking.v1.Booking.Source} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 41:
      var value = msg.getSourceRefsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDesc(value);
      break;
    case 43:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 44:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestDescription(value);
      break;
    case 46:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLatestDescriptionCreatedAt(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setUiConfigJson(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCo2Emissions(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistance(value);
      break;
    case 50:
      var value = /** @type {!proto.swift.distance.v1.DistanceUnit} */ (reader.readEnum());
      msg.setDistanceUnit(value);
      break;
    case 51:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setAcceptor(value);
      break;
    case 52:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAcceptedAt(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceCurrency(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractRef(value);
      break;
    case 56:
      var value = /** @type {!proto.swift.common.TransportSubmode} */ (reader.readEnum());
      msg.setTransportSubmodeType(value);
      break;
    case 57:
      var value = new proto.swift.booking.v1.SubmodeItem;
      reader.readMessage(value,proto.swift.booking.v1.SubmodeItem.deserializeBinaryFromReader);
      msg.addTransportSubmodeItems(value);
      break;
    case 58:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoInvoicing(value);
      break;
    case 59:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinancialMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Booking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Booking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Booking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Booking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNominationId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBookingTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getReceiver();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getDesiredPickupDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDesiredDeliveryDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDesiredLatestPickupTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDesiredLatestDeliveryTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDesiredTransportModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      14,
      f
    );
  }
  f = message.getRequirementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.swift.booking.v1.Requirement.serializeBinaryToWriter
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getInvoiceRef();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLoadingRef();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUnloadingRef();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDirectDistance();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getServiceSelection();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getTransportRef();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getNominatedSupplierType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getNominatedTransportMode();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getCurrentGeolocation();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      common_geolocation_pb.Geolocation.serializeBinaryToWriter
    );
  }
  f = message.getCurrentLocation();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getEstimatedTimeDeparture();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTimeArrival();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualTimeDeparture();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActualTimeArrival();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExceptionTime();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getInternalNoteEditedAt();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.swift.booking.v1.Action.serializeBinaryToWriter
    );
  }
  f = message.getNumMessages();
  if (f !== 0) {
    writer.writeInt64(
      37,
      f
    );
  }
  f = message.getLastMessage();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getLastMessageAt();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      40,
      f
    );
  }
  f = message.getSourceRefsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(41, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSourceDesc();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLatestDescription();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getLatestDescriptionCreatedAt();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUiConfigJson();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getCo2Emissions();
  if (f !== 0) {
    writer.writeInt64(
      48,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt64(
      49,
      f
    );
  }
  f = message.getDistanceUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getAcceptor();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getAcceptedAt();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      53,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getContractRef();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getTransportSubmodeType();
  if (f !== 0.0) {
    writer.writeEnum(
      56,
      f
    );
  }
  f = message.getTransportSubmodeItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      57,
      f,
      proto.swift.booking.v1.SubmodeItem.serializeBinaryToWriter
    );
  }
  f = message.getAutoInvoicing();
  if (f) {
    writer.writeBool(
      58,
      f
    );
  }
  f = message.getFinancialMonth();
  if (f != null) {
    writer.writeMessage(
      59,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Booking.Status = {
  NEW: 0,
  DRAFT: 1,
  QUOTE: 2,
  QUOTED: 3,
  BOOKED: 4,
  ACCEPTED: 5,
  IN_TRANSIT: 6,
  EXCEPTION: 7,
  DELIVERED: 8,
  CANCELLED: 9
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.Booking.Source = {
  INTERNAL: 0,
  INTEREAST: 1,
  CENTIRO: 2,
  INTEREAST_DRAFT: 3,
  UPS: 4,
  SUPPLYSTACK: 5
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.Booking.ServiceSelection = {
  UNSELECTED: 0,
  DIRECT: 1,
  MANAGED: 2
};

/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Booking.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string booking_ref = 3;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string order_refs = 4;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.Booking.prototype.getOrderRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setOrderRefsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.addOrderRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearOrderRefsList = function() {
  return this.setOrderRefsList([]);
};


/**
 * optional string shipment_ref = 5;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 nomination_id = 6;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getNominationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setNominationId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 booking_template_id = 7;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getBookingTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setBookingTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional swift.crm.v1.Address sender = 8;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.booking.v1.Booking.prototype.getSender = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 8));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasSender = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional swift.crm.v1.Address receiver = 9;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.booking.v1.Booking.prototype.getReceiver = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 9));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setReceiver = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearReceiver = function() {
  return this.setReceiver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasReceiver = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp desired_pickup_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getDesiredPickupDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setDesiredPickupDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearDesiredPickupDate = function() {
  return this.setDesiredPickupDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasDesiredPickupDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp desired_delivery_date = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getDesiredDeliveryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setDesiredDeliveryDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearDesiredDeliveryDate = function() {
  return this.setDesiredDeliveryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasDesiredDeliveryDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp desired_latest_pickup_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getDesiredLatestPickupTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setDesiredLatestPickupTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearDesiredLatestPickupTime = function() {
  return this.setDesiredLatestPickupTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasDesiredLatestPickupTime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp desired_latest_delivery_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getDesiredLatestDeliveryTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setDesiredLatestDeliveryTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearDesiredLatestDeliveryTime = function() {
  return this.setDesiredLatestDeliveryTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasDesiredLatestDeliveryTime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated swift.common.TransportMode desired_transport_modes = 14;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.booking.v1.Booking.prototype.getDesiredTransportModesList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setDesiredTransportModesList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.addDesiredTransportModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearDesiredTransportModesList = function() {
  return this.setDesiredTransportModesList([]);
};


/**
 * repeated Requirement requirements = 15;
 * @return {!Array<!proto.swift.booking.v1.Requirement>}
 */
proto.swift.booking.v1.Booking.prototype.getRequirementsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Requirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Requirement, 15));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Requirement>} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setRequirementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.swift.booking.v1.Requirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Requirement}
 */
proto.swift.booking.v1.Booking.prototype.addRequirements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.swift.booking.v1.Requirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearRequirementsList = function() {
  return this.setRequirementsList([]);
};


/**
 * map<string, string> attributes = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.swift.booking.v1.Booking.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;
};


/**
 * optional string invoice_ref = 17;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getInvoiceRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setInvoiceRef = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string loading_ref = 18;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getLoadingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setLoadingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string unloading_ref = 19;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getUnloadingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setUnloadingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string external_ref = 20;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 direct_distance = 21;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getDirectDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setDirectDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional ServiceSelection service_selection = 22;
 * @return {!proto.swift.booking.v1.Booking.ServiceSelection}
 */
proto.swift.booking.v1.Booking.prototype.getServiceSelection = function() {
  return /** @type {!proto.swift.booking.v1.Booking.ServiceSelection} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.swift.booking.v1.Booking.ServiceSelection} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setServiceSelection = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string transport_ref = 23;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getTransportRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setTransportRef = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional SupplierType nominated_supplier_type = 24;
 * @return {!proto.swift.booking.v1.SupplierType}
 */
proto.swift.booking.v1.Booking.prototype.getNominatedSupplierType = function() {
  return /** @type {!proto.swift.booking.v1.SupplierType} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.swift.booking.v1.SupplierType} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setNominatedSupplierType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional swift.common.TransportMode nominated_transport_mode = 25;
 * @return {!proto.swift.common.TransportMode}
 */
proto.swift.booking.v1.Booking.prototype.getNominatedTransportMode = function() {
  return /** @type {!proto.swift.common.TransportMode} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setNominatedTransportMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional Status status = 26;
 * @return {!proto.swift.booking.v1.Booking.Status}
 */
proto.swift.booking.v1.Booking.prototype.getStatus = function() {
  return /** @type {!proto.swift.booking.v1.Booking.Status} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.swift.booking.v1.Booking.Status} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional swift.common.Geolocation current_geolocation = 27;
 * @return {?proto.swift.common.Geolocation}
 */
proto.swift.booking.v1.Booking.prototype.getCurrentGeolocation = function() {
  return /** @type{?proto.swift.common.Geolocation} */ (
    jspb.Message.getWrapperField(this, common_geolocation_pb.Geolocation, 27));
};


/**
 * @param {?proto.swift.common.Geolocation|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setCurrentGeolocation = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearCurrentGeolocation = function() {
  return this.setCurrentGeolocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasCurrentGeolocation = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string current_location = 28;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getCurrentLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setCurrentLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional google.protobuf.Timestamp estimated_time_departure = 29;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getEstimatedTimeDeparture = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 29));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setEstimatedTimeDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearEstimatedTimeDeparture = function() {
  return this.setEstimatedTimeDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasEstimatedTimeDeparture = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Timestamp estimated_time_arrival = 30;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getEstimatedTimeArrival = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 30));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setEstimatedTimeArrival = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearEstimatedTimeArrival = function() {
  return this.setEstimatedTimeArrival(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasEstimatedTimeArrival = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.Timestamp actual_time_departure = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getActualTimeDeparture = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setActualTimeDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearActualTimeDeparture = function() {
  return this.setActualTimeDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasActualTimeDeparture = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Timestamp actual_time_arrival = 32;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getActualTimeArrival = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 32));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setActualTimeArrival = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearActualTimeArrival = function() {
  return this.setActualTimeArrival(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasActualTimeArrival = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional google.protobuf.Timestamp exception_time = 33;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getExceptionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 33));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setExceptionTime = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearExceptionTime = function() {
  return this.setExceptionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasExceptionTime = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional string internal_note = 34;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional google.protobuf.Timestamp internal_note_edited_at = 35;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getInternalNoteEditedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 35));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setInternalNoteEditedAt = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearInternalNoteEditedAt = function() {
  return this.setInternalNoteEditedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasInternalNoteEditedAt = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional Action action = 36;
 * @return {?proto.swift.booking.v1.Action}
 */
proto.swift.booking.v1.Booking.prototype.getAction = function() {
  return /** @type{?proto.swift.booking.v1.Action} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Action, 36));
};


/**
 * @param {?proto.swift.booking.v1.Action|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasAction = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional int64 num_messages = 37;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getNumMessages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setNumMessages = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional string last_message = 38;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getLastMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setLastMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional google.protobuf.Timestamp last_message_at = 39;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getLastMessageAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 39));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setLastMessageAt = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearLastMessageAt = function() {
  return this.setLastMessageAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasLastMessageAt = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional Source source = 40;
 * @return {!proto.swift.booking.v1.Booking.Source}
 */
proto.swift.booking.v1.Booking.prototype.getSource = function() {
  return /** @type {!proto.swift.booking.v1.Booking.Source} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {!proto.swift.booking.v1.Booking.Source} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 40, value);
};


/**
 * map<string, string> source_refs = 41;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.swift.booking.v1.Booking.prototype.getSourceRefsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 41, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearSourceRefsMap = function() {
  this.getSourceRefsMap().clear();
  return this;
};


/**
 * optional string source_desc = 42;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getSourceDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setSourceDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 43;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 43));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 44;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 44));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional string latest_description = 45;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getLatestDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setLatestDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional google.protobuf.Timestamp latest_description_created_at = 46;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getLatestDescriptionCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 46));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setLatestDescriptionCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearLatestDescriptionCreatedAt = function() {
  return this.setLatestDescriptionCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasLatestDescriptionCreatedAt = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional string ui_config_json = 47;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getUiConfigJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setUiConfigJson = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional int64 co2_emissions = 48;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getCo2Emissions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setCo2Emissions = function(value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};


/**
 * optional int64 distance = 49;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 49, value);
};


/**
 * optional swift.distance.v1.DistanceUnit distance_unit = 50;
 * @return {!proto.swift.distance.v1.DistanceUnit}
 */
proto.swift.booking.v1.Booking.prototype.getDistanceUnit = function() {
  return /** @type {!proto.swift.distance.v1.DistanceUnit} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.swift.distance.v1.DistanceUnit} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setDistanceUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional swift.common.Owner acceptor = 51;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Booking.prototype.getAcceptor = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 51));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setAcceptor = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearAcceptor = function() {
  return this.setAcceptor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasAcceptor = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional google.protobuf.Timestamp accepted_at = 52;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getAcceptedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 52));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setAcceptedAt = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearAcceptedAt = function() {
  return this.setAcceptedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasAcceptedAt = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional int64 price = 53;
 * @return {number}
 */
proto.swift.booking.v1.Booking.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 53, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 53, value);
};


/**
 * optional string price_currency = 54;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getPriceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string contract_ref = 55;
 * @return {string}
 */
proto.swift.booking.v1.Booking.prototype.getContractRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setContractRef = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional swift.common.TransportSubmode transport_submode_type = 56;
 * @return {!proto.swift.common.TransportSubmode}
 */
proto.swift.booking.v1.Booking.prototype.getTransportSubmodeType = function() {
  return /** @type {!proto.swift.common.TransportSubmode} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {!proto.swift.common.TransportSubmode} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setTransportSubmodeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 56, value);
};


/**
 * repeated SubmodeItem transport_submode_items = 57;
 * @return {!Array<!proto.swift.booking.v1.SubmodeItem>}
 */
proto.swift.booking.v1.Booking.prototype.getTransportSubmodeItemsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.SubmodeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.SubmodeItem, 57));
};


/**
 * @param {!Array<!proto.swift.booking.v1.SubmodeItem>} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setTransportSubmodeItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 57, value);
};


/**
 * @param {!proto.swift.booking.v1.SubmodeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SubmodeItem}
 */
proto.swift.booking.v1.Booking.prototype.addTransportSubmodeItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 57, opt_value, proto.swift.booking.v1.SubmodeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearTransportSubmodeItemsList = function() {
  return this.setTransportSubmodeItemsList([]);
};


/**
 * optional bool auto_invoicing = 58;
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.getAutoInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 58, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.setAutoInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 58, value);
};


/**
 * optional google.protobuf.Timestamp financial_month = 59;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Booking.prototype.getFinancialMonth = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 59));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Booking} returns this
*/
proto.swift.booking.v1.Booking.prototype.setFinancialMonth = function(value) {
  return jspb.Message.setWrapperField(this, 59, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Booking} returns this
 */
proto.swift.booking.v1.Booking.prototype.clearFinancialMonth = function() {
  return this.setFinancialMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Booking.prototype.hasFinancialMonth = function() {
  return jspb.Message.getField(this, 59) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.Requirement.oneofGroups_ = [[2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.Requirement.DataCase = {
  DATA_NOT_SET: 0,
  DANGEROUS_GOODS: 2,
  TEMPERATURE_CONTROLLED: 3,
  DRY_LOAD: 4,
  HANGING_GARMENTS: 5,
  BOX_TRAILER: 6,
  TAIL_LIFT_AT_LOADING: 7,
  TAIL_LIFT_AT_UNLOADING: 8,
  SENDER_NOTIFICATION: 9,
  RECEIVER_NOTIFICATION: 10,
  LOADING_NOTE: 11,
  UNLOADING_NOTE: 12,
  EXTRA_LOADING: 13,
  EXTRA_UNLOADING: 14,
  CUSTOMS_HANDLING_IMPORT: 15,
  CUSTOMS_HANDLING_EXPORT: 16,
  OTHER_REQUIREMENT: 17
};

/**
 * @return {proto.swift.booking.v1.Requirement.DataCase}
 */
proto.swift.booking.v1.Requirement.prototype.getDataCase = function() {
  return /** @type {proto.swift.booking.v1.Requirement.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.Requirement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Requirement.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Requirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Requirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Requirement.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
dangerousGoods: (f = msg.getDangerousGoods()) && proto.swift.booking.v1.DangerousGoods.toObject(includeInstance, f),
temperatureControlled: (f = msg.getTemperatureControlled()) && proto.swift.booking.v1.TemperatureControlled.toObject(includeInstance, f),
dryLoad: (f = msg.getDryLoad()) && proto.swift.booking.v1.DryLoad.toObject(includeInstance, f),
hangingGarments: (f = msg.getHangingGarments()) && proto.swift.booking.v1.HangingGarments.toObject(includeInstance, f),
boxTrailer: (f = msg.getBoxTrailer()) && proto.swift.booking.v1.BoxTrailer.toObject(includeInstance, f),
tailLiftAtLoading: (f = msg.getTailLiftAtLoading()) && proto.swift.booking.v1.TailLiftAtLoading.toObject(includeInstance, f),
tailLiftAtUnloading: (f = msg.getTailLiftAtUnloading()) && proto.swift.booking.v1.TailLiftAtUnloading.toObject(includeInstance, f),
senderNotification: (f = msg.getSenderNotification()) && proto.swift.booking.v1.SenderNotification.toObject(includeInstance, f),
receiverNotification: (f = msg.getReceiverNotification()) && proto.swift.booking.v1.ReceiverNotification.toObject(includeInstance, f),
loadingNote: (f = msg.getLoadingNote()) && proto.swift.booking.v1.LoadingNote.toObject(includeInstance, f),
unloadingNote: (f = msg.getUnloadingNote()) && proto.swift.booking.v1.UnloadingNote.toObject(includeInstance, f),
extraLoading: (f = msg.getExtraLoading()) && proto.swift.booking.v1.ExtraLoading.toObject(includeInstance, f),
extraUnloading: (f = msg.getExtraUnloading()) && proto.swift.booking.v1.ExtraUnloading.toObject(includeInstance, f),
customsHandlingImport: (f = msg.getCustomsHandlingImport()) && proto.swift.booking.v1.CustomsHandlingImport.toObject(includeInstance, f),
customsHandlingExport: (f = msg.getCustomsHandlingExport()) && proto.swift.booking.v1.CustomsHandlingExport.toObject(includeInstance, f),
otherRequirement: (f = msg.getOtherRequirement()) && proto.swift.booking.v1.OtherRequirement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Requirement}
 */
proto.swift.booking.v1.Requirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Requirement;
  return proto.swift.booking.v1.Requirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Requirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Requirement}
 */
proto.swift.booking.v1.Requirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.Requirement.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.DangerousGoods;
      reader.readMessage(value,proto.swift.booking.v1.DangerousGoods.deserializeBinaryFromReader);
      msg.setDangerousGoods(value);
      break;
    case 3:
      var value = new proto.swift.booking.v1.TemperatureControlled;
      reader.readMessage(value,proto.swift.booking.v1.TemperatureControlled.deserializeBinaryFromReader);
      msg.setTemperatureControlled(value);
      break;
    case 4:
      var value = new proto.swift.booking.v1.DryLoad;
      reader.readMessage(value,proto.swift.booking.v1.DryLoad.deserializeBinaryFromReader);
      msg.setDryLoad(value);
      break;
    case 5:
      var value = new proto.swift.booking.v1.HangingGarments;
      reader.readMessage(value,proto.swift.booking.v1.HangingGarments.deserializeBinaryFromReader);
      msg.setHangingGarments(value);
      break;
    case 6:
      var value = new proto.swift.booking.v1.BoxTrailer;
      reader.readMessage(value,proto.swift.booking.v1.BoxTrailer.deserializeBinaryFromReader);
      msg.setBoxTrailer(value);
      break;
    case 7:
      var value = new proto.swift.booking.v1.TailLiftAtLoading;
      reader.readMessage(value,proto.swift.booking.v1.TailLiftAtLoading.deserializeBinaryFromReader);
      msg.setTailLiftAtLoading(value);
      break;
    case 8:
      var value = new proto.swift.booking.v1.TailLiftAtUnloading;
      reader.readMessage(value,proto.swift.booking.v1.TailLiftAtUnloading.deserializeBinaryFromReader);
      msg.setTailLiftAtUnloading(value);
      break;
    case 9:
      var value = new proto.swift.booking.v1.SenderNotification;
      reader.readMessage(value,proto.swift.booking.v1.SenderNotification.deserializeBinaryFromReader);
      msg.setSenderNotification(value);
      break;
    case 10:
      var value = new proto.swift.booking.v1.ReceiverNotification;
      reader.readMessage(value,proto.swift.booking.v1.ReceiverNotification.deserializeBinaryFromReader);
      msg.setReceiverNotification(value);
      break;
    case 11:
      var value = new proto.swift.booking.v1.LoadingNote;
      reader.readMessage(value,proto.swift.booking.v1.LoadingNote.deserializeBinaryFromReader);
      msg.setLoadingNote(value);
      break;
    case 12:
      var value = new proto.swift.booking.v1.UnloadingNote;
      reader.readMessage(value,proto.swift.booking.v1.UnloadingNote.deserializeBinaryFromReader);
      msg.setUnloadingNote(value);
      break;
    case 13:
      var value = new proto.swift.booking.v1.ExtraLoading;
      reader.readMessage(value,proto.swift.booking.v1.ExtraLoading.deserializeBinaryFromReader);
      msg.setExtraLoading(value);
      break;
    case 14:
      var value = new proto.swift.booking.v1.ExtraUnloading;
      reader.readMessage(value,proto.swift.booking.v1.ExtraUnloading.deserializeBinaryFromReader);
      msg.setExtraUnloading(value);
      break;
    case 15:
      var value = new proto.swift.booking.v1.CustomsHandlingImport;
      reader.readMessage(value,proto.swift.booking.v1.CustomsHandlingImport.deserializeBinaryFromReader);
      msg.setCustomsHandlingImport(value);
      break;
    case 16:
      var value = new proto.swift.booking.v1.CustomsHandlingExport;
      reader.readMessage(value,proto.swift.booking.v1.CustomsHandlingExport.deserializeBinaryFromReader);
      msg.setCustomsHandlingExport(value);
      break;
    case 17:
      var value = new proto.swift.booking.v1.OtherRequirement;
      reader.readMessage(value,proto.swift.booking.v1.OtherRequirement.deserializeBinaryFromReader);
      msg.setOtherRequirement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Requirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Requirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Requirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Requirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDangerousGoods();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.DangerousGoods.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureControlled();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.booking.v1.TemperatureControlled.serializeBinaryToWriter
    );
  }
  f = message.getDryLoad();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.booking.v1.DryLoad.serializeBinaryToWriter
    );
  }
  f = message.getHangingGarments();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.booking.v1.HangingGarments.serializeBinaryToWriter
    );
  }
  f = message.getBoxTrailer();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.swift.booking.v1.BoxTrailer.serializeBinaryToWriter
    );
  }
  f = message.getTailLiftAtLoading();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.booking.v1.TailLiftAtLoading.serializeBinaryToWriter
    );
  }
  f = message.getTailLiftAtUnloading();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.swift.booking.v1.TailLiftAtUnloading.serializeBinaryToWriter
    );
  }
  f = message.getSenderNotification();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.booking.v1.SenderNotification.serializeBinaryToWriter
    );
  }
  f = message.getReceiverNotification();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.swift.booking.v1.ReceiverNotification.serializeBinaryToWriter
    );
  }
  f = message.getLoadingNote();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.booking.v1.LoadingNote.serializeBinaryToWriter
    );
  }
  f = message.getUnloadingNote();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.booking.v1.UnloadingNote.serializeBinaryToWriter
    );
  }
  f = message.getExtraLoading();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.swift.booking.v1.ExtraLoading.serializeBinaryToWriter
    );
  }
  f = message.getExtraUnloading();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.swift.booking.v1.ExtraUnloading.serializeBinaryToWriter
    );
  }
  f = message.getCustomsHandlingImport();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.swift.booking.v1.CustomsHandlingImport.serializeBinaryToWriter
    );
  }
  f = message.getCustomsHandlingExport();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.swift.booking.v1.CustomsHandlingExport.serializeBinaryToWriter
    );
  }
  f = message.getOtherRequirement();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.swift.booking.v1.OtherRequirement.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Requirement.Type = {
  DANGEROUS_GOODS: 0,
  TEMPERATURE_CONTROLLED: 1,
  DRY_LOAD: 2,
  HANGING_GARMENTS: 3,
  BOX_TRAILER: 4,
  TAIL_LIFT_AT_LOADING: 5,
  TAIL_LIFT_AT_UNLOADING: 6,
  SENDER_NOTIFICATION: 7,
  RECEIVER_NOTIFICATION: 8,
  LOADING_NOTE: 9,
  UNLOADING_NOTE: 10,
  EXTRA_LOADING: 11,
  EXTRA_UNLOADING: 12,
  CUSTOMS_HANDLING_IMPORT: 13,
  CUSTOMS_HANDLING_EXPORT: 14,
  OTHER: 15
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.booking.v1.Requirement.Type}
 */
proto.swift.booking.v1.Requirement.prototype.getType = function() {
  return /** @type {!proto.swift.booking.v1.Requirement.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.Requirement.Type} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DangerousGoods dangerous_goods = 2;
 * @return {?proto.swift.booking.v1.DangerousGoods}
 */
proto.swift.booking.v1.Requirement.prototype.getDangerousGoods = function() {
  return /** @type{?proto.swift.booking.v1.DangerousGoods} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DangerousGoods, 2));
};


/**
 * @param {?proto.swift.booking.v1.DangerousGoods|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setDangerousGoods = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearDangerousGoods = function() {
  return this.setDangerousGoods(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasDangerousGoods = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TemperatureControlled temperature_controlled = 3;
 * @return {?proto.swift.booking.v1.TemperatureControlled}
 */
proto.swift.booking.v1.Requirement.prototype.getTemperatureControlled = function() {
  return /** @type{?proto.swift.booking.v1.TemperatureControlled} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TemperatureControlled, 3));
};


/**
 * @param {?proto.swift.booking.v1.TemperatureControlled|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setTemperatureControlled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearTemperatureControlled = function() {
  return this.setTemperatureControlled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasTemperatureControlled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DryLoad dry_load = 4;
 * @return {?proto.swift.booking.v1.DryLoad}
 */
proto.swift.booking.v1.Requirement.prototype.getDryLoad = function() {
  return /** @type{?proto.swift.booking.v1.DryLoad} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DryLoad, 4));
};


/**
 * @param {?proto.swift.booking.v1.DryLoad|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setDryLoad = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearDryLoad = function() {
  return this.setDryLoad(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasDryLoad = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HangingGarments hanging_garments = 5;
 * @return {?proto.swift.booking.v1.HangingGarments}
 */
proto.swift.booking.v1.Requirement.prototype.getHangingGarments = function() {
  return /** @type{?proto.swift.booking.v1.HangingGarments} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.HangingGarments, 5));
};


/**
 * @param {?proto.swift.booking.v1.HangingGarments|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setHangingGarments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearHangingGarments = function() {
  return this.setHangingGarments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasHangingGarments = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BoxTrailer box_trailer = 6;
 * @return {?proto.swift.booking.v1.BoxTrailer}
 */
proto.swift.booking.v1.Requirement.prototype.getBoxTrailer = function() {
  return /** @type{?proto.swift.booking.v1.BoxTrailer} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BoxTrailer, 6));
};


/**
 * @param {?proto.swift.booking.v1.BoxTrailer|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setBoxTrailer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearBoxTrailer = function() {
  return this.setBoxTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasBoxTrailer = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TailLiftAtLoading tail_lift_at_loading = 7;
 * @return {?proto.swift.booking.v1.TailLiftAtLoading}
 */
proto.swift.booking.v1.Requirement.prototype.getTailLiftAtLoading = function() {
  return /** @type{?proto.swift.booking.v1.TailLiftAtLoading} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TailLiftAtLoading, 7));
};


/**
 * @param {?proto.swift.booking.v1.TailLiftAtLoading|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setTailLiftAtLoading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearTailLiftAtLoading = function() {
  return this.setTailLiftAtLoading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasTailLiftAtLoading = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TailLiftAtUnloading tail_lift_at_unloading = 8;
 * @return {?proto.swift.booking.v1.TailLiftAtUnloading}
 */
proto.swift.booking.v1.Requirement.prototype.getTailLiftAtUnloading = function() {
  return /** @type{?proto.swift.booking.v1.TailLiftAtUnloading} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TailLiftAtUnloading, 8));
};


/**
 * @param {?proto.swift.booking.v1.TailLiftAtUnloading|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setTailLiftAtUnloading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearTailLiftAtUnloading = function() {
  return this.setTailLiftAtUnloading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasTailLiftAtUnloading = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SenderNotification sender_notification = 9;
 * @return {?proto.swift.booking.v1.SenderNotification}
 */
proto.swift.booking.v1.Requirement.prototype.getSenderNotification = function() {
  return /** @type{?proto.swift.booking.v1.SenderNotification} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SenderNotification, 9));
};


/**
 * @param {?proto.swift.booking.v1.SenderNotification|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setSenderNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearSenderNotification = function() {
  return this.setSenderNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasSenderNotification = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ReceiverNotification receiver_notification = 10;
 * @return {?proto.swift.booking.v1.ReceiverNotification}
 */
proto.swift.booking.v1.Requirement.prototype.getReceiverNotification = function() {
  return /** @type{?proto.swift.booking.v1.ReceiverNotification} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.ReceiverNotification, 10));
};


/**
 * @param {?proto.swift.booking.v1.ReceiverNotification|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setReceiverNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearReceiverNotification = function() {
  return this.setReceiverNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasReceiverNotification = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional LoadingNote loading_note = 11;
 * @return {?proto.swift.booking.v1.LoadingNote}
 */
proto.swift.booking.v1.Requirement.prototype.getLoadingNote = function() {
  return /** @type{?proto.swift.booking.v1.LoadingNote} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.LoadingNote, 11));
};


/**
 * @param {?proto.swift.booking.v1.LoadingNote|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setLoadingNote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearLoadingNote = function() {
  return this.setLoadingNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasLoadingNote = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UnloadingNote unloading_note = 12;
 * @return {?proto.swift.booking.v1.UnloadingNote}
 */
proto.swift.booking.v1.Requirement.prototype.getUnloadingNote = function() {
  return /** @type{?proto.swift.booking.v1.UnloadingNote} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.UnloadingNote, 12));
};


/**
 * @param {?proto.swift.booking.v1.UnloadingNote|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setUnloadingNote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearUnloadingNote = function() {
  return this.setUnloadingNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasUnloadingNote = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ExtraLoading extra_loading = 13;
 * @return {?proto.swift.booking.v1.ExtraLoading}
 */
proto.swift.booking.v1.Requirement.prototype.getExtraLoading = function() {
  return /** @type{?proto.swift.booking.v1.ExtraLoading} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.ExtraLoading, 13));
};


/**
 * @param {?proto.swift.booking.v1.ExtraLoading|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setExtraLoading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearExtraLoading = function() {
  return this.setExtraLoading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasExtraLoading = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ExtraUnloading extra_unloading = 14;
 * @return {?proto.swift.booking.v1.ExtraUnloading}
 */
proto.swift.booking.v1.Requirement.prototype.getExtraUnloading = function() {
  return /** @type{?proto.swift.booking.v1.ExtraUnloading} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.ExtraUnloading, 14));
};


/**
 * @param {?proto.swift.booking.v1.ExtraUnloading|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setExtraUnloading = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearExtraUnloading = function() {
  return this.setExtraUnloading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasExtraUnloading = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional CustomsHandlingImport customs_handling_import = 15;
 * @return {?proto.swift.booking.v1.CustomsHandlingImport}
 */
proto.swift.booking.v1.Requirement.prototype.getCustomsHandlingImport = function() {
  return /** @type{?proto.swift.booking.v1.CustomsHandlingImport} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.CustomsHandlingImport, 15));
};


/**
 * @param {?proto.swift.booking.v1.CustomsHandlingImport|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setCustomsHandlingImport = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearCustomsHandlingImport = function() {
  return this.setCustomsHandlingImport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasCustomsHandlingImport = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CustomsHandlingExport customs_handling_export = 16;
 * @return {?proto.swift.booking.v1.CustomsHandlingExport}
 */
proto.swift.booking.v1.Requirement.prototype.getCustomsHandlingExport = function() {
  return /** @type{?proto.swift.booking.v1.CustomsHandlingExport} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.CustomsHandlingExport, 16));
};


/**
 * @param {?proto.swift.booking.v1.CustomsHandlingExport|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setCustomsHandlingExport = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearCustomsHandlingExport = function() {
  return this.setCustomsHandlingExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasCustomsHandlingExport = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional OtherRequirement other_requirement = 17;
 * @return {?proto.swift.booking.v1.OtherRequirement}
 */
proto.swift.booking.v1.Requirement.prototype.getOtherRequirement = function() {
  return /** @type{?proto.swift.booking.v1.OtherRequirement} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.OtherRequirement, 17));
};


/**
 * @param {?proto.swift.booking.v1.OtherRequirement|undefined} value
 * @return {!proto.swift.booking.v1.Requirement} returns this
*/
proto.swift.booking.v1.Requirement.prototype.setOtherRequirement = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.swift.booking.v1.Requirement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Requirement} returns this
 */
proto.swift.booking.v1.Requirement.prototype.clearOtherRequirement = function() {
  return this.setOtherRequirement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Requirement.prototype.hasOtherRequirement = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DangerousGoods.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DangerousGoods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DangerousGoods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DangerousGoods.toObject = function(includeInstance, msg) {
  var f, obj = {
pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
division: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DangerousGoods}
 */
proto.swift.booking.v1.DangerousGoods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DangerousGoods;
  return proto.swift.booking.v1.DangerousGoods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DangerousGoods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DangerousGoods}
 */
proto.swift.booking.v1.DangerousGoods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.DangerousGoods.Class} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DangerousGoods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DangerousGoods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DangerousGoods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DangerousGoods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDivision();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.DangerousGoods.Class = {
  EXPLOSIVES: 0,
  GASES: 1,
  FLAMMABLE_LIQUIDS: 2,
  FLAMMABLE_SOLIDS: 3,
  OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES: 4,
  TOXIC_AND_INFECTIOUS_SUBSTANCES: 5,
  RADIOACTIVE_SUBSTANCES: 6,
  CORROSIVE_SUBSTANCES: 7,
  MISCELLANEOUS: 8
};

/**
 * optional Class class = 1;
 * @return {!proto.swift.booking.v1.DangerousGoods.Class}
 */
proto.swift.booking.v1.DangerousGoods.prototype.getClass = function() {
  return /** @type {!proto.swift.booking.v1.DangerousGoods.Class} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.DangerousGoods.Class} value
 * @return {!proto.swift.booking.v1.DangerousGoods} returns this
 */
proto.swift.booking.v1.DangerousGoods.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string division = 2;
 * @return {string}
 */
proto.swift.booking.v1.DangerousGoods.prototype.getDivision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DangerousGoods} returns this
 */
proto.swift.booking.v1.DangerousGoods.prototype.setDivision = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
actionDate: (f = msg.getActionDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Action}
 */
proto.swift.booking.v1.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Action;
  return proto.swift.booking.v1.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Action}
 */
proto.swift.booking.v1.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.Action.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActionDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Action.Type = {
  UNKNOWN: 0,
  ADVISE: 1,
  BOOK_DOMESTIC: 2,
  BOOK_LINEHAUL: 3,
  BOOKING_RECEIVED: 4,
  BOOK_SLOT_TIME: 5,
  CONTROLLING: 6,
  CUSTOMS: 7,
  INVOICE: 8
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.booking.v1.Action.Type}
 */
proto.swift.booking.v1.Action.prototype.getType = function() {
  return /** @type {!proto.swift.booking.v1.Action.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.Action.Type} value
 * @return {!proto.swift.booking.v1.Action} returns this
 */
proto.swift.booking.v1.Action.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.swift.booking.v1.Action.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Action} returns this
 */
proto.swift.booking.v1.Action.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp action_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Action.prototype.getActionDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Action} returns this
*/
proto.swift.booking.v1.Action.prototype.setActionDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Action} returns this
 */
proto.swift.booking.v1.Action.prototype.clearActionDate = function() {
  return this.setActionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Action.prototype.hasActionDate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TemperatureControlled.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TemperatureControlled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TemperatureControlled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemperatureControlled.toObject = function(includeInstance, msg) {
  var f, obj = {
temperature: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TemperatureControlled}
 */
proto.swift.booking.v1.TemperatureControlled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TemperatureControlled;
  return proto.swift.booking.v1.TemperatureControlled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TemperatureControlled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TemperatureControlled}
 */
proto.swift.booking.v1.TemperatureControlled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TemperatureControlled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TemperatureControlled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TemperatureControlled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemperatureControlled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemperature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string temperature = 1;
 * @return {string}
 */
proto.swift.booking.v1.TemperatureControlled.prototype.getTemperature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.TemperatureControlled} returns this
 */
proto.swift.booking.v1.TemperatureControlled.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DryLoad.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DryLoad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DryLoad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DryLoad.toObject = function(includeInstance, msg) {
  var f, obj = {
humidity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DryLoad}
 */
proto.swift.booking.v1.DryLoad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DryLoad;
  return proto.swift.booking.v1.DryLoad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DryLoad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DryLoad}
 */
proto.swift.booking.v1.DryLoad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHumidity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DryLoad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DryLoad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DryLoad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DryLoad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHumidity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 humidity = 1;
 * @return {number}
 */
proto.swift.booking.v1.DryLoad.prototype.getHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DryLoad} returns this
 */
proto.swift.booking.v1.DryLoad.prototype.setHumidity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.HangingGarments.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.HangingGarments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.HangingGarments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.HangingGarments.toObject = function(includeInstance, msg) {
  var f, obj = {
quantity: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.HangingGarments}
 */
proto.swift.booking.v1.HangingGarments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.HangingGarments;
  return proto.swift.booking.v1.HangingGarments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.HangingGarments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.HangingGarments}
 */
proto.swift.booking.v1.HangingGarments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.HangingGarments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.HangingGarments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.HangingGarments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.HangingGarments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 quantity = 1;
 * @return {number}
 */
proto.swift.booking.v1.HangingGarments.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.HangingGarments} returns this
 */
proto.swift.booking.v1.HangingGarments.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BoxTrailer.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BoxTrailer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BoxTrailer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BoxTrailer.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BoxTrailer}
 */
proto.swift.booking.v1.BoxTrailer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BoxTrailer;
  return proto.swift.booking.v1.BoxTrailer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BoxTrailer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BoxTrailer}
 */
proto.swift.booking.v1.BoxTrailer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BoxTrailer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BoxTrailer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BoxTrailer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BoxTrailer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TailLiftAtLoading.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TailLiftAtLoading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TailLiftAtLoading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TailLiftAtLoading.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TailLiftAtLoading}
 */
proto.swift.booking.v1.TailLiftAtLoading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TailLiftAtLoading;
  return proto.swift.booking.v1.TailLiftAtLoading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TailLiftAtLoading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TailLiftAtLoading}
 */
proto.swift.booking.v1.TailLiftAtLoading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TailLiftAtLoading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TailLiftAtLoading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TailLiftAtLoading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TailLiftAtLoading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TailLiftAtUnloading.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TailLiftAtUnloading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TailLiftAtUnloading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TailLiftAtUnloading.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TailLiftAtUnloading}
 */
proto.swift.booking.v1.TailLiftAtUnloading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TailLiftAtUnloading;
  return proto.swift.booking.v1.TailLiftAtUnloading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TailLiftAtUnloading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TailLiftAtUnloading}
 */
proto.swift.booking.v1.TailLiftAtUnloading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TailLiftAtUnloading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TailLiftAtUnloading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TailLiftAtUnloading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TailLiftAtUnloading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SenderNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SenderNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SenderNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SenderNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
phoneNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SenderNotification}
 */
proto.swift.booking.v1.SenderNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SenderNotification;
  return proto.swift.booking.v1.SenderNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SenderNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SenderNotification}
 */
proto.swift.booking.v1.SenderNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SenderNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SenderNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SenderNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SenderNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone_number = 1;
 * @return {string}
 */
proto.swift.booking.v1.SenderNotification.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SenderNotification} returns this
 */
proto.swift.booking.v1.SenderNotification.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ReceiverNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ReceiverNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ReceiverNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ReceiverNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
phoneNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ReceiverNotification}
 */
proto.swift.booking.v1.ReceiverNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ReceiverNotification;
  return proto.swift.booking.v1.ReceiverNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ReceiverNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ReceiverNotification}
 */
proto.swift.booking.v1.ReceiverNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ReceiverNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ReceiverNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ReceiverNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ReceiverNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phone_number = 1;
 * @return {string}
 */
proto.swift.booking.v1.ReceiverNotification.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.ReceiverNotification} returns this
 */
proto.swift.booking.v1.ReceiverNotification.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.LoadingNote.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.LoadingNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.LoadingNote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.LoadingNote.toObject = function(includeInstance, msg) {
  var f, obj = {
note: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.LoadingNote}
 */
proto.swift.booking.v1.LoadingNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.LoadingNote;
  return proto.swift.booking.v1.LoadingNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.LoadingNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.LoadingNote}
 */
proto.swift.booking.v1.LoadingNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.LoadingNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.LoadingNote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.LoadingNote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.LoadingNote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string note = 1;
 * @return {string}
 */
proto.swift.booking.v1.LoadingNote.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.LoadingNote} returns this
 */
proto.swift.booking.v1.LoadingNote.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UnloadingNote.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UnloadingNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UnloadingNote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UnloadingNote.toObject = function(includeInstance, msg) {
  var f, obj = {
note: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UnloadingNote}
 */
proto.swift.booking.v1.UnloadingNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UnloadingNote;
  return proto.swift.booking.v1.UnloadingNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UnloadingNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UnloadingNote}
 */
proto.swift.booking.v1.UnloadingNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UnloadingNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UnloadingNote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UnloadingNote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UnloadingNote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string note = 1;
 * @return {string}
 */
proto.swift.booking.v1.UnloadingNote.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UnloadingNote} returns this
 */
proto.swift.booking.v1.UnloadingNote.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ExtraLoading.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ExtraLoading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ExtraLoading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ExtraLoading.toObject = function(includeInstance, msg) {
  var f, obj = {
note: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ExtraLoading}
 */
proto.swift.booking.v1.ExtraLoading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ExtraLoading;
  return proto.swift.booking.v1.ExtraLoading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ExtraLoading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ExtraLoading}
 */
proto.swift.booking.v1.ExtraLoading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ExtraLoading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ExtraLoading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ExtraLoading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ExtraLoading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string note = 1;
 * @return {string}
 */
proto.swift.booking.v1.ExtraLoading.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.ExtraLoading} returns this
 */
proto.swift.booking.v1.ExtraLoading.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ExtraUnloading.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ExtraUnloading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ExtraUnloading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ExtraUnloading.toObject = function(includeInstance, msg) {
  var f, obj = {
note: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ExtraUnloading}
 */
proto.swift.booking.v1.ExtraUnloading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ExtraUnloading;
  return proto.swift.booking.v1.ExtraUnloading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ExtraUnloading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ExtraUnloading}
 */
proto.swift.booking.v1.ExtraUnloading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ExtraUnloading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ExtraUnloading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ExtraUnloading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ExtraUnloading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string note = 1;
 * @return {string}
 */
proto.swift.booking.v1.ExtraUnloading.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.ExtraUnloading} returns this
 */
proto.swift.booking.v1.ExtraUnloading.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CustomsHandlingImport.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CustomsHandlingImport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CustomsHandlingImport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CustomsHandlingImport.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CustomsHandlingImport}
 */
proto.swift.booking.v1.CustomsHandlingImport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CustomsHandlingImport;
  return proto.swift.booking.v1.CustomsHandlingImport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CustomsHandlingImport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CustomsHandlingImport}
 */
proto.swift.booking.v1.CustomsHandlingImport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CustomsHandlingImport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CustomsHandlingImport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CustomsHandlingImport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CustomsHandlingImport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CustomsHandlingExport.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CustomsHandlingExport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CustomsHandlingExport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CustomsHandlingExport.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CustomsHandlingExport}
 */
proto.swift.booking.v1.CustomsHandlingExport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CustomsHandlingExport;
  return proto.swift.booking.v1.CustomsHandlingExport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CustomsHandlingExport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CustomsHandlingExport}
 */
proto.swift.booking.v1.CustomsHandlingExport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CustomsHandlingExport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CustomsHandlingExport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CustomsHandlingExport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CustomsHandlingExport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.OtherRequirement.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.OtherRequirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.OtherRequirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.OtherRequirement.toObject = function(includeInstance, msg) {
  var f, obj = {
info: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.OtherRequirement}
 */
proto.swift.booking.v1.OtherRequirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.OtherRequirement;
  return proto.swift.booking.v1.OtherRequirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.OtherRequirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.OtherRequirement}
 */
proto.swift.booking.v1.OtherRequirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.OtherRequirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.OtherRequirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.OtherRequirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.OtherRequirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string info = 1;
 * @return {string}
 */
proto.swift.booking.v1.OtherRequirement.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.OtherRequirement} returns this
 */
proto.swift.booking.v1.OtherRequirement.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.SubmodeItem.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SubmodeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SubmodeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SubmodeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SubmodeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
itemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
containerType: jspb.Message.getFieldWithDefault(msg, 2, 0),
totalWeight: jspb.Message.getFieldWithDefault(msg, 3, 0),
totalSpace: jspb.Message.getFieldWithDefault(msg, 4, 0),
totalSpaceUnit: jspb.Message.getFieldWithDefault(msg, 5, 0),
totalChargeableWeight: jspb.Message.getFieldWithDefault(msg, 6, 0),
unitReference: jspb.Message.getFieldWithDefault(msg, 7, ""),
colliList: jspb.Message.toObjectList(msg.getColliList(),
    proto.swift.booking.v1.Colli.toObject, includeInstance),
declaredValue: jspb.Message.getFieldWithDefault(msg, 9, 0),
declaredCurrency: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SubmodeItem}
 */
proto.swift.booking.v1.SubmodeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SubmodeItem;
  return proto.swift.booking.v1.SubmodeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SubmodeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SubmodeItem}
 */
proto.swift.booking.v1.SubmodeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.SubmodeItem.ContainerType} */ (reader.readEnum());
      msg.setContainerType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalWeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSpace(value);
      break;
    case 5:
      var value = /** @type {!proto.swift.booking.v1.SubmodeItem.SpaceUnit} */ (reader.readEnum());
      msg.setTotalSpaceUnit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalChargeableWeight(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitReference(value);
      break;
    case 8:
      var value = new proto.swift.booking.v1.Colli;
      reader.readMessage(value,proto.swift.booking.v1.Colli.deserializeBinaryFromReader);
      msg.addColli(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeclaredValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclaredCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SubmodeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SubmodeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SubmodeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SubmodeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContainerType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTotalWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalSpace();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalSpaceUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTotalChargeableWeight();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUnitReference();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getColliList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.swift.booking.v1.Colli.serializeBinaryToWriter
    );
  }
  f = message.getDeclaredValue();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDeclaredCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.SubmodeItem.ContainerType = {
  UNDEFINED: 0,
  DV20: 1,
  DV40: 2,
  HC40: 3,
  RF20: 4,
  RF40: 5
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.SubmodeItem.SpaceUnit = {
  CBM: 0,
  PPL: 1,
  LDM: 2
};

/**
 * optional int64 item_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ContainerType container_type = 2;
 * @return {!proto.swift.booking.v1.SubmodeItem.ContainerType}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getContainerType = function() {
  return /** @type {!proto.swift.booking.v1.SubmodeItem.ContainerType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.SubmodeItem.ContainerType} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setContainerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 total_weight = 3;
 * @return {number}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getTotalWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setTotalWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_space = 4;
 * @return {number}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getTotalSpace = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setTotalSpace = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional SpaceUnit total_space_unit = 5;
 * @return {!proto.swift.booking.v1.SubmodeItem.SpaceUnit}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getTotalSpaceUnit = function() {
  return /** @type {!proto.swift.booking.v1.SubmodeItem.SpaceUnit} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.swift.booking.v1.SubmodeItem.SpaceUnit} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setTotalSpaceUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 total_chargeable_weight = 6;
 * @return {number}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getTotalChargeableWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setTotalChargeableWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string unit_reference = 7;
 * @return {string}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getUnitReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setUnitReference = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Colli colli = 8;
 * @return {!Array<!proto.swift.booking.v1.Colli>}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getColliList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Colli>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Colli, 8));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Colli>} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
*/
proto.swift.booking.v1.SubmodeItem.prototype.setColliList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.swift.booking.v1.Colli=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Colli}
 */
proto.swift.booking.v1.SubmodeItem.prototype.addColli = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.swift.booking.v1.Colli, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.clearColliList = function() {
  return this.setColliList([]);
};


/**
 * optional int64 declared_value = 9;
 * @return {number}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getDeclaredValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setDeclaredValue = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string declared_currency = 10;
 * @return {string}
 */
proto.swift.booking.v1.SubmodeItem.prototype.getDeclaredCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SubmodeItem} returns this
 */
proto.swift.booking.v1.SubmodeItem.prototype.setDeclaredCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Colli.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Colli.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Colli} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Colli.toObject = function(includeInstance, msg) {
  var f, obj = {
colliType: jspb.Message.getFieldWithDefault(msg, 1, 0),
quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
volume: (f = msg.getVolume()) && proto.swift.booking.v1.Volume.toObject(includeInstance, f),
dimension: (f = msg.getDimension()) && proto.swift.booking.v1.Dimension.toObject(includeInstance, f),
description: jspb.Message.getFieldWithDefault(msg, 6, ""),
colliId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Colli}
 */
proto.swift.booking.v1.Colli.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Colli;
  return proto.swift.booking.v1.Colli.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Colli} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Colli}
 */
proto.swift.booking.v1.Colli.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.Colli.Type} */ (reader.readEnum());
      msg.setColliType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 4:
      var value = new proto.swift.booking.v1.Volume;
      reader.readMessage(value,proto.swift.booking.v1.Volume.deserializeBinaryFromReader);
      msg.setVolume(value);
      break;
    case 5:
      var value = new proto.swift.booking.v1.Dimension;
      reader.readMessage(value,proto.swift.booking.v1.Dimension.deserializeBinaryFromReader);
      msg.setDimension(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setColliId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Colli.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Colli.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Colli} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Colli.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColliType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getVolume();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.booking.v1.Volume.serializeBinaryToWriter
    );
  }
  f = message.getDimension();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.booking.v1.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColliId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Colli.Type = {
  UNKNOWN: 0,
  PLT: 1,
  EUP: 2,
  PKT: 3,
  CRT: 4,
  RLS: 5,
  PCS: 6,
  CLI: 7
};

/**
 * optional Type colli_type = 1;
 * @return {!proto.swift.booking.v1.Colli.Type}
 */
proto.swift.booking.v1.Colli.prototype.getColliType = function() {
  return /** @type {!proto.swift.booking.v1.Colli.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.Colli.Type} value
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.setColliType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.swift.booking.v1.Colli.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 weight = 3;
 * @return {number}
 */
proto.swift.booking.v1.Colli.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Volume volume = 4;
 * @return {?proto.swift.booking.v1.Volume}
 */
proto.swift.booking.v1.Colli.prototype.getVolume = function() {
  return /** @type{?proto.swift.booking.v1.Volume} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Volume, 4));
};


/**
 * @param {?proto.swift.booking.v1.Volume|undefined} value
 * @return {!proto.swift.booking.v1.Colli} returns this
*/
proto.swift.booking.v1.Colli.prototype.setVolume = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.clearVolume = function() {
  return this.setVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Colli.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Dimension dimension = 5;
 * @return {?proto.swift.booking.v1.Dimension}
 */
proto.swift.booking.v1.Colli.prototype.getDimension = function() {
  return /** @type{?proto.swift.booking.v1.Dimension} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Dimension, 5));
};


/**
 * @param {?proto.swift.booking.v1.Dimension|undefined} value
 * @return {!proto.swift.booking.v1.Colli} returns this
*/
proto.swift.booking.v1.Colli.prototype.setDimension = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.clearDimension = function() {
  return this.setDimension(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Colli.prototype.hasDimension = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.swift.booking.v1.Colli.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 colli_id = 7;
 * @return {number}
 */
proto.swift.booking.v1.Colli.prototype.getColliId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Colli} returns this
 */
proto.swift.booking.v1.Colli.prototype.setColliId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0),
unit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Volume}
 */
proto.swift.booking.v1.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Volume;
  return proto.swift.booking.v1.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Volume}
 */
proto.swift.booking.v1.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.Volume.Unit} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.Volume.Unit = {
  UNKNOWN: 0,
  PPL: 1,
  LDM: 2,
  CBM: 3,
  FTL: 4,
  FCL: 5
};

/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.swift.booking.v1.Volume.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Volume} returns this
 */
proto.swift.booking.v1.Volume.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Unit unit = 2;
 * @return {!proto.swift.booking.v1.Volume.Unit}
 */
proto.swift.booking.v1.Volume.prototype.getUnit = function() {
  return /** @type {!proto.swift.booking.v1.Volume.Unit} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.Volume.Unit} value
 * @return {!proto.swift.booking.v1.Volume} returns this
 */
proto.swift.booking.v1.Volume.prototype.setUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Dimension.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Dimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Dimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Dimension.toObject = function(includeInstance, msg) {
  var f, obj = {
length: jspb.Message.getFieldWithDefault(msg, 1, 0),
width: jspb.Message.getFieldWithDefault(msg, 2, 0),
height: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Dimension}
 */
proto.swift.booking.v1.Dimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Dimension;
  return proto.swift.booking.v1.Dimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Dimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Dimension}
 */
proto.swift.booking.v1.Dimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLength(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Dimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Dimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Dimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Dimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 length = 1;
 * @return {number}
 */
proto.swift.booking.v1.Dimension.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Dimension} returns this
 */
proto.swift.booking.v1.Dimension.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 width = 2;
 * @return {number}
 */
proto.swift.booking.v1.Dimension.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Dimension} returns this
 */
proto.swift.booking.v1.Dimension.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 height = 3;
 * @return {number}
 */
proto.swift.booking.v1.Dimension.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Dimension} returns this
 */
proto.swift.booking.v1.Dimension.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingItem.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingItem.toObject = function(includeInstance, msg) {
  var f, obj = {
itemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
sku: jspb.Message.getFieldWithDefault(msg, 2, ""),
quantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
price: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingItem}
 */
proto.swift.booking.v1.BookingItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingItem;
  return proto.swift.booking.v1.BookingItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingItem}
 */
proto.swift.booking.v1.BookingItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 item_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.BookingItem.prototype.getItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingItem} returns this
 */
proto.swift.booking.v1.BookingItem.prototype.setItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string sku = 2;
 * @return {string}
 */
proto.swift.booking.v1.BookingItem.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingItem} returns this
 */
proto.swift.booking.v1.BookingItem.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 quantity = 3;
 * @return {number}
 */
proto.swift.booking.v1.BookingItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingItem} returns this
 */
proto.swift.booking.v1.BookingItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 price = 4;
 * @return {number}
 */
proto.swift.booking.v1.BookingItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingItem} returns this
 */
proto.swift.booking.v1.BookingItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingEventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingId: jspb.Message.getFieldWithDefault(msg, 2, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
status: jspb.Message.getFieldWithDefault(msg, 4, 0),
description: jspb.Message.getFieldWithDefault(msg, 5, ""),
geolocation: (f = msg.getGeolocation()) && common_geolocation_pb.Geolocation.toObject(includeInstance, f),
location: jspb.Message.getFieldWithDefault(msg, 7, ""),
eventDate: (f = msg.getEventDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingEvent}
 */
proto.swift.booking.v1.BookingEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingEvent;
  return proto.swift.booking.v1.BookingEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingEvent}
 */
proto.swift.booking.v1.BookingEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 4:
      var value = /** @type {!proto.swift.booking.v1.Booking.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new common_geolocation_pb.Geolocation;
      reader.readMessage(value,common_geolocation_pb.Geolocation.deserializeBinaryFromReader);
      msg.setGeolocation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEventDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGeolocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_geolocation_pb.Geolocation.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEventDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 booking_event_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.BookingEvent.prototype.getBookingEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setBookingEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 booking_id = 2;
 * @return {number}
 */
proto.swift.booking.v1.BookingEvent.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string booking_ref = 3;
 * @return {string}
 */
proto.swift.booking.v1.BookingEvent.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Booking.Status status = 4;
 * @return {!proto.swift.booking.v1.Booking.Status}
 */
proto.swift.booking.v1.BookingEvent.prototype.getStatus = function() {
  return /** @type {!proto.swift.booking.v1.Booking.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.swift.booking.v1.Booking.Status} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.swift.booking.v1.BookingEvent.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional swift.common.Geolocation geolocation = 6;
 * @return {?proto.swift.common.Geolocation}
 */
proto.swift.booking.v1.BookingEvent.prototype.getGeolocation = function() {
  return /** @type{?proto.swift.common.Geolocation} */ (
    jspb.Message.getWrapperField(this, common_geolocation_pb.Geolocation, 6));
};


/**
 * @param {?proto.swift.common.Geolocation|undefined} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
*/
proto.swift.booking.v1.BookingEvent.prototype.setGeolocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.clearGeolocation = function() {
  return this.setGeolocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingEvent.prototype.hasGeolocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string location = 7;
 * @return {string}
 */
proto.swift.booking.v1.BookingEvent.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp event_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingEvent.prototype.getEventDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
*/
proto.swift.booking.v1.BookingEvent.prototype.setEventDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.clearEventDate = function() {
  return this.setEventDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingEvent.prototype.hasEventDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingEvent.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
*/
proto.swift.booking.v1.BookingEvent.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingEvent} returns this
 */
proto.swift.booking.v1.BookingEvent.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingEvent.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingStatsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingStatsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingStatsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
grouping: jspb.Message.getFieldWithDefault(msg, 2, 0),
from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingStatsQuery}
 */
proto.swift.booking.v1.BookingStatsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingStatsQuery;
  return proto.swift.booking.v1.BookingStatsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingStatsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingStatsQuery}
 */
proto.swift.booking.v1.BookingStatsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.BookingStatsQuery.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.BookingStatsQuery.Grouping} */ (reader.readEnum());
      msg.setGrouping(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingStatsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingStatsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingStatsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGrouping();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.BookingStatsQuery.Type = {
  BOOKING_PROGRESS: 0,
  BOOKING_EMISSIONS: 1
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.BookingStatsQuery.Grouping = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.swift.booking.v1.BookingStatsQuery.Type}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.getType = function() {
  return /** @type {!proto.swift.booking.v1.BookingStatsQuery.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.BookingStatsQuery.Type} value
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Grouping grouping = 2;
 * @return {!proto.swift.booking.v1.BookingStatsQuery.Grouping}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.getGrouping = function() {
  return /** @type {!proto.swift.booking.v1.BookingStatsQuery.Grouping} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.BookingStatsQuery.Grouping} value
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.setGrouping = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
*/
proto.swift.booking.v1.BookingStatsQuery.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
*/
proto.swift.booking.v1.BookingStatsQuery.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingStatsQuery} returns this
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingStatsQuery.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.BookingStats.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.BookingStats.DataCase = {
  DATA_NOT_SET: 0,
  BOOKING_PROGRESS: 1,
  BOOKING_EMISSIONS: 2
};

/**
 * @return {proto.swift.booking.v1.BookingStats.DataCase}
 */
proto.swift.booking.v1.BookingStats.prototype.getDataCase = function() {
  return /** @type {proto.swift.booking.v1.BookingStats.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.BookingStats.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingStats.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingStats.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingProgress: (f = msg.getBookingProgress()) && proto.swift.booking.v1.BookingProgress.toObject(includeInstance, f),
bookingEmissions: (f = msg.getBookingEmissions()) && proto.swift.booking.v1.BookingEmissions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingStats}
 */
proto.swift.booking.v1.BookingStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingStats;
  return proto.swift.booking.v1.BookingStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingStats}
 */
proto.swift.booking.v1.BookingStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingProgress;
      reader.readMessage(value,proto.swift.booking.v1.BookingProgress.deserializeBinaryFromReader);
      msg.setBookingProgress(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.BookingEmissions;
      reader.readMessage(value,proto.swift.booking.v1.BookingEmissions.deserializeBinaryFromReader);
      msg.setBookingEmissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingProgress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingProgress.serializeBinaryToWriter
    );
  }
  f = message.getBookingEmissions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.BookingEmissions.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingProgress booking_progress = 1;
 * @return {?proto.swift.booking.v1.BookingProgress}
 */
proto.swift.booking.v1.BookingStats.prototype.getBookingProgress = function() {
  return /** @type{?proto.swift.booking.v1.BookingProgress} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingProgress, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingProgress|undefined} value
 * @return {!proto.swift.booking.v1.BookingStats} returns this
*/
proto.swift.booking.v1.BookingStats.prototype.setBookingProgress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.swift.booking.v1.BookingStats.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingStats} returns this
 */
proto.swift.booking.v1.BookingStats.prototype.clearBookingProgress = function() {
  return this.setBookingProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingStats.prototype.hasBookingProgress = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BookingEmissions booking_emissions = 2;
 * @return {?proto.swift.booking.v1.BookingEmissions}
 */
proto.swift.booking.v1.BookingStats.prototype.getBookingEmissions = function() {
  return /** @type{?proto.swift.booking.v1.BookingEmissions} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingEmissions, 2));
};


/**
 * @param {?proto.swift.booking.v1.BookingEmissions|undefined} value
 * @return {!proto.swift.booking.v1.BookingStats} returns this
*/
proto.swift.booking.v1.BookingStats.prototype.setBookingEmissions = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.swift.booking.v1.BookingStats.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingStats} returns this
 */
proto.swift.booking.v1.BookingStats.prototype.clearBookingEmissions = function() {
  return this.setBookingEmissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingStats.prototype.hasBookingEmissions = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.BookingProgress.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.swift.booking.v1.BookingProgressPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingProgress}
 */
proto.swift.booking.v1.BookingProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingProgress;
  return proto.swift.booking.v1.BookingProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingProgress}
 */
proto.swift.booking.v1.BookingProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingProgressPoint;
      reader.readMessage(value,proto.swift.booking.v1.BookingProgressPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.BookingProgressPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BookingProgressPoint points = 1;
 * @return {!Array<!proto.swift.booking.v1.BookingProgressPoint>}
 */
proto.swift.booking.v1.BookingProgress.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.BookingProgressPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.BookingProgressPoint, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.BookingProgressPoint>} value
 * @return {!proto.swift.booking.v1.BookingProgress} returns this
*/
proto.swift.booking.v1.BookingProgress.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.BookingProgressPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingProgressPoint}
 */
proto.swift.booking.v1.BookingProgress.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.BookingProgressPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingProgress} returns this
 */
proto.swift.booking.v1.BookingProgress.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingProgressPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingProgressPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingProgressPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
draft: jspb.Message.getFieldWithDefault(msg, 2, 0),
accepted: jspb.Message.getFieldWithDefault(msg, 3, 0),
inTransit: jspb.Message.getFieldWithDefault(msg, 4, 0),
delivered: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingProgressPoint}
 */
proto.swift.booking.v1.BookingProgressPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingProgressPoint;
  return proto.swift.booking.v1.BookingProgressPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingProgressPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingProgressPoint}
 */
proto.swift.booking.v1.BookingProgressPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDraft(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccepted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInTransit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelivered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingProgressPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingProgressPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingProgressPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDraft();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccepted();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInTransit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDelivered();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
*/
proto.swift.booking.v1.BookingProgressPoint.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 draft = 2;
 * @return {number}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.getDraft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.setDraft = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 accepted = 3;
 * @return {number}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.getAccepted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.setAccepted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 in_transit = 4;
 * @return {number}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.getInTransit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.setInTransit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 delivered = 5;
 * @return {number}
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.getDelivered = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingProgressPoint} returns this
 */
proto.swift.booking.v1.BookingProgressPoint.prototype.setDelivered = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.BookingEmissions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingEmissions.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingEmissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingEmissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEmissions.toObject = function(includeInstance, msg) {
  var f, obj = {
pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.swift.booking.v1.BookingEmissionsPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingEmissions}
 */
proto.swift.booking.v1.BookingEmissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingEmissions;
  return proto.swift.booking.v1.BookingEmissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingEmissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingEmissions}
 */
proto.swift.booking.v1.BookingEmissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingEmissionsPoint;
      reader.readMessage(value,proto.swift.booking.v1.BookingEmissionsPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingEmissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingEmissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingEmissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEmissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.BookingEmissionsPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BookingEmissionsPoint points = 1;
 * @return {!Array<!proto.swift.booking.v1.BookingEmissionsPoint>}
 */
proto.swift.booking.v1.BookingEmissions.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.BookingEmissionsPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.BookingEmissionsPoint, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.BookingEmissionsPoint>} value
 * @return {!proto.swift.booking.v1.BookingEmissions} returns this
*/
proto.swift.booking.v1.BookingEmissions.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.BookingEmissionsPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint}
 */
proto.swift.booking.v1.BookingEmissions.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.BookingEmissionsPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingEmissions} returns this
 */
proto.swift.booking.v1.BookingEmissions.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingEmissionsPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingEmissionsPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEmissionsPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
air: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
courier: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
rail: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
road: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
sea: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint}
 */
proto.swift.booking.v1.BookingEmissionsPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingEmissionsPoint;
  return proto.swift.booking.v1.BookingEmissionsPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingEmissionsPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint}
 */
proto.swift.booking.v1.BookingEmissionsPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAir(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCourier(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRail(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRoad(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingEmissionsPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingEmissionsPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingEmissionsPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAir();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCourier();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRail();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRoad();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSea();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
*/
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double air = 2;
 * @return {number}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getAir = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setAir = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double courier = 3;
 * @return {number}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getCourier = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setCourier = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double rail = 4;
 * @return {number}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getRail = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setRail = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double road = 5;
 * @return {number}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getRoad = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setRoad = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double sea = 6;
 * @return {number}
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.getSea = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingEmissionsPoint} returns this
 */
proto.swift.booking.v1.BookingEmissionsPoint.prototype.setSea = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.BookingFilter.repeatedFields_ = [1,2,3,4,5,6,7,8,9,10,12,13,14,15,17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
userIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
organizationIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
userGroupIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
branchIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
bookingRefList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
statusList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
senderAddressIdList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
senderCountryIdList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
receiverAddressIdList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
receiverCountryIdList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
search: jspb.Message.getFieldWithDefault(msg, 11, ""),
dateList: jspb.Message.toObjectList(msg.getDateList(),
    proto.swift.booking.v1.DateFilter.toObject, includeInstance),
transportRefList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
serviceSelectionList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
sourceList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
sourceRefsMap: (f = msg.getSourceRefsMap()) ? f.toObject(includeInstance, undefined) : [],
sourceDescList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
transportModeList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
transportOperatorUserIdList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
shipmentRef: jspb.Message.getFieldWithDefault(msg, 20, ""),
shippable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingFilter}
 */
proto.swift.booking.v1.BookingFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingFilter;
  return proto.swift.booking.v1.BookingFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingFilter}
 */
proto.swift.booking.v1.BookingFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrganizationId(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserGroupId(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBranchId(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRef(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.booking.v1.Booking.Status>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSenderAddressId(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSenderCountryId(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReceiverAddressId(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceiverCountryId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 12:
      var value = new proto.swift.booking.v1.DateFilter;
      reader.readMessage(value,proto.swift.booking.v1.DateFilter.deserializeBinaryFromReader);
      msg.addDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransportRef(value);
      break;
    case 14:
      var values = /** @type {!Array<!proto.swift.booking.v1.Booking.ServiceSelection>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addServiceSelection(values[i]);
      }
      break;
    case 15:
      var values = /** @type {!Array<!proto.swift.booking.v1.Booking.Source>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSource(values[i]);
      }
      break;
    case 16:
      var value = msg.getSourceRefsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceDesc(value);
      break;
    case 18:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTransportMode(values[i]);
      }
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addTransportOperatorUserId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShippable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrganizationIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getUserGroupIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getBranchIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getBookingRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getSenderAddressIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getSenderCountryIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getReceiverAddressIdList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getReceiverCountryIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.swift.booking.v1.DateFilter.serializeBinaryToWriter
    );
  }
  f = message.getTransportRefList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getServiceSelectionList();
  if (f.length > 0) {
    writer.writePackedEnum(
      14,
      f
    );
  }
  f = message.getSourceList();
  if (f.length > 0) {
    writer.writePackedEnum(
      15,
      f
    );
  }
  f = message.getSourceRefsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSourceDescList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getTransportModeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      18,
      f
    );
  }
  f = message.getTransportOperatorUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getShippable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * repeated string user_id = 1;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setUserIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearUserIdList = function() {
  return this.setUserIdList([]);
};


/**
 * repeated int64 organization_id = 2;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getOrganizationIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setOrganizationIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addOrganizationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearOrganizationIdList = function() {
  return this.setOrganizationIdList([]);
};


/**
 * repeated int64 user_group_id = 3;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getUserGroupIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setUserGroupIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addUserGroupId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearUserGroupIdList = function() {
  return this.setUserGroupIdList([]);
};


/**
 * repeated int64 branch_id = 4;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getBranchIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setBranchIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addBranchId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearBranchIdList = function() {
  return this.setBranchIdList([]);
};


/**
 * repeated string booking_ref = 5;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getBookingRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setBookingRefList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addBookingRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearBookingRefList = function() {
  return this.setBookingRefList([]);
};


/**
 * repeated Booking.Status status = 6;
 * @return {!Array<!proto.swift.booking.v1.Booking.Status>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.swift.booking.v1.Booking.Status>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Booking.Status>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.booking.v1.Booking.Status} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated int64 sender_address_id = 7;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSenderAddressIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setSenderAddressIdList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addSenderAddressId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearSenderAddressIdList = function() {
  return this.setSenderAddressIdList([]);
};


/**
 * repeated string sender_country_id = 8;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSenderCountryIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setSenderCountryIdList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addSenderCountryId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearSenderCountryIdList = function() {
  return this.setSenderCountryIdList([]);
};


/**
 * repeated int64 receiver_address_id = 9;
 * @return {!Array<number>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getReceiverAddressIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setReceiverAddressIdList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addReceiverAddressId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearReceiverAddressIdList = function() {
  return this.setReceiverAddressIdList([]);
};


/**
 * repeated string receiver_country_id = 10;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getReceiverCountryIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setReceiverCountryIdList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addReceiverCountryId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearReceiverCountryIdList = function() {
  return this.setReceiverCountryIdList([]);
};


/**
 * optional string search = 11;
 * @return {string}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated DateFilter date = 12;
 * @return {!Array<!proto.swift.booking.v1.DateFilter>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getDateList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.DateFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.DateFilter, 12));
};


/**
 * @param {!Array<!proto.swift.booking.v1.DateFilter>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
*/
proto.swift.booking.v1.BookingFilter.prototype.setDateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.swift.booking.v1.DateFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.DateFilter}
 */
proto.swift.booking.v1.BookingFilter.prototype.addDate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.swift.booking.v1.DateFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearDateList = function() {
  return this.setDateList([]);
};


/**
 * repeated string transport_ref = 13;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getTransportRefList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setTransportRefList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addTransportRef = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearTransportRefList = function() {
  return this.setTransportRefList([]);
};


/**
 * repeated Booking.ServiceSelection service_selection = 14;
 * @return {!Array<!proto.swift.booking.v1.Booking.ServiceSelection>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getServiceSelectionList = function() {
  return /** @type {!Array<!proto.swift.booking.v1.Booking.ServiceSelection>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Booking.ServiceSelection>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setServiceSelectionList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {!proto.swift.booking.v1.Booking.ServiceSelection} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addServiceSelection = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearServiceSelectionList = function() {
  return this.setServiceSelectionList([]);
};


/**
 * repeated Booking.Source source = 15;
 * @return {!Array<!proto.swift.booking.v1.Booking.Source>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSourceList = function() {
  return /** @type {!Array<!proto.swift.booking.v1.Booking.Source>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Booking.Source>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setSourceList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!proto.swift.booking.v1.Booking.Source} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addSource = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearSourceList = function() {
  return this.setSourceList([]);
};


/**
 * map<string, string> source_refs = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSourceRefsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearSourceRefsMap = function() {
  this.getSourceRefsMap().clear();
  return this;
};


/**
 * repeated string source_desc = 17;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getSourceDescList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setSourceDescList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addSourceDesc = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearSourceDescList = function() {
  return this.setSourceDescList([]);
};


/**
 * repeated swift.common.TransportMode transport_mode = 18;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getTransportModeList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setTransportModeList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addTransportMode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearTransportModeList = function() {
  return this.setTransportModeList([]);
};


/**
 * repeated string transport_operator_user_id = 19;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.BookingFilter.prototype.getTransportOperatorUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setTransportOperatorUserIdList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.addTransportOperatorUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.clearTransportOperatorUserIdList = function() {
  return this.setTransportOperatorUserIdList([]);
};


/**
 * optional string shipment_ref = 20;
 * @return {string}
 */
proto.swift.booking.v1.BookingFilter.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool shippable = 21;
 * @return {boolean}
 */
proto.swift.booking.v1.BookingFilter.prototype.getShippable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.BookingFilter} returns this
 */
proto.swift.booking.v1.BookingFilter.prototype.setShippable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.DateFilter.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.DateFilter.ValueCase = {
  VALUE_NOT_SET: 0,
  RELATIVE: 3,
  ABSOLUTE: 4
};

/**
 * @return {proto.swift.booking.v1.DateFilter.ValueCase}
 */
proto.swift.booking.v1.DateFilter.prototype.getValueCase = function() {
  return /** @type {proto.swift.booking.v1.DateFilter.ValueCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.DateFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
relative: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
absolute: (f = msg.getAbsolute()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DateFilter}
 */
proto.swift.booking.v1.DateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DateFilter;
  return proto.swift.booking.v1.DateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DateFilter}
 */
proto.swift.booking.v1.DateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.DateFilter.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.common.FilterOperator} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.booking.v1.DateFilter.Relative} */ (reader.readEnum());
      msg.setRelative(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAbsolute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.swift.booking.v1.DateFilter.Relative} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAbsolute();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.DateFilter.Field = {
  UNKNOWN: 0,
  CREATEDAT: 1,
  UPDATEDAT: 2,
  DPD: 3,
  EDT: 4,
  EAT: 5,
  AAT: 6,
  ADT: 7,
  DDD: 8,
  ET: 9,
  DLPT: 10,
  DLDT: 11
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.DateFilter.Relative = {
  NOW: 0,
  CURRENT_DATE: 1
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.booking.v1.DateFilter.Field}
 */
proto.swift.booking.v1.DateFilter.prototype.getField = function() {
  return /** @type {!proto.swift.booking.v1.DateFilter.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.DateFilter.Field} value
 * @return {!proto.swift.booking.v1.DateFilter} returns this
 */
proto.swift.booking.v1.DateFilter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional swift.common.FilterOperator operator = 2;
 * @return {!proto.swift.common.FilterOperator}
 */
proto.swift.booking.v1.DateFilter.prototype.getOperator = function() {
  return /** @type {!proto.swift.common.FilterOperator} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.common.FilterOperator} value
 * @return {!proto.swift.booking.v1.DateFilter} returns this
 */
proto.swift.booking.v1.DateFilter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Relative relative = 3;
 * @return {!proto.swift.booking.v1.DateFilter.Relative}
 */
proto.swift.booking.v1.DateFilter.prototype.getRelative = function() {
  return /** @type {!proto.swift.booking.v1.DateFilter.Relative} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.booking.v1.DateFilter.Relative} value
 * @return {!proto.swift.booking.v1.DateFilter} returns this
 */
proto.swift.booking.v1.DateFilter.prototype.setRelative = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.swift.booking.v1.DateFilter.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.swift.booking.v1.DateFilter} returns this
 */
proto.swift.booking.v1.DateFilter.prototype.clearRelative = function() {
  return jspb.Message.setOneofField(this, 3, proto.swift.booking.v1.DateFilter.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.DateFilter.prototype.hasRelative = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp absolute = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.DateFilter.prototype.getAbsolute = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.DateFilter} returns this
*/
proto.swift.booking.v1.DateFilter.prototype.setAbsolute = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.booking.v1.DateFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.DateFilter} returns this
 */
proto.swift.booking.v1.DateFilter.prototype.clearAbsolute = function() {
  return this.setAbsolute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.DateFilter.prototype.hasAbsolute = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingSorting}
 */
proto.swift.booking.v1.BookingSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingSorting;
  return proto.swift.booking.v1.BookingSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingSorting}
 */
proto.swift.booking.v1.BookingSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.BookingSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.BookingSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.BookingSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.BookingSorting.Field = {
  BOOKING_ID: 0,
  ORDER_REF: 1,
  STATUS: 2,
  PICKUP_DATE: 3,
  BOOKING_REF: 4,
  CREATED_AT: 5,
  SENDER_NAME: 6,
  RECEIVER_NAME: 7,
  TRANSPORT_MODE: 8,
  ETD: 9,
  ATD: 10,
  ETA: 11,
  ATA: 12,
  OWNER: 13,
  TRANSPORT_OPERATOR: 14,
  TRANSPORT_REF: 15,
  ORGANIZATION: 16,
  ACTION_DATE: 17,
  LAST_MESAGE_AT: 18,
  SOURCE_REFS: 19
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.booking.v1.BookingSorting.Field}
 */
proto.swift.booking.v1.BookingSorting.prototype.getField = function() {
  return /** @type {!proto.swift.booking.v1.BookingSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.BookingSorting.Field} value
 * @return {!proto.swift.booking.v1.BookingSorting} returns this
 */
proto.swift.booking.v1.BookingSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.booking.v1.BookingSorting.Ordering}
 */
proto.swift.booking.v1.BookingSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.booking.v1.BookingSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.BookingSorting.Ordering} value
 * @return {!proto.swift.booking.v1.BookingSorting} returns this
 */
proto.swift.booking.v1.BookingSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.AcceptDirectSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.AcceptDirectSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptDirectSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {
nomination: (f = msg.getNomination()) && booking_v1_nomination_pb.Nomination.toObject(includeInstance, f),
goodsInvoiceDocumentId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.AcceptDirectSupplier}
 */
proto.swift.booking.v1.AcceptDirectSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.AcceptDirectSupplier;
  return proto.swift.booking.v1.AcceptDirectSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.AcceptDirectSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.AcceptDirectSupplier}
 */
proto.swift.booking.v1.AcceptDirectSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGoodsInvoiceDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.AcceptDirectSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.AcceptDirectSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptDirectSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
  f = message.getGoodsInvoiceDocumentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Nomination nomination = 1;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, booking_v1_nomination_pb.Nomination, 1));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.AcceptDirectSupplier} returns this
*/
proto.swift.booking.v1.AcceptDirectSupplier.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptDirectSupplier} returns this
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 goods_invoice_document_id = 2;
 * @return {number}
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.getGoodsInvoiceDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.AcceptDirectSupplier} returns this
 */
proto.swift.booking.v1.AcceptDirectSupplier.prototype.setGoodsInvoiceDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.AcceptManagedSupplier.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.AcceptManagedSupplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.AcceptManagedSupplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptManagedSupplier.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.AcceptManagedSupplier}
 */
proto.swift.booking.v1.AcceptManagedSupplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.AcceptManagedSupplier;
  return proto.swift.booking.v1.AcceptManagedSupplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.AcceptManagedSupplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.AcceptManagedSupplier}
 */
proto.swift.booking.v1.AcceptManagedSupplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.AcceptManagedSupplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.AcceptManagedSupplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.AcceptManagedSupplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptManagedSupplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListBookingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListBookingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
filter: (f = msg.getFilter()) && proto.swift.booking.v1.BookingFilter.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.booking.v1.BookingSorting.toObject(includeInstance, f),
filterId: jspb.Message.getFieldWithDefault(msg, 4, 0),
skipSubmodes: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListBookingsRequest}
 */
proto.swift.booking.v1.ListBookingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListBookingsRequest;
  return proto.swift.booking.v1.ListBookingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListBookingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListBookingsRequest}
 */
proto.swift.booking.v1.ListBookingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.BookingFilter;
      reader.readMessage(value,proto.swift.booking.v1.BookingFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.swift.booking.v1.BookingSorting;
      reader.readMessage(value,proto.swift.booking.v1.BookingSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFilterId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipSubmodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListBookingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListBookingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.BookingFilter.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.booking.v1.BookingSorting.serializeBinaryToWriter
    );
  }
  f = message.getFilterId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSkipSubmodes();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
*/
proto.swift.booking.v1.ListBookingsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BookingFilter filter = 2;
 * @return {?proto.swift.booking.v1.BookingFilter}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.booking.v1.BookingFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingFilter, 2));
};


/**
 * @param {?proto.swift.booking.v1.BookingFilter|undefined} value
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
*/
proto.swift.booking.v1.ListBookingsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BookingSorting sorting = 3;
 * @return {?proto.swift.booking.v1.BookingSorting}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.booking.v1.BookingSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingSorting, 3));
};


/**
 * @param {?proto.swift.booking.v1.BookingSorting|undefined} value
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
*/
proto.swift.booking.v1.ListBookingsRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 filter_id = 4;
 * @return {number}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.getFilterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.setFilterId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool skip_submodes = 5;
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.getSkipSubmodes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.ListBookingsRequest} returns this
 */
proto.swift.booking.v1.ListBookingsRequest.prototype.setSkipSubmodes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListBookingsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListBookingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListBookingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingsList: jspb.Message.toObjectList(msg.getBookingsList(),
    proto.swift.booking.v1.Booking.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListBookingsResponse}
 */
proto.swift.booking.v1.ListBookingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListBookingsResponse;
  return proto.swift.booking.v1.ListBookingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListBookingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListBookingsResponse}
 */
proto.swift.booking.v1.ListBookingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.addBookings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListBookingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListBookingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListBookingsResponse} returns this
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Booking bookings = 2;
 * @return {!Array<!proto.swift.booking.v1.Booking>}
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.getBookingsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Booking>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Booking, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Booking>} value
 * @return {!proto.swift.booking.v1.ListBookingsResponse} returns this
*/
proto.swift.booking.v1.ListBookingsResponse.prototype.setBookingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Booking=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.addBookings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Booking, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListBookingsResponse} returns this
 */
proto.swift.booking.v1.ListBookingsResponse.prototype.clearBookingsList = function() {
  return this.setBookingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingRequest}
 */
proto.swift.booking.v1.GetBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingRequest;
  return proto.swift.booking.v1.GetBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingRequest}
 */
proto.swift.booking.v1.GetBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetBookingRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetBookingRequest} returns this
 */
proto.swift.booking.v1.GetBookingRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.GetBookingRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.GetBookingRequest} returns this
 */
proto.swift.booking.v1.GetBookingRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingResponse}
 */
proto.swift.booking.v1.GetBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingResponse;
  return proto.swift.booking.v1.GetBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingResponse}
 */
proto.swift.booking.v1.GetBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.GetBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingResponse} returns this
*/
proto.swift.booking.v1.GetBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingResponse} returns this
 */
proto.swift.booking.v1.GetBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingEventsRequest}
 */
proto.swift.booking.v1.GetBookingEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingEventsRequest;
  return proto.swift.booking.v1.GetBookingEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingEventsRequest}
 */
proto.swift.booking.v1.GetBookingEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetBookingEventsRequest} returns this
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.GetBookingEventsRequest} returns this
 */
proto.swift.booking.v1.GetBookingEventsRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.GetBookingEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.swift.booking.v1.BookingEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingEventsResponse}
 */
proto.swift.booking.v1.GetBookingEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingEventsResponse;
  return proto.swift.booking.v1.GetBookingEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingEventsResponse}
 */
proto.swift.booking.v1.GetBookingEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingEvent;
      reader.readMessage(value,proto.swift.booking.v1.BookingEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.BookingEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BookingEvent events = 1;
 * @return {!Array<!proto.swift.booking.v1.BookingEvent>}
 */
proto.swift.booking.v1.GetBookingEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.BookingEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.BookingEvent, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.BookingEvent>} value
 * @return {!proto.swift.booking.v1.GetBookingEventsResponse} returns this
*/
proto.swift.booking.v1.GetBookingEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.BookingEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingEvent}
 */
proto.swift.booking.v1.GetBookingEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.BookingEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.GetBookingEventsResponse} returns this
 */
proto.swift.booking.v1.GetBookingEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
query: (f = msg.getQuery()) && proto.swift.booking.v1.BookingStatsQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingStatsRequest}
 */
proto.swift.booking.v1.GetBookingStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingStatsRequest;
  return proto.swift.booking.v1.GetBookingStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingStatsRequest}
 */
proto.swift.booking.v1.GetBookingStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingStatsQuery;
      reader.readMessage(value,proto.swift.booking.v1.BookingStatsQuery.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingStatsQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingStatsQuery query = 1;
 * @return {?proto.swift.booking.v1.BookingStatsQuery}
 */
proto.swift.booking.v1.GetBookingStatsRequest.prototype.getQuery = function() {
  return /** @type{?proto.swift.booking.v1.BookingStatsQuery} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingStatsQuery, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingStatsQuery|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingStatsRequest} returns this
*/
proto.swift.booking.v1.GetBookingStatsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingStatsRequest} returns this
 */
proto.swift.booking.v1.GetBookingStatsRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingStatsRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
stats: (f = msg.getStats()) && proto.swift.booking.v1.BookingStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingStatsResponse}
 */
proto.swift.booking.v1.GetBookingStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingStatsResponse;
  return proto.swift.booking.v1.GetBookingStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingStatsResponse}
 */
proto.swift.booking.v1.GetBookingStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingStats;
      reader.readMessage(value,proto.swift.booking.v1.BookingStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingStats stats = 1;
 * @return {?proto.swift.booking.v1.BookingStats}
 */
proto.swift.booking.v1.GetBookingStatsResponse.prototype.getStats = function() {
  return /** @type{?proto.swift.booking.v1.BookingStats} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingStats, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingStats|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingStatsResponse} returns this
*/
proto.swift.booking.v1.GetBookingStatsResponse.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingStatsResponse} returns this
 */
proto.swift.booking.v1.GetBookingStatsResponse.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingStatsResponse.prototype.hasStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
asQuote: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateBookingRequest}
 */
proto.swift.booking.v1.CreateBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateBookingRequest;
  return proto.swift.booking.v1.CreateBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateBookingRequest}
 */
proto.swift.booking.v1.CreateBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getAsQuote();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.CreateBookingRequest} returns this
*/
proto.swift.booking.v1.CreateBookingRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateBookingRequest} returns this
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool as_quote = 2;
 * @return {boolean}
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.getAsQuote = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.CreateBookingRequest} returns this
 */
proto.swift.booking.v1.CreateBookingRequest.prototype.setAsQuote = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.CreateBookingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
nominationsList: jspb.Message.toObjectList(msg.getNominationsList(),
    booking_v1_nomination_pb.Nomination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateBookingResponse}
 */
proto.swift.booking.v1.CreateBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateBookingResponse;
  return proto.swift.booking.v1.CreateBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateBookingResponse}
 */
proto.swift.booking.v1.CreateBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.addNominations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getNominationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.CreateBookingResponse} returns this
*/
proto.swift.booking.v1.CreateBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateBookingResponse} returns this
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Nomination nominations = 2;
 * @return {!Array<!proto.swift.booking.v1.Nomination>}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.getNominationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Nomination>} */ (
    jspb.Message.getRepeatedWrapperField(this, booking_v1_nomination_pb.Nomination, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Nomination>} value
 * @return {!proto.swift.booking.v1.CreateBookingResponse} returns this
*/
proto.swift.booking.v1.CreateBookingResponse.prototype.setNominationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Nomination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.addNominations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Nomination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.CreateBookingResponse} returns this
 */
proto.swift.booking.v1.CreateBookingResponse.prototype.clearNominationsList = function() {
  return this.setNominationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreatePreAcceptedBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
nomination: (f = msg.getNomination()) && booking_v1_nomination_pb.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreatePreAcceptedBookingRequest;
  return proto.swift.booking.v1.CreatePreAcceptedBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.setNomination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreatePreAcceptedBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getNomination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} returns this
*/
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} returns this
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Nomination nomination = 2;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.getNomination = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, booking_v1_nomination_pb.Nomination, 2));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} returns this
*/
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.setNomination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingRequest} returns this
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.clearNomination = function() {
  return this.setNomination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingRequest.prototype.hasNomination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreatePreAcceptedBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreatePreAcceptedBookingResponse;
  return proto.swift.booking.v1.CreatePreAcceptedBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreatePreAcceptedBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse} returns this
*/
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreatePreAcceptedBookingResponse} returns this
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreatePreAcceptedBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditBookingRequest}
 */
proto.swift.booking.v1.EditBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditBookingRequest;
  return proto.swift.booking.v1.EditBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditBookingRequest}
 */
proto.swift.booking.v1.EditBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.EditBookingRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.EditBookingRequest} returns this
*/
proto.swift.booking.v1.EditBookingRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditBookingRequest} returns this
 */
proto.swift.booking.v1.EditBookingRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditBookingRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditBookingResponse}
 */
proto.swift.booking.v1.EditBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditBookingResponse;
  return proto.swift.booking.v1.EditBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditBookingResponse}
 */
proto.swift.booking.v1.EditBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.EditBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.EditBookingResponse} returns this
*/
proto.swift.booking.v1.EditBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditBookingResponse} returns this
 */
proto.swift.booking.v1.EditBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.QuoteBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.QuoteBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.QuoteBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.QuoteBookingRequest}
 */
proto.swift.booking.v1.QuoteBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.QuoteBookingRequest;
  return proto.swift.booking.v1.QuoteBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.QuoteBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.QuoteBookingRequest}
 */
proto.swift.booking.v1.QuoteBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.QuoteBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.QuoteBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.QuoteBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.QuoteBookingRequest} returns this
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.QuoteBookingRequest} returns this
 */
proto.swift.booking.v1.QuoteBookingRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.QuoteBookingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.QuoteBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.QuoteBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.QuoteBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
nominationsList: jspb.Message.toObjectList(msg.getNominationsList(),
    booking_v1_nomination_pb.Nomination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.QuoteBookingResponse}
 */
proto.swift.booking.v1.QuoteBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.QuoteBookingResponse;
  return proto.swift.booking.v1.QuoteBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.QuoteBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.QuoteBookingResponse}
 */
proto.swift.booking.v1.QuoteBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.addNominations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.QuoteBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.QuoteBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.QuoteBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getNominationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.QuoteBookingResponse} returns this
*/
proto.swift.booking.v1.QuoteBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.QuoteBookingResponse} returns this
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Nomination nominations = 2;
 * @return {!Array<!proto.swift.booking.v1.Nomination>}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.getNominationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Nomination>} */ (
    jspb.Message.getRepeatedWrapperField(this, booking_v1_nomination_pb.Nomination, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Nomination>} value
 * @return {!proto.swift.booking.v1.QuoteBookingResponse} returns this
*/
proto.swift.booking.v1.QuoteBookingResponse.prototype.setNominationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Nomination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.addNominations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Nomination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.QuoteBookingResponse} returns this
 */
proto.swift.booking.v1.QuoteBookingResponse.prototype.clearNominationsList = function() {
  return this.setNominationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetQuotesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetQuotesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetQuotesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetQuotesRequest}
 */
proto.swift.booking.v1.GetQuotesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetQuotesRequest;
  return proto.swift.booking.v1.GetQuotesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetQuotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetQuotesRequest}
 */
proto.swift.booking.v1.GetQuotesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetQuotesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetQuotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetQuotesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetQuotesRequest} returns this
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.GetQuotesRequest} returns this
 */
proto.swift.booking.v1.GetQuotesRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.GetQuotesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetQuotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetQuotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetQuotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
nominationsList: jspb.Message.toObjectList(msg.getNominationsList(),
    booking_v1_nomination_pb.Nomination.toObject, includeInstance),
nominatedContract: (f = msg.getNominatedContract()) && contract_v1_contract_pb.NominatedContract.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetQuotesResponse}
 */
proto.swift.booking.v1.GetQuotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetQuotesResponse;
  return proto.swift.booking.v1.GetQuotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetQuotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetQuotesResponse}
 */
proto.swift.booking.v1.GetQuotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.addNominations(value);
      break;
    case 2:
      var value = new contract_v1_contract_pb.NominatedContract;
      reader.readMessage(value,contract_v1_contract_pb.NominatedContract.deserializeBinaryFromReader);
      msg.setNominatedContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetQuotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetQuotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetQuotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNominationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
  f = message.getNominatedContract();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      contract_v1_contract_pb.NominatedContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Nomination nominations = 1;
 * @return {!Array<!proto.swift.booking.v1.Nomination>}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.getNominationsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Nomination>} */ (
    jspb.Message.getRepeatedWrapperField(this, booking_v1_nomination_pb.Nomination, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Nomination>} value
 * @return {!proto.swift.booking.v1.GetQuotesResponse} returns this
*/
proto.swift.booking.v1.GetQuotesResponse.prototype.setNominationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.Nomination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.addNominations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.Nomination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.GetQuotesResponse} returns this
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.clearNominationsList = function() {
  return this.setNominationsList([]);
};


/**
 * optional swift.contract.v1.NominatedContract nominated_contract = 2;
 * @return {?proto.swift.contract.v1.NominatedContract}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.getNominatedContract = function() {
  return /** @type{?proto.swift.contract.v1.NominatedContract} */ (
    jspb.Message.getWrapperField(this, contract_v1_contract_pb.NominatedContract, 2));
};


/**
 * @param {?proto.swift.contract.v1.NominatedContract|undefined} value
 * @return {!proto.swift.booking.v1.GetQuotesResponse} returns this
*/
proto.swift.booking.v1.GetQuotesResponse.prototype.setNominatedContract = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetQuotesResponse} returns this
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.clearNominatedContract = function() {
  return this.setNominatedContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetQuotesResponse.prototype.hasNominatedContract = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.AcceptBookingRequest.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.AcceptBookingRequest.DataCase = {
  DATA_NOT_SET: 0,
  ACCEPT_DIRECT_SUPPLIER: 3,
  ACCEPT_MANAGED_SUPPLIER: 4
};

/**
 * @return {proto.swift.booking.v1.AcceptBookingRequest.DataCase}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.getDataCase = function() {
  return /** @type {proto.swift.booking.v1.AcceptBookingRequest.DataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.AcceptBookingRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.AcceptBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.AcceptBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingRef: jspb.Message.getFieldWithDefault(msg, 1, ""),
useContract: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
acceptDirectSupplier: (f = msg.getAcceptDirectSupplier()) && proto.swift.booking.v1.AcceptDirectSupplier.toObject(includeInstance, f),
acceptManagedSupplier: (f = msg.getAcceptManagedSupplier()) && proto.swift.booking.v1.AcceptManagedSupplier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.AcceptBookingRequest}
 */
proto.swift.booking.v1.AcceptBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.AcceptBookingRequest;
  return proto.swift.booking.v1.AcceptBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.AcceptBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.AcceptBookingRequest}
 */
proto.swift.booking.v1.AcceptBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseContract(value);
      break;
    case 3:
      var value = new proto.swift.booking.v1.AcceptDirectSupplier;
      reader.readMessage(value,proto.swift.booking.v1.AcceptDirectSupplier.deserializeBinaryFromReader);
      msg.setAcceptDirectSupplier(value);
      break;
    case 4:
      var value = new proto.swift.booking.v1.AcceptManagedSupplier;
      reader.readMessage(value,proto.swift.booking.v1.AcceptManagedSupplier.deserializeBinaryFromReader);
      msg.setAcceptManagedSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.AcceptBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.AcceptBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUseContract();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAcceptDirectSupplier();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.swift.booking.v1.AcceptDirectSupplier.serializeBinaryToWriter
    );
  }
  f = message.getAcceptManagedSupplier();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.swift.booking.v1.AcceptManagedSupplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional string booking_ref = 1;
 * @return {string}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool use_contract = 2;
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.getUseContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.setUseContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional AcceptDirectSupplier accept_direct_supplier = 3;
 * @return {?proto.swift.booking.v1.AcceptDirectSupplier}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.getAcceptDirectSupplier = function() {
  return /** @type{?proto.swift.booking.v1.AcceptDirectSupplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.AcceptDirectSupplier, 3));
};


/**
 * @param {?proto.swift.booking.v1.AcceptDirectSupplier|undefined} value
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
*/
proto.swift.booking.v1.AcceptBookingRequest.prototype.setAcceptDirectSupplier = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.swift.booking.v1.AcceptBookingRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.clearAcceptDirectSupplier = function() {
  return this.setAcceptDirectSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.hasAcceptDirectSupplier = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AcceptManagedSupplier accept_managed_supplier = 4;
 * @return {?proto.swift.booking.v1.AcceptManagedSupplier}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.getAcceptManagedSupplier = function() {
  return /** @type{?proto.swift.booking.v1.AcceptManagedSupplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.AcceptManagedSupplier, 4));
};


/**
 * @param {?proto.swift.booking.v1.AcceptManagedSupplier|undefined} value
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
*/
proto.swift.booking.v1.AcceptBookingRequest.prototype.setAcceptManagedSupplier = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.swift.booking.v1.AcceptBookingRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptBookingRequest} returns this
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.clearAcceptManagedSupplier = function() {
  return this.setAcceptManagedSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingRequest.prototype.hasAcceptManagedSupplier = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.AcceptBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.AcceptBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
nominatedContract: (f = msg.getNominatedContract()) && contract_v1_contract_pb.NominatedContract.toObject(includeInstance, f),
nominations: (f = msg.getNominations()) && booking_v1_nomination_pb.Nomination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.AcceptBookingResponse}
 */
proto.swift.booking.v1.AcceptBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.AcceptBookingResponse;
  return proto.swift.booking.v1.AcceptBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.AcceptBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.AcceptBookingResponse}
 */
proto.swift.booking.v1.AcceptBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = new contract_v1_contract_pb.NominatedContract;
      reader.readMessage(value,contract_v1_contract_pb.NominatedContract.deserializeBinaryFromReader);
      msg.setNominatedContract(value);
      break;
    case 3:
      var value = new booking_v1_nomination_pb.Nomination;
      reader.readMessage(value,booking_v1_nomination_pb.Nomination.deserializeBinaryFromReader);
      msg.setNominations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.AcceptBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.AcceptBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.AcceptBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getNominatedContract();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      contract_v1_contract_pb.NominatedContract.serializeBinaryToWriter
    );
  }
  f = message.getNominations();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      booking_v1_nomination_pb.Nomination.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
*/
proto.swift.booking.v1.AcceptBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional swift.contract.v1.NominatedContract nominated_contract = 2;
 * @return {?proto.swift.contract.v1.NominatedContract}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.getNominatedContract = function() {
  return /** @type{?proto.swift.contract.v1.NominatedContract} */ (
    jspb.Message.getWrapperField(this, contract_v1_contract_pb.NominatedContract, 2));
};


/**
 * @param {?proto.swift.contract.v1.NominatedContract|undefined} value
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
*/
proto.swift.booking.v1.AcceptBookingResponse.prototype.setNominatedContract = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.clearNominatedContract = function() {
  return this.setNominatedContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.hasNominatedContract = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Nomination nominations = 3;
 * @return {?proto.swift.booking.v1.Nomination}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.getNominations = function() {
  return /** @type{?proto.swift.booking.v1.Nomination} */ (
    jspb.Message.getWrapperField(this, booking_v1_nomination_pb.Nomination, 3));
};


/**
 * @param {?proto.swift.booking.v1.Nomination|undefined} value
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
*/
proto.swift.booking.v1.AcceptBookingResponse.prototype.setNominations = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.AcceptBookingResponse} returns this
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.clearNominations = function() {
  return this.setNominations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.AcceptBookingResponse.prototype.hasNominations = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportRefRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportRefRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
transportRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportRefRequest}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportRefRequest;
  return proto.swift.booking.v1.UpdateTransportRefRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportRefRequest}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportRefRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportRefRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportRefRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTransportRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateTransportRefRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string transport_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.getTransportRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateTransportRefRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportRefRequest.prototype.setTransportRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportRefResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportRefResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportRefResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportRefResponse}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportRefResponse;
  return proto.swift.booking.v1.UpdateTransportRefResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportRefResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportRefResponse}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportRefResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportRefResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportRefResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateTransportRefResponse} returns this
*/
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateTransportRefResponse} returns this
 */
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateTransportRefResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateEstimatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateEstimatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
estimatedTimeDeparture: (f = msg.getEstimatedTimeDeparture()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
estimatedTimeArrival: (f = msg.getEstimatedTimeArrival()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateEstimatesRequest;
  return proto.swift.booking.v1.UpdateEstimatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeDeparture(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedTimeArrival(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateEstimatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateEstimatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateEstimatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEstimatedTimeDeparture();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTimeArrival();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest} returns this
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp estimated_time_departure = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.getEstimatedTimeDeparture = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest} returns this
*/
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.setEstimatedTimeDeparture = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest} returns this
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.clearEstimatedTimeDeparture = function() {
  return this.setEstimatedTimeDeparture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.hasEstimatedTimeDeparture = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp estimated_time_arrival = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.getEstimatedTimeArrival = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest} returns this
*/
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.setEstimatedTimeArrival = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateEstimatesRequest} returns this
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.clearEstimatedTimeArrival = function() {
  return this.setEstimatedTimeArrival(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateEstimatesRequest.prototype.hasEstimatedTimeArrival = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateEstimatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateEstimatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateEstimatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateEstimatesResponse}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateEstimatesResponse;
  return proto.swift.booking.v1.UpdateEstimatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateEstimatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateEstimatesResponse}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateEstimatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateEstimatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateEstimatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateEstimatesResponse} returns this
*/
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateEstimatesResponse} returns this
 */
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateEstimatesResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportModeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportModeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
transportMode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportModeRequest}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportModeRequest;
  return proto.swift.booking.v1.UpdateTransportModeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportModeRequest}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 3:
      var value = /** @type {!proto.swift.common.TransportMode} */ (reader.readEnum());
      msg.setTransportMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportModeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportModeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportModeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportMode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateTransportModeRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateTransportModeRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional swift.common.TransportMode transport_mode = 3;
 * @return {!proto.swift.common.TransportMode}
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.getTransportMode = function() {
  return /** @type {!proto.swift.common.TransportMode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @return {!proto.swift.booking.v1.UpdateTransportModeRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportModeRequest.prototype.setTransportMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportModeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportModeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportModeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportModeResponse}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportModeResponse;
  return proto.swift.booking.v1.UpdateTransportModeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportModeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportModeResponse}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportModeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportModeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportModeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateTransportModeResponse} returns this
*/
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateTransportModeResponse} returns this
 */
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateTransportModeResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.UpdateStatusRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.swift.booking.v1.BookingEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateStatusRequest}
 */
proto.swift.booking.v1.UpdateStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateStatusRequest;
  return proto.swift.booking.v1.UpdateStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateStatusRequest}
 */
proto.swift.booking.v1.UpdateStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingEvent;
      reader.readMessage(value,proto.swift.booking.v1.BookingEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.swift.booking.v1.BookingEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BookingEvent events = 1;
 * @return {!Array<!proto.swift.booking.v1.BookingEvent>}
 */
proto.swift.booking.v1.UpdateStatusRequest.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.BookingEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.BookingEvent, 1));
};


/**
 * @param {!Array<!proto.swift.booking.v1.BookingEvent>} value
 * @return {!proto.swift.booking.v1.UpdateStatusRequest} returns this
*/
proto.swift.booking.v1.UpdateStatusRequest.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.swift.booking.v1.BookingEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingEvent}
 */
proto.swift.booking.v1.UpdateStatusRequest.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.swift.booking.v1.BookingEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.UpdateStatusRequest} returns this
 */
proto.swift.booking.v1.UpdateStatusRequest.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateStatusResponse}
 */
proto.swift.booking.v1.UpdateStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateStatusResponse;
  return proto.swift.booking.v1.UpdateStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateStatusResponse}
 */
proto.swift.booking.v1.UpdateStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateStatusResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateStatusResponse} returns this
*/
proto.swift.booking.v1.UpdateStatusResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateStatusResponse} returns this
 */
proto.swift.booking.v1.UpdateStatusResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateStatusResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateBookingItemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f),
reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsRequest}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateBookingItemsRequest;
  return proto.swift.booking.v1.UpdateBookingItemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsRequest}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateBookingItemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateBookingItemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateBookingItemsRequest} returns this
*/
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsRequest} returns this
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateBookingItemsRequest} returns this
 */
proto.swift.booking.v1.UpdateBookingItemsRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateBookingItemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateBookingItemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsResponse}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateBookingItemsResponse;
  return proto.swift.booking.v1.UpdateBookingItemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateBookingItemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsResponse}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateBookingItemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateBookingItemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateBookingItemsResponse} returns this
*/
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateBookingItemsResponse} returns this
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateBookingItemsResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.UpdateRequirementsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateRequirementsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateRequirementsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
requirementsList: jspb.Message.toObjectList(msg.getRequirementsList(),
    proto.swift.booking.v1.Requirement.toObject, includeInstance),
invoiceRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
loadingRef: jspb.Message.getFieldWithDefault(msg, 4, ""),
unloadingRef: jspb.Message.getFieldWithDefault(msg, 5, ""),
externalRef: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateRequirementsRequest;
  return proto.swift.booking.v1.UpdateRequirementsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Requirement;
      reader.readMessage(value,proto.swift.booking.v1.Requirement.deserializeBinaryFromReader);
      msg.addRequirements(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceRef(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoadingRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnloadingRef(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateRequirementsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateRequirementsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateRequirementsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRequirementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.Requirement.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoadingRef();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnloadingRef();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalRef();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Requirement requirements = 2;
 * @return {!Array<!proto.swift.booking.v1.Requirement>}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getRequirementsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Requirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Requirement, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Requirement>} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
*/
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setRequirementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Requirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Requirement}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.addRequirements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Requirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.clearRequirementsList = function() {
  return this.setRequirementsList([]);
};


/**
 * optional string invoice_ref = 3;
 * @return {string}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getInvoiceRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setInvoiceRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loading_ref = 4;
 * @return {string}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getLoadingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setLoadingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string unloading_ref = 5;
 * @return {string}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getUnloadingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setUnloadingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_ref = 6;
 * @return {string}
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.getExternalRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsRequest} returns this
 */
proto.swift.booking.v1.UpdateRequirementsRequest.prototype.setExternalRef = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateRequirementsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateRequirementsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateRequirementsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateRequirementsResponse}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateRequirementsResponse;
  return proto.swift.booking.v1.UpdateRequirementsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateRequirementsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateRequirementsResponse}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateRequirementsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateRequirementsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateRequirementsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateRequirementsResponse} returns this
*/
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateRequirementsResponse} returns this
 */
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateRequirementsResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateInternalNoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
internalNote: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateInternalNoteRequest}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateInternalNoteRequest;
  return proto.swift.booking.v1.UpdateInternalNoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateInternalNoteRequest}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateInternalNoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateInternalNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateInternalNoteRequest} returns this
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateInternalNoteRequest} returns this
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_note = 3;
 * @return {string}
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UpdateInternalNoteRequest} returns this
 */
proto.swift.booking.v1.UpdateInternalNoteRequest.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateInternalNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateInternalNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateInternalNoteResponse}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateInternalNoteResponse;
  return proto.swift.booking.v1.UpdateInternalNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateInternalNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateInternalNoteResponse}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateInternalNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateInternalNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateInternalNoteResponse} returns this
*/
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateInternalNoteResponse} returns this
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateInternalNoteResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteBookingRequest}
 */
proto.swift.booking.v1.DeleteBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteBookingRequest;
  return proto.swift.booking.v1.DeleteBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteBookingRequest}
 */
proto.swift.booking.v1.DeleteBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteBookingRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteBookingRequest} returns this
 */
proto.swift.booking.v1.DeleteBookingRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteBookingResponse}
 */
proto.swift.booking.v1.DeleteBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteBookingResponse;
  return proto.swift.booking.v1.DeleteBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteBookingResponse}
 */
proto.swift.booking.v1.DeleteBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteBookingResponse.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteBookingResponse} returns this
 */
proto.swift.booking.v1.DeleteBookingResponse.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.SetShipmentRefRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetShipmentRefRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetShipmentRefRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetShipmentRefRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingRefsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
shipmentRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest}
 */
proto.swift.booking.v1.SetShipmentRefRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetShipmentRefRequest;
  return proto.swift.booking.v1.SetShipmentRefRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetShipmentRefRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest}
 */
proto.swift.booking.v1.SetShipmentRefRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingRefs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShipmentRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetShipmentRefRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetShipmentRefRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetShipmentRefRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingRefsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getShipmentRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string booking_refs = 1;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.getBookingRefsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest} returns this
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.setBookingRefsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest} returns this
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.addBookingRefs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest} returns this
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.clearBookingRefsList = function() {
  return this.setBookingRefsList([]);
};


/**
 * optional string shipment_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.getShipmentRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SetShipmentRefRequest} returns this
 */
proto.swift.booking.v1.SetShipmentRefRequest.prototype.setShipmentRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetShipmentRefResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetShipmentRefResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetShipmentRefResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetShipmentRefResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetShipmentRefResponse}
 */
proto.swift.booking.v1.SetShipmentRefResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetShipmentRefResponse;
  return proto.swift.booking.v1.SetShipmentRefResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetShipmentRefResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetShipmentRefResponse}
 */
proto.swift.booking.v1.SetShipmentRefResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetShipmentRefResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetShipmentRefResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetShipmentRefResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetShipmentRefResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetBookingPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetBookingPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetBookingPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
price: jspb.Message.getFieldWithDefault(msg, 2, 0),
priceCurrency: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetBookingPriceRequest}
 */
proto.swift.booking.v1.SetBookingPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetBookingPriceRequest;
  return proto.swift.booking.v1.SetBookingPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetBookingPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetBookingPriceRequest}
 */
proto.swift.booking.v1.SetBookingPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetBookingPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetBookingPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetBookingPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SetBookingPriceRequest} returns this
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 price = 2;
 * @return {number}
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SetBookingPriceRequest} returns this
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string price_currency = 3;
 * @return {string}
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.getPriceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SetBookingPriceRequest} returns this
 */
proto.swift.booking.v1.SetBookingPriceRequest.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetBookingPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetBookingPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetBookingPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetBookingPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetBookingPriceResponse}
 */
proto.swift.booking.v1.SetBookingPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetBookingPriceResponse;
  return proto.swift.booking.v1.SetBookingPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetBookingPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetBookingPriceResponse}
 */
proto.swift.booking.v1.SetBookingPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetBookingPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetBookingPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetBookingPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetBookingPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.SetBookingPriceResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.SetBookingPriceResponse} returns this
*/
proto.swift.booking.v1.SetBookingPriceResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetBookingPriceResponse} returns this
 */
proto.swift.booking.v1.SetBookingPriceResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetBookingPriceResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportOperatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorRequest}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportOperatorRequest;
  return proto.swift.booking.v1.UpdateTransportOperatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorRequest}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportOperatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorRequest} returns this
*/
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorRequest} returns this
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateTransportOperatorRequest.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UpdateTransportOperatorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorResponse}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UpdateTransportOperatorResponse;
  return proto.swift.booking.v1.UpdateTransportOperatorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorResponse}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UpdateTransportOperatorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UpdateTransportOperatorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorResponse} returns this
*/
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UpdateTransportOperatorResponse} returns this
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UpdateTransportOperatorResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
action: (f = msg.getAction()) && proto.swift.booking.v1.Action.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetActionRequest}
 */
proto.swift.booking.v1.SetActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetActionRequest;
  return proto.swift.booking.v1.SetActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetActionRequest}
 */
proto.swift.booking.v1.SetActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Action;
      reader.readMessage(value,proto.swift.booking.v1.Action.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SetActionRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SetActionRequest} returns this
 */
proto.swift.booking.v1.SetActionRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Action action = 2;
 * @return {?proto.swift.booking.v1.Action}
 */
proto.swift.booking.v1.SetActionRequest.prototype.getAction = function() {
  return /** @type{?proto.swift.booking.v1.Action} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Action, 2));
};


/**
 * @param {?proto.swift.booking.v1.Action|undefined} value
 * @return {!proto.swift.booking.v1.SetActionRequest} returns this
*/
proto.swift.booking.v1.SetActionRequest.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetActionRequest} returns this
 */
proto.swift.booking.v1.SetActionRequest.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetActionRequest.prototype.hasAction = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetActionResponse}
 */
proto.swift.booking.v1.SetActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetActionResponse;
  return proto.swift.booking.v1.SetActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetActionResponse}
 */
proto.swift.booking.v1.SetActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.SetActionResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.SetActionResponse} returns this
*/
proto.swift.booking.v1.SetActionResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetActionResponse} returns this
 */
proto.swift.booking.v1.SetActionResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetActionResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingChargeableWeightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightRequest}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingChargeableWeightRequest;
  return proto.swift.booking.v1.GetBookingChargeableWeightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightRequest}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingChargeableWeightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} returns this
*/
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightRequest} returns this
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingChargeableWeightRequest.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingChargeableWeightResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightResponse}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingChargeableWeightResponse;
  return proto.swift.booking.v1.GetBookingChargeableWeightResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightResponse}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingChargeableWeightResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingChargeableWeightResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightResponse} returns this
*/
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingChargeableWeightResponse} returns this
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingChargeableWeightResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.InquireBookingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.InquireBookingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.InquireBookingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
returnIfExists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
filter: (f = msg.getFilter()) && proto.swift.booking.v1.BookingFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.InquireBookingRequest}
 */
proto.swift.booking.v1.InquireBookingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.InquireBookingRequest;
  return proto.swift.booking.v1.InquireBookingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.InquireBookingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.InquireBookingRequest}
 */
proto.swift.booking.v1.InquireBookingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnIfExists(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.BookingFilter;
      reader.readMessage(value,proto.swift.booking.v1.BookingFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.InquireBookingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.InquireBookingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.InquireBookingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnIfExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.BookingFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool return_if_exists = 1;
 * @return {boolean}
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.getReturnIfExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.InquireBookingRequest} returns this
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.setReturnIfExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional BookingFilter filter = 2;
 * @return {?proto.swift.booking.v1.BookingFilter}
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.getFilter = function() {
  return /** @type{?proto.swift.booking.v1.BookingFilter} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingFilter, 2));
};


/**
 * @param {?proto.swift.booking.v1.BookingFilter|undefined} value
 * @return {!proto.swift.booking.v1.InquireBookingRequest} returns this
*/
proto.swift.booking.v1.InquireBookingRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.InquireBookingRequest} returns this
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.InquireBookingRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.InquireBookingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.InquireBookingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.InquireBookingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingExists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.InquireBookingResponse}
 */
proto.swift.booking.v1.InquireBookingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.InquireBookingResponse;
  return proto.swift.booking.v1.InquireBookingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.InquireBookingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.InquireBookingResponse}
 */
proto.swift.booking.v1.InquireBookingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBookingExists(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.InquireBookingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.InquireBookingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.InquireBookingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool booking_exists = 1;
 * @return {boolean}
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.getBookingExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.InquireBookingResponse} returns this
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.setBookingExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Booking booking = 2;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 2));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.InquireBookingResponse} returns this
*/
proto.swift.booking.v1.InquireBookingResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.InquireBookingResponse} returns this
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.InquireBookingResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetFinancialMonthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetFinancialMonthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingRef: jspb.Message.getFieldWithDefault(msg, 2, ""),
financialMonth: (f = msg.getFinancialMonth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetFinancialMonthRequest;
  return proto.swift.booking.v1.SetFinancialMonthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingRef(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinancialMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetFinancialMonthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetFinancialMonthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetFinancialMonthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFinancialMonth();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 booking_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.getBookingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest} returns this
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.getBookingRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest} returns this
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.setBookingRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp financial_month = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.getFinancialMonth = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest} returns this
*/
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.setFinancialMonth = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetFinancialMonthRequest} returns this
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.clearFinancialMonth = function() {
  return this.setFinancialMonth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetFinancialMonthRequest.prototype.hasFinancialMonth = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetFinancialMonthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetFinancialMonthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetFinancialMonthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
booking: (f = msg.getBooking()) && proto.swift.booking.v1.Booking.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetFinancialMonthResponse}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetFinancialMonthResponse;
  return proto.swift.booking.v1.SetFinancialMonthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetFinancialMonthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetFinancialMonthResponse}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Booking;
      reader.readMessage(value,proto.swift.booking.v1.Booking.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetFinancialMonthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetFinancialMonthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetFinancialMonthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Booking.serializeBinaryToWriter
    );
  }
};


/**
 * optional Booking booking = 1;
 * @return {?proto.swift.booking.v1.Booking}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.getBooking = function() {
  return /** @type{?proto.swift.booking.v1.Booking} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Booking, 1));
};


/**
 * @param {?proto.swift.booking.v1.Booking|undefined} value
 * @return {!proto.swift.booking.v1.SetFinancialMonthResponse} returns this
*/
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetFinancialMonthResponse} returns this
 */
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetFinancialMonthResponse.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.swift.booking.v1);
