/**
 * @fileoverview gRPC-Web generated client stub for swift.iam.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: iam/v1/signup.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var crm_v1_address_pb = require('../../crm/v1/address_pb.js')

var iam_v1_user_pb = require('../../iam/v1/user_pb.js')

var iam_v1_usergroup_pb = require('../../iam/v1/usergroup_pb.js')
const proto = {};
proto.swift = {};
proto.swift.iam = {};
proto.swift.iam.v1 = require('./signup_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.SignupServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.iam.v1.SignupServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.ValidateJoinCodeRequest,
 *   !proto.swift.iam.v1.ValidateJoinCodeResponse>}
 */
const methodDescriptor_SignupService_ValidateJoinCode = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.SignupService/ValidateJoinCode',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.ValidateJoinCodeRequest,
  proto.swift.iam.v1.ValidateJoinCodeResponse,
  /**
   * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.ValidateJoinCodeResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.ValidateJoinCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.ValidateJoinCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.SignupServiceClient.prototype.validateJoinCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.SignupService/ValidateJoinCode',
      request,
      metadata || {},
      methodDescriptor_SignupService_ValidateJoinCode,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.ValidateJoinCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.ValidateJoinCodeResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.SignupServicePromiseClient.prototype.validateJoinCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.SignupService/ValidateJoinCode',
      request,
      metadata || {},
      methodDescriptor_SignupService_ValidateJoinCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.iam.v1.SignupUserRequest,
 *   !proto.swift.iam.v1.SignupUserResponse>}
 */
const methodDescriptor_SignupService_SignupUser = new grpc.web.MethodDescriptor(
  '/swift.iam.v1.SignupService/SignupUser',
  grpc.web.MethodType.UNARY,
  proto.swift.iam.v1.SignupUserRequest,
  proto.swift.iam.v1.SignupUserResponse,
  /**
   * @param {!proto.swift.iam.v1.SignupUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.iam.v1.SignupUserResponse.deserializeBinary
);


/**
 * @param {!proto.swift.iam.v1.SignupUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.iam.v1.SignupUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.iam.v1.SignupUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.iam.v1.SignupServiceClient.prototype.signupUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.iam.v1.SignupService/SignupUser',
      request,
      metadata || {},
      methodDescriptor_SignupService_SignupUser,
      callback);
};


/**
 * @param {!proto.swift.iam.v1.SignupUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.iam.v1.SignupUserResponse>}
 *     Promise that resolves to the response
 */
proto.swift.iam.v1.SignupServicePromiseClient.prototype.signupUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.iam.v1.SignupService/SignupUser',
      request,
      metadata || {},
      methodDescriptor_SignupService_SignupUser);
};


module.exports = proto.swift.iam.v1;

