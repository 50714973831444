// source: booking/v1/bookingtemplate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var common_query_pb = require('../../common/query_pb.js');
goog.object.extend(proto, common_query_pb);
var booking_v1_booking_pb = require('../../booking/v1/booking_pb.js');
goog.object.extend(proto, booking_v1_booking_pb);
goog.exportSymbol('proto.swift.booking.v1.BookingTemplate', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateBookingTemplateRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateBookingTemplateResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteBookingTemplateRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteBookingTemplateResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingTemplateRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetBookingTemplateResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListBookingTemplatesRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListBookingTemplatesResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemplateSorting', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemplateSorting.Field', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemplateSorting.Ordering', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BookingTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.BookingTemplate.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.BookingTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BookingTemplate.displayName = 'proto.swift.booking.v1.BookingTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TemplateSorting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TemplateSorting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TemplateSorting.displayName = 'proto.swift.booking.v1.TemplateSorting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListBookingTemplatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListBookingTemplatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListBookingTemplatesRequest.displayName = 'proto.swift.booking.v1.ListBookingTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListBookingTemplatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListBookingTemplatesResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListBookingTemplatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListBookingTemplatesResponse.displayName = 'proto.swift.booking.v1.ListBookingTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingTemplateRequest.displayName = 'proto.swift.booking.v1.GetBookingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetBookingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetBookingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetBookingTemplateResponse.displayName = 'proto.swift.booking.v1.GetBookingTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateBookingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateBookingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateBookingTemplateRequest.displayName = 'proto.swift.booking.v1.CreateBookingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateBookingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateBookingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateBookingTemplateResponse.displayName = 'proto.swift.booking.v1.CreateBookingTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteBookingTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteBookingTemplateRequest.displayName = 'proto.swift.booking.v1.DeleteBookingTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteBookingTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteBookingTemplateResponse.displayName = 'proto.swift.booking.v1.DeleteBookingTemplateResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.BookingTemplate.repeatedFields_ = [6,7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BookingTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BookingTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BookingTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
senderAddressId: jspb.Message.getFieldWithDefault(msg, 4, 0),
receiverAddressId: jspb.Message.getFieldWithDefault(msg, 5, 0),
desiredTransportModesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
requirementsList: jspb.Message.toObjectList(msg.getRequirementsList(),
    booking_v1_booking_pb.Requirement.toObject, includeInstance),
attributesMap: (f = msg.getAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
transportSubmodeType: jspb.Message.getFieldWithDefault(msg, 9, 0),
transportSubmodeItemsList: jspb.Message.toObjectList(msg.getTransportSubmodeItemsList(),
    booking_v1_booking_pb.SubmodeItem.toObject, includeInstance),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
internalComment: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.BookingTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BookingTemplate;
  return proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BookingTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingTemplateId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderAddressId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverAddressId(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDesiredTransportModes(values[i]);
      }
      break;
    case 7:
      var value = new booking_v1_booking_pb.Requirement;
      reader.readMessage(value,booking_v1_booking_pb.Requirement.deserializeBinaryFromReader);
      msg.addRequirements(value);
      break;
    case 8:
      var value = msg.getAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = /** @type {!proto.swift.common.TransportSubmode} */ (reader.readEnum());
      msg.setTransportSubmodeType(value);
      break;
    case 10:
      var value = new booking_v1_booking_pb.SubmodeItem;
      reader.readMessage(value,booking_v1_booking_pb.SubmodeItem.deserializeBinaryFromReader);
      msg.addTransportSubmodeItems(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BookingTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BookingTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSenderAddressId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReceiverAddressId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDesiredTransportModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getRequirementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      booking_v1_booking_pb.Requirement.serializeBinaryToWriter
    );
  }
  f = message.getAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTransportSubmodeType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getTransportSubmodeItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      booking_v1_booking_pb.SubmodeItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalComment();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 booking_template_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getBookingTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setBookingTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
*/
proto.swift.booking.v1.BookingTemplate.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingTemplate.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 sender_address_id = 4;
 * @return {number}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getSenderAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setSenderAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 receiver_address_id = 5;
 * @return {number}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getReceiverAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setReceiverAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated swift.common.TransportMode desired_transport_modes = 6;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getDesiredTransportModesList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setDesiredTransportModesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.addDesiredTransportModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearDesiredTransportModesList = function() {
  return this.setDesiredTransportModesList([]);
};


/**
 * repeated Requirement requirements = 7;
 * @return {!Array<!proto.swift.booking.v1.Requirement>}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getRequirementsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Requirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, booking_v1_booking_pb.Requirement, 7));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Requirement>} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
*/
proto.swift.booking.v1.BookingTemplate.prototype.setRequirementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.swift.booking.v1.Requirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Requirement}
 */
proto.swift.booking.v1.BookingTemplate.prototype.addRequirements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.swift.booking.v1.Requirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearRequirementsList = function() {
  return this.setRequirementsList([]);
};


/**
 * map<string, string> attributes = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearAttributesMap = function() {
  this.getAttributesMap().clear();
  return this;
};


/**
 * optional swift.common.TransportSubmode transport_submode_type = 9;
 * @return {!proto.swift.common.TransportSubmode}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getTransportSubmodeType = function() {
  return /** @type {!proto.swift.common.TransportSubmode} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.swift.common.TransportSubmode} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setTransportSubmodeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated SubmodeItem transport_submode_items = 10;
 * @return {!Array<!proto.swift.booking.v1.SubmodeItem>}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getTransportSubmodeItemsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.SubmodeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, booking_v1_booking_pb.SubmodeItem, 10));
};


/**
 * @param {!Array<!proto.swift.booking.v1.SubmodeItem>} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
*/
proto.swift.booking.v1.BookingTemplate.prototype.setTransportSubmodeItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.swift.booking.v1.SubmodeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SubmodeItem}
 */
proto.swift.booking.v1.BookingTemplate.prototype.addTransportSubmodeItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.swift.booking.v1.SubmodeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearTransportSubmodeItemsList = function() {
  return this.setTransportSubmodeItemsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
*/
proto.swift.booking.v1.BookingTemplate.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingTemplate.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
*/
proto.swift.booking.v1.BookingTemplate.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.BookingTemplate.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string internal_comment = 13;
 * @return {string}
 */
proto.swift.booking.v1.BookingTemplate.prototype.getInternalComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BookingTemplate} returns this
 */
proto.swift.booking.v1.BookingTemplate.prototype.setInternalComment = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TemplateSorting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TemplateSorting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TemplateSorting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateSorting.toObject = function(includeInstance, msg) {
  var f, obj = {
field: jspb.Message.getFieldWithDefault(msg, 1, 0),
ordering: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TemplateSorting}
 */
proto.swift.booking.v1.TemplateSorting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TemplateSorting;
  return proto.swift.booking.v1.TemplateSorting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TemplateSorting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TemplateSorting}
 */
proto.swift.booking.v1.TemplateSorting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.TemplateSorting.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.TemplateSorting.Ordering} */ (reader.readEnum());
      msg.setOrdering(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TemplateSorting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TemplateSorting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TemplateSorting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateSorting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrdering();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.TemplateSorting.Ordering = {
  UNKNOWN: 0,
  ASC: 1,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.swift.booking.v1.TemplateSorting.Field = {
  BOOKING_TEMPLATE_ID: 0,
  NAME: 1
};

/**
 * optional Field field = 1;
 * @return {!proto.swift.booking.v1.TemplateSorting.Field}
 */
proto.swift.booking.v1.TemplateSorting.prototype.getField = function() {
  return /** @type {!proto.swift.booking.v1.TemplateSorting.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.TemplateSorting.Field} value
 * @return {!proto.swift.booking.v1.TemplateSorting} returns this
 */
proto.swift.booking.v1.TemplateSorting.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Ordering ordering = 2;
 * @return {!proto.swift.booking.v1.TemplateSorting.Ordering}
 */
proto.swift.booking.v1.TemplateSorting.prototype.getOrdering = function() {
  return /** @type {!proto.swift.booking.v1.TemplateSorting.Ordering} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.TemplateSorting.Ordering} value
 * @return {!proto.swift.booking.v1.TemplateSorting} returns this
 */
proto.swift.booking.v1.TemplateSorting.prototype.setOrdering = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListBookingTemplatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
pagination: (f = msg.getPagination()) && common_query_pb.Pagination.toObject(includeInstance, f),
sorting: (f = msg.getSorting()) && proto.swift.booking.v1.TemplateSorting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListBookingTemplatesRequest;
  return proto.swift.booking.v1.ListBookingTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_query_pb.Pagination;
      reader.readMessage(value,common_query_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.TemplateSorting;
      reader.readMessage(value,proto.swift.booking.v1.TemplateSorting.deserializeBinaryFromReader);
      msg.setSorting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListBookingTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListBookingTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_query_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSorting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.swift.booking.v1.TemplateSorting.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Pagination pagination = 1;
 * @return {?proto.swift.common.Pagination}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.getPagination = function() {
  return /** @type{?proto.swift.common.Pagination} */ (
    jspb.Message.getWrapperField(this, common_query_pb.Pagination, 1));
};


/**
 * @param {?proto.swift.common.Pagination|undefined} value
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest} returns this
*/
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest} returns this
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TemplateSorting sorting = 2;
 * @return {?proto.swift.booking.v1.TemplateSorting}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.getSorting = function() {
  return /** @type{?proto.swift.booking.v1.TemplateSorting} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TemplateSorting, 2));
};


/**
 * @param {?proto.swift.booking.v1.TemplateSorting|undefined} value
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest} returns this
*/
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.setSorting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesRequest} returns this
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.clearSorting = function() {
  return this.setSorting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.ListBookingTemplatesRequest.prototype.hasSorting = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListBookingTemplatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListBookingTemplatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
bookingTemplatesList: jspb.Message.toObjectList(msg.getBookingTemplatesList(),
    proto.swift.booking.v1.BookingTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesResponse}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListBookingTemplatesResponse;
  return proto.swift.booking.v1.ListBookingTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListBookingTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesResponse}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.BookingTemplate;
      reader.readMessage(value,proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader);
      msg.addBookingTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListBookingTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListBookingTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListBookingTemplatesResponse} returns this
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated BookingTemplate booking_templates = 2;
 * @return {!Array<!proto.swift.booking.v1.BookingTemplate>}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.getBookingTemplatesList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.BookingTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.BookingTemplate, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.BookingTemplate>} value
 * @return {!proto.swift.booking.v1.ListBookingTemplatesResponse} returns this
*/
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.setBookingTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.BookingTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.addBookingTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.BookingTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListBookingTemplatesResponse} returns this
 */
proto.swift.booking.v1.ListBookingTemplatesResponse.prototype.clearBookingTemplatesList = function() {
  return this.setBookingTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingTemplateRequest}
 */
proto.swift.booking.v1.GetBookingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingTemplateRequest;
  return proto.swift.booking.v1.GetBookingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingTemplateRequest}
 */
proto.swift.booking.v1.GetBookingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 booking_template_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetBookingTemplateRequest.prototype.getBookingTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetBookingTemplateRequest} returns this
 */
proto.swift.booking.v1.GetBookingTemplateRequest.prototype.setBookingTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetBookingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetBookingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplate: (f = msg.getBookingTemplate()) && proto.swift.booking.v1.BookingTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetBookingTemplateResponse}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetBookingTemplateResponse;
  return proto.swift.booking.v1.GetBookingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetBookingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetBookingTemplateResponse}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingTemplate;
      reader.readMessage(value,proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader);
      msg.setBookingTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetBookingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetBookingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetBookingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingTemplate booking_template = 1;
 * @return {?proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.getBookingTemplate = function() {
  return /** @type{?proto.swift.booking.v1.BookingTemplate} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingTemplate, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingTemplate|undefined} value
 * @return {!proto.swift.booking.v1.GetBookingTemplateResponse} returns this
*/
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.setBookingTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetBookingTemplateResponse} returns this
 */
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.clearBookingTemplate = function() {
  return this.setBookingTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetBookingTemplateResponse.prototype.hasBookingTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateBookingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplate: (f = msg.getBookingTemplate()) && proto.swift.booking.v1.BookingTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateRequest}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateBookingTemplateRequest;
  return proto.swift.booking.v1.CreateBookingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateRequest}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingTemplate;
      reader.readMessage(value,proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader);
      msg.setBookingTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateBookingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateBookingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingTemplate booking_template = 1;
 * @return {?proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.getBookingTemplate = function() {
  return /** @type{?proto.swift.booking.v1.BookingTemplate} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingTemplate, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingTemplate|undefined} value
 * @return {!proto.swift.booking.v1.CreateBookingTemplateRequest} returns this
*/
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.setBookingTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateRequest} returns this
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.clearBookingTemplate = function() {
  return this.setBookingTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateBookingTemplateRequest.prototype.hasBookingTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateBookingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateBookingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplate: (f = msg.getBookingTemplate()) && proto.swift.booking.v1.BookingTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateResponse}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateBookingTemplateResponse;
  return proto.swift.booking.v1.CreateBookingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateBookingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateResponse}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.BookingTemplate;
      reader.readMessage(value,proto.swift.booking.v1.BookingTemplate.deserializeBinaryFromReader);
      msg.setBookingTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateBookingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateBookingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.BookingTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingTemplate booking_template = 1;
 * @return {?proto.swift.booking.v1.BookingTemplate}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.getBookingTemplate = function() {
  return /** @type{?proto.swift.booking.v1.BookingTemplate} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BookingTemplate, 1));
};


/**
 * @param {?proto.swift.booking.v1.BookingTemplate|undefined} value
 * @return {!proto.swift.booking.v1.CreateBookingTemplateResponse} returns this
*/
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.setBookingTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateBookingTemplateResponse} returns this
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.clearBookingTemplate = function() {
  return this.setBookingTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateBookingTemplateResponse.prototype.hasBookingTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteBookingTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
bookingTemplateId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteBookingTemplateRequest}
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteBookingTemplateRequest;
  return proto.swift.booking.v1.DeleteBookingTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteBookingTemplateRequest}
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteBookingTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingTemplateId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 booking_template_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.prototype.getBookingTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteBookingTemplateRequest} returns this
 */
proto.swift.booking.v1.DeleteBookingTemplateRequest.prototype.setBookingTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteBookingTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteBookingTemplateResponse}
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteBookingTemplateResponse;
  return proto.swift.booking.v1.DeleteBookingTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteBookingTemplateResponse}
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteBookingTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteBookingTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteBookingTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.swift.booking.v1);
