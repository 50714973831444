import React from 'react'

import { Dropdown, Form } from 'antd'
import { ItemType } from 'rc-menu/es/interface'

import { SecondaryButton } from 'components/Buttons'

import './Dropdown.css'

interface DropdownProps {
  label?: string
  iconBefore?: JSX.Element
  iconAfter?: JSX.Element
  buttonLabel: string
  items: ItemType[]
  trigger?: 'click'
  noMargin?: boolean
  disabled?: boolean
}

export const DropdownComponent = ({
  label,
  iconBefore,
  iconAfter,
  items,
  buttonLabel,
  noMargin,
  trigger = 'click',
  disabled,
}: DropdownProps) => {
  return (
    <Form.Item label={label} className={noMargin ? 'dropdown-component--no-margin' : ''}>
      <Dropdown
        menu={{ items }}
        trigger={[trigger]}
        disabled={disabled}
        autoAdjustOverflow={true}
        overlayClassName="dropdown-component__menu"
        className="dropdown-component__menu"
      >
        <SecondaryButton label={buttonLabel} iconBefore={iconBefore} iconAfter={iconAfter} />
      </Dropdown>
    </Form.Item>
  )
}
