/**
 * @fileoverview gRPC-Web generated client stub for swift.messaging.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: messaging/v1/messaging.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_owner_pb = require('../../common/owner_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.swift = {};
proto.swift.messaging = {};
proto.swift.messaging.v1 = require('./messaging_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.messaging.v1.MessageServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.messaging.v1.MessageServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.messaging.v1.ListMessagesRequest,
 *   !proto.swift.messaging.v1.ListMessagesResponse>}
 */
const methodDescriptor_MessageService_ListMessages = new grpc.web.MethodDescriptor(
  '/swift.messaging.v1.MessageService/ListMessages',
  grpc.web.MethodType.UNARY,
  proto.swift.messaging.v1.ListMessagesRequest,
  proto.swift.messaging.v1.ListMessagesResponse,
  /**
   * @param {!proto.swift.messaging.v1.ListMessagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.messaging.v1.ListMessagesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.messaging.v1.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.messaging.v1.ListMessagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.messaging.v1.ListMessagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.messaging.v1.MessageServiceClient.prototype.listMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/ListMessages',
      request,
      metadata || {},
      methodDescriptor_MessageService_ListMessages,
      callback);
};


/**
 * @param {!proto.swift.messaging.v1.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.messaging.v1.ListMessagesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.messaging.v1.MessageServicePromiseClient.prototype.listMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/ListMessages',
      request,
      metadata || {},
      methodDescriptor_MessageService_ListMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.messaging.v1.GetMessageRequest,
 *   !proto.swift.messaging.v1.GetMessageResponse>}
 */
const methodDescriptor_MessageService_GetMessage = new grpc.web.MethodDescriptor(
  '/swift.messaging.v1.MessageService/GetMessage',
  grpc.web.MethodType.UNARY,
  proto.swift.messaging.v1.GetMessageRequest,
  proto.swift.messaging.v1.GetMessageResponse,
  /**
   * @param {!proto.swift.messaging.v1.GetMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.messaging.v1.GetMessageResponse.deserializeBinary
);


/**
 * @param {!proto.swift.messaging.v1.GetMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.messaging.v1.GetMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.messaging.v1.GetMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.messaging.v1.MessageServiceClient.prototype.getMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/GetMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_GetMessage,
      callback);
};


/**
 * @param {!proto.swift.messaging.v1.GetMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.messaging.v1.GetMessageResponse>}
 *     Promise that resolves to the response
 */
proto.swift.messaging.v1.MessageServicePromiseClient.prototype.getMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/GetMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_GetMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.messaging.v1.CreateMessageRequest,
 *   !proto.swift.messaging.v1.CreateMessageResponse>}
 */
const methodDescriptor_MessageService_CreateMessage = new grpc.web.MethodDescriptor(
  '/swift.messaging.v1.MessageService/CreateMessage',
  grpc.web.MethodType.UNARY,
  proto.swift.messaging.v1.CreateMessageRequest,
  proto.swift.messaging.v1.CreateMessageResponse,
  /**
   * @param {!proto.swift.messaging.v1.CreateMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.messaging.v1.CreateMessageResponse.deserializeBinary
);


/**
 * @param {!proto.swift.messaging.v1.CreateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.messaging.v1.CreateMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.messaging.v1.CreateMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.messaging.v1.MessageServiceClient.prototype.createMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/CreateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_CreateMessage,
      callback);
};


/**
 * @param {!proto.swift.messaging.v1.CreateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.messaging.v1.CreateMessageResponse>}
 *     Promise that resolves to the response
 */
proto.swift.messaging.v1.MessageServicePromiseClient.prototype.createMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/CreateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_CreateMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.messaging.v1.SetPinnedMessageRequest,
 *   !proto.swift.messaging.v1.SetPinnedMessageResponse>}
 */
const methodDescriptor_MessageService_SetPinnedMessage = new grpc.web.MethodDescriptor(
  '/swift.messaging.v1.MessageService/SetPinnedMessage',
  grpc.web.MethodType.UNARY,
  proto.swift.messaging.v1.SetPinnedMessageRequest,
  proto.swift.messaging.v1.SetPinnedMessageResponse,
  /**
   * @param {!proto.swift.messaging.v1.SetPinnedMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.messaging.v1.SetPinnedMessageResponse.deserializeBinary
);


/**
 * @param {!proto.swift.messaging.v1.SetPinnedMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.messaging.v1.SetPinnedMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.messaging.v1.SetPinnedMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.messaging.v1.MessageServiceClient.prototype.setPinnedMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/SetPinnedMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_SetPinnedMessage,
      callback);
};


/**
 * @param {!proto.swift.messaging.v1.SetPinnedMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.messaging.v1.SetPinnedMessageResponse>}
 *     Promise that resolves to the response
 */
proto.swift.messaging.v1.MessageServicePromiseClient.prototype.setPinnedMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/SetPinnedMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_SetPinnedMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.messaging.v1.SetMessageOwnershipRequest,
 *   !proto.swift.messaging.v1.SetMessageOwnershipResponse>}
 */
const methodDescriptor_MessageService_SetMessageOwnership = new grpc.web.MethodDescriptor(
  '/swift.messaging.v1.MessageService/SetMessageOwnership',
  grpc.web.MethodType.UNARY,
  proto.swift.messaging.v1.SetMessageOwnershipRequest,
  proto.swift.messaging.v1.SetMessageOwnershipResponse,
  /**
   * @param {!proto.swift.messaging.v1.SetMessageOwnershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.messaging.v1.SetMessageOwnershipResponse.deserializeBinary
);


/**
 * @param {!proto.swift.messaging.v1.SetMessageOwnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.messaging.v1.SetMessageOwnershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.messaging.v1.SetMessageOwnershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.messaging.v1.MessageServiceClient.prototype.setMessageOwnership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/SetMessageOwnership',
      request,
      metadata || {},
      methodDescriptor_MessageService_SetMessageOwnership,
      callback);
};


/**
 * @param {!proto.swift.messaging.v1.SetMessageOwnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.messaging.v1.SetMessageOwnershipResponse>}
 *     Promise that resolves to the response
 */
proto.swift.messaging.v1.MessageServicePromiseClient.prototype.setMessageOwnership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.messaging.v1.MessageService/SetMessageOwnership',
      request,
      metadata || {},
      methodDescriptor_MessageService_SetMessageOwnership);
};


module.exports = proto.swift.messaging.v1;

