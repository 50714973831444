/**
 * @fileoverview gRPC-Web generated client stub for swift.booking.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: booking/v1/pricing.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')
const proto = {};
proto.swift = {};
proto.swift.booking = {};
proto.swift.booking.v1 = require('./pricing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.PricingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.booking.v1.PricingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.ListPricingsRequest,
 *   !proto.swift.booking.v1.ListPricingsResponse>}
 */
const methodDescriptor_PricingService_ListPricings = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.PricingService/ListPricings',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.ListPricingsRequest,
  proto.swift.booking.v1.ListPricingsResponse,
  /**
   * @param {!proto.swift.booking.v1.ListPricingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.ListPricingsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.ListPricingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.ListPricingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.ListPricingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.PricingServiceClient.prototype.listPricings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.PricingService/ListPricings',
      request,
      metadata || {},
      methodDescriptor_PricingService_ListPricings,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.ListPricingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.ListPricingsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.PricingServicePromiseClient.prototype.listPricings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.PricingService/ListPricings',
      request,
      metadata || {},
      methodDescriptor_PricingService_ListPricings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.GetPricingRequest,
 *   !proto.swift.booking.v1.GetPricingResponse>}
 */
const methodDescriptor_PricingService_GetPricing = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.PricingService/GetPricing',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.GetPricingRequest,
  proto.swift.booking.v1.GetPricingResponse,
  /**
   * @param {!proto.swift.booking.v1.GetPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.GetPricingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.GetPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.GetPricingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.GetPricingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.PricingServiceClient.prototype.getPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.PricingService/GetPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_GetPricing,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.GetPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.GetPricingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.PricingServicePromiseClient.prototype.getPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.PricingService/GetPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_GetPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.CreatePricingRequest,
 *   !proto.swift.booking.v1.CreatePricingResponse>}
 */
const methodDescriptor_PricingService_CreatePricing = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.PricingService/CreatePricing',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.CreatePricingRequest,
  proto.swift.booking.v1.CreatePricingResponse,
  /**
   * @param {!proto.swift.booking.v1.CreatePricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.CreatePricingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.CreatePricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.CreatePricingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.CreatePricingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.PricingServiceClient.prototype.createPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.PricingService/CreatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_CreatePricing,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.CreatePricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.CreatePricingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.PricingServicePromiseClient.prototype.createPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.PricingService/CreatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_CreatePricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.EditPricingRequest,
 *   !proto.swift.booking.v1.EditPricingResponse>}
 */
const methodDescriptor_PricingService_EditPricing = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.PricingService/EditPricing',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.EditPricingRequest,
  proto.swift.booking.v1.EditPricingResponse,
  /**
   * @param {!proto.swift.booking.v1.EditPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.EditPricingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.EditPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.EditPricingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.EditPricingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.PricingServiceClient.prototype.editPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.PricingService/EditPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_EditPricing,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.EditPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.EditPricingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.PricingServicePromiseClient.prototype.editPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.PricingService/EditPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_EditPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.booking.v1.DeletePricingRequest,
 *   !proto.swift.booking.v1.DeletePricingResponse>}
 */
const methodDescriptor_PricingService_DeletePricing = new grpc.web.MethodDescriptor(
  '/swift.booking.v1.PricingService/DeletePricing',
  grpc.web.MethodType.UNARY,
  proto.swift.booking.v1.DeletePricingRequest,
  proto.swift.booking.v1.DeletePricingResponse,
  /**
   * @param {!proto.swift.booking.v1.DeletePricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.booking.v1.DeletePricingResponse.deserializeBinary
);


/**
 * @param {!proto.swift.booking.v1.DeletePricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.booking.v1.DeletePricingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.booking.v1.DeletePricingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.booking.v1.PricingServiceClient.prototype.deletePricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.booking.v1.PricingService/DeletePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_DeletePricing,
      callback);
};


/**
 * @param {!proto.swift.booking.v1.DeletePricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.booking.v1.DeletePricingResponse>}
 *     Promise that resolves to the response
 */
proto.swift.booking.v1.PricingServicePromiseClient.prototype.deletePricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.booking.v1.PricingService/DeletePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_DeletePricing);
};


module.exports = proto.swift.booking.v1;

