/**
 * @fileoverview gRPC-Web generated client stub for swift.shipment.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: shipment/v1/shipment.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var common_booking_pb = require('../../common/booking_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var invoicing_v1_cost_pb = require('../../invoicing/v1/cost_pb.js')
const proto = {};
proto.swift = {};
proto.swift.shipment = {};
proto.swift.shipment.v1 = require('./shipment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.shipment.v1.ShipmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.ListShipmentsRequest,
 *   !proto.swift.shipment.v1.ListShipmentsResponse>}
 */
const methodDescriptor_ShipmentService_ListShipments = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/ListShipments',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.ListShipmentsRequest,
  proto.swift.shipment.v1.ListShipmentsResponse,
  /**
   * @param {!proto.swift.shipment.v1.ListShipmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.ListShipmentsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.ListShipmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.ListShipmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.ListShipmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.listShipments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/ListShipments',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_ListShipments,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.ListShipmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.ListShipmentsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.listShipments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/ListShipments',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_ListShipments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.GetShipmentRequest,
 *   !proto.swift.shipment.v1.GetShipmentResponse>}
 */
const methodDescriptor_ShipmentService_GetShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/GetShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.GetShipmentRequest,
  proto.swift.shipment.v1.GetShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.GetShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.GetShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.GetShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.GetShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.GetShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.getShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/GetShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_GetShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.GetShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.GetShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.getShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/GetShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_GetShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.GetShipmentStatsRequest,
 *   !proto.swift.shipment.v1.GetShipmentStatsResponse>}
 */
const methodDescriptor_ShipmentService_GetShipmentStats = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/GetShipmentStats',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.GetShipmentStatsRequest,
  proto.swift.shipment.v1.GetShipmentStatsResponse,
  /**
   * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.GetShipmentStatsResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.GetShipmentStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.GetShipmentStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.getShipmentStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/GetShipmentStats',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_GetShipmentStats,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.GetShipmentStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.GetShipmentStatsResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.getShipmentStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/GetShipmentStats',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_GetShipmentStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest,
 *   !proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse>}
 */
const methodDescriptor_ShipmentService_UpdateShipmentInternalNote = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/UpdateShipmentInternalNote',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest,
  proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse,
  /**
   * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.updateShipmentInternalNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentInternalNote',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentInternalNote,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentInternalNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.UpdateShipmentInternalNoteResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.updateShipmentInternalNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentInternalNote',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentInternalNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.CreateShipmentRequest,
 *   !proto.swift.shipment.v1.CreateShipmentResponse>}
 */
const methodDescriptor_ShipmentService_CreateShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/CreateShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.CreateShipmentRequest,
  proto.swift.shipment.v1.CreateShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.CreateShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.CreateShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.CreateShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.CreateShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.CreateShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.createShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/CreateShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_CreateShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.CreateShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.CreateShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.createShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/CreateShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_CreateShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.UpdateShipmentRequest,
 *   !proto.swift.shipment.v1.UpdateShipmentResponse>}
 */
const methodDescriptor_ShipmentService_UpdateShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/UpdateShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.UpdateShipmentRequest,
  proto.swift.shipment.v1.UpdateShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.UpdateShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.UpdateShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.UpdateShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.updateShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.UpdateShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.updateShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest,
 *   !proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse>}
 */
const methodDescriptor_ShipmentService_UpdateBookingOrderInShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/UpdateBookingOrderInShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest,
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.updateBookingOrderInShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateBookingOrderInShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateBookingOrderInShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.UpdateBookingOrderInShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.UpdateBookingOrderInShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.updateBookingOrderInShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateBookingOrderInShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateBookingOrderInShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.AddBookingToShipmentRequest,
 *   !proto.swift.shipment.v1.AddBookingToShipmentResponse>}
 */
const methodDescriptor_ShipmentService_AddBookingToShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/AddBookingToShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.AddBookingToShipmentRequest,
  proto.swift.shipment.v1.AddBookingToShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.AddBookingToShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.AddBookingToShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.AddBookingToShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.addBookingToShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/AddBookingToShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_AddBookingToShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.AddBookingToShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.AddBookingToShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.addBookingToShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/AddBookingToShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_AddBookingToShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.UpdateShipmentCostStatusRequest,
 *   !proto.swift.shipment.v1.UpdateShipmentCostStatusResponse>}
 */
const methodDescriptor_ShipmentService_UpdateShipmentCostStatus = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/UpdateShipmentCostStatus',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.UpdateShipmentCostStatusRequest,
  proto.swift.shipment.v1.UpdateShipmentCostStatusResponse,
  /**
   * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.UpdateShipmentCostStatusResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.UpdateShipmentCostStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.updateShipmentCostStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentCostStatus',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentCostStatus,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.UpdateShipmentCostStatusResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.updateShipmentCostStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentCostStatus',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentCostStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.CreateShipmentCostRequest,
 *   !proto.swift.shipment.v1.CreateShipmentCostResponse>}
 */
const methodDescriptor_ShipmentService_CreateShipmentCost = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/CreateShipmentCost',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.CreateShipmentCostRequest,
  proto.swift.shipment.v1.CreateShipmentCostResponse,
  /**
   * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.CreateShipmentCostResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.CreateShipmentCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.CreateShipmentCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.createShipmentCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/CreateShipmentCost',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_CreateShipmentCost,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.CreateShipmentCostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.CreateShipmentCostResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.createShipmentCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/CreateShipmentCost',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_CreateShipmentCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.UpdateShipmentCostRequest,
 *   !proto.swift.shipment.v1.UpdateShipmentCostResponse>}
 */
const methodDescriptor_ShipmentService_UpdateShipmentCost = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/UpdateShipmentCost',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.UpdateShipmentCostRequest,
  proto.swift.shipment.v1.UpdateShipmentCostResponse,
  /**
   * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.UpdateShipmentCostResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.UpdateShipmentCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.UpdateShipmentCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.updateShipmentCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentCost',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentCost,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.UpdateShipmentCostRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.UpdateShipmentCostResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.updateShipmentCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/UpdateShipmentCost',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_UpdateShipmentCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.RemoveBookingInShipmentRequest,
 *   !proto.swift.shipment.v1.RemoveBookingInShipmentResponse>}
 */
const methodDescriptor_ShipmentService_RemoveBookingInShipment = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/RemoveBookingInShipment',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.RemoveBookingInShipmentRequest,
  proto.swift.shipment.v1.RemoveBookingInShipmentResponse,
  /**
   * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.RemoveBookingInShipmentResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.RemoveBookingInShipmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.RemoveBookingInShipmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.removeBookingInShipment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/RemoveBookingInShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_RemoveBookingInShipment,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.RemoveBookingInShipmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.RemoveBookingInShipmentResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.removeBookingInShipment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/RemoveBookingInShipment',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_RemoveBookingInShipment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.shipment.v1.ListSplitCostReferenceRequest,
 *   !proto.swift.shipment.v1.ListSplitCostReferenceResponse>}
 */
const methodDescriptor_ShipmentService_ListSplitCostReference = new grpc.web.MethodDescriptor(
  '/swift.shipment.v1.ShipmentService/ListSplitCostReference',
  grpc.web.MethodType.UNARY,
  proto.swift.shipment.v1.ListSplitCostReferenceRequest,
  proto.swift.shipment.v1.ListSplitCostReferenceResponse,
  /**
   * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.shipment.v1.ListSplitCostReferenceResponse.deserializeBinary
);


/**
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.shipment.v1.ListSplitCostReferenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.shipment.v1.ListSplitCostReferenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.shipment.v1.ShipmentServiceClient.prototype.listSplitCostReference =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/ListSplitCostReference',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_ListSplitCostReference,
      callback);
};


/**
 * @param {!proto.swift.shipment.v1.ListSplitCostReferenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.shipment.v1.ListSplitCostReferenceResponse>}
 *     Promise that resolves to the response
 */
proto.swift.shipment.v1.ShipmentServicePromiseClient.prototype.listSplitCostReference =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.shipment.v1.ShipmentService/ListSplitCostReference',
      request,
      metadata || {},
      methodDescriptor_ShipmentService_ListSplitCostReference);
};


module.exports = proto.swift.shipment.v1;

