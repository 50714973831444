/**
 * @fileoverview gRPC-Web generated client stub for swift.crm.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.3
// source: crm/v1/address.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var common_geolocation_pb = require('../../common/geolocation_pb.js')

var common_owner_pb = require('../../common/owner_pb.js')

var common_query_pb = require('../../common/query_pb.js')

var crm_v1_contact_pb = require('../../crm/v1/contact_pb.js')
const proto = {};
proto.swift = {};
proto.swift.crm = {};
proto.swift.crm.v1 = require('./address_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.crm.v1.AddressServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.swift.crm.v1.AddressServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.crm.v1.ListAddressesRequest,
 *   !proto.swift.crm.v1.ListAddressesResponse>}
 */
const methodDescriptor_AddressService_ListAddresses = new grpc.web.MethodDescriptor(
  '/swift.crm.v1.AddressService/ListAddresses',
  grpc.web.MethodType.UNARY,
  proto.swift.crm.v1.ListAddressesRequest,
  proto.swift.crm.v1.ListAddressesResponse,
  /**
   * @param {!proto.swift.crm.v1.ListAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.crm.v1.ListAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.swift.crm.v1.ListAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.crm.v1.ListAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.crm.v1.ListAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.crm.v1.AddressServiceClient.prototype.listAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.crm.v1.AddressService/ListAddresses',
      request,
      metadata || {},
      methodDescriptor_AddressService_ListAddresses,
      callback);
};


/**
 * @param {!proto.swift.crm.v1.ListAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.crm.v1.ListAddressesResponse>}
 *     Promise that resolves to the response
 */
proto.swift.crm.v1.AddressServicePromiseClient.prototype.listAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.crm.v1.AddressService/ListAddresses',
      request,
      metadata || {},
      methodDescriptor_AddressService_ListAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.crm.v1.GetAddressRequest,
 *   !proto.swift.crm.v1.GetAddressResponse>}
 */
const methodDescriptor_AddressService_GetAddress = new grpc.web.MethodDescriptor(
  '/swift.crm.v1.AddressService/GetAddress',
  grpc.web.MethodType.UNARY,
  proto.swift.crm.v1.GetAddressRequest,
  proto.swift.crm.v1.GetAddressResponse,
  /**
   * @param {!proto.swift.crm.v1.GetAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.crm.v1.GetAddressResponse.deserializeBinary
);


/**
 * @param {!proto.swift.crm.v1.GetAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.crm.v1.GetAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.crm.v1.GetAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.crm.v1.AddressServiceClient.prototype.getAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.crm.v1.AddressService/GetAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_GetAddress,
      callback);
};


/**
 * @param {!proto.swift.crm.v1.GetAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.crm.v1.GetAddressResponse>}
 *     Promise that resolves to the response
 */
proto.swift.crm.v1.AddressServicePromiseClient.prototype.getAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.crm.v1.AddressService/GetAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_GetAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.crm.v1.CreateAddressRequest,
 *   !proto.swift.crm.v1.CreateAddressResponse>}
 */
const methodDescriptor_AddressService_CreateAddress = new grpc.web.MethodDescriptor(
  '/swift.crm.v1.AddressService/CreateAddress',
  grpc.web.MethodType.UNARY,
  proto.swift.crm.v1.CreateAddressRequest,
  proto.swift.crm.v1.CreateAddressResponse,
  /**
   * @param {!proto.swift.crm.v1.CreateAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.crm.v1.CreateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.swift.crm.v1.CreateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.crm.v1.CreateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.crm.v1.CreateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.crm.v1.AddressServiceClient.prototype.createAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.crm.v1.AddressService/CreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_CreateAddress,
      callback);
};


/**
 * @param {!proto.swift.crm.v1.CreateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.crm.v1.CreateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.swift.crm.v1.AddressServicePromiseClient.prototype.createAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.crm.v1.AddressService/CreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_CreateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.crm.v1.UpdateAddressRequest,
 *   !proto.swift.crm.v1.UpdateAddressResponse>}
 */
const methodDescriptor_AddressService_UpdateAddress = new grpc.web.MethodDescriptor(
  '/swift.crm.v1.AddressService/UpdateAddress',
  grpc.web.MethodType.UNARY,
  proto.swift.crm.v1.UpdateAddressRequest,
  proto.swift.crm.v1.UpdateAddressResponse,
  /**
   * @param {!proto.swift.crm.v1.UpdateAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.crm.v1.UpdateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.swift.crm.v1.UpdateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.crm.v1.UpdateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.crm.v1.UpdateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.crm.v1.AddressServiceClient.prototype.updateAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.crm.v1.AddressService/UpdateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_UpdateAddress,
      callback);
};


/**
 * @param {!proto.swift.crm.v1.UpdateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.crm.v1.UpdateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.swift.crm.v1.AddressServicePromiseClient.prototype.updateAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.crm.v1.AddressService/UpdateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_UpdateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.swift.crm.v1.DeleteAddressRequest,
 *   !proto.swift.crm.v1.DeleteAddressResponse>}
 */
const methodDescriptor_AddressService_DeleteAddress = new grpc.web.MethodDescriptor(
  '/swift.crm.v1.AddressService/DeleteAddress',
  grpc.web.MethodType.UNARY,
  proto.swift.crm.v1.DeleteAddressRequest,
  proto.swift.crm.v1.DeleteAddressResponse,
  /**
   * @param {!proto.swift.crm.v1.DeleteAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.swift.crm.v1.DeleteAddressResponse.deserializeBinary
);


/**
 * @param {!proto.swift.crm.v1.DeleteAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.swift.crm.v1.DeleteAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.swift.crm.v1.DeleteAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.swift.crm.v1.AddressServiceClient.prototype.deleteAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/swift.crm.v1.AddressService/DeleteAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteAddress,
      callback);
};


/**
 * @param {!proto.swift.crm.v1.DeleteAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.swift.crm.v1.DeleteAddressResponse>}
 *     Promise that resolves to the response
 */
proto.swift.crm.v1.AddressServicePromiseClient.prototype.deleteAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/swift.crm.v1.AddressService/DeleteAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteAddress);
};


module.exports = proto.swift.crm.v1;

